import {
  AccidentForm,
  Af,
  Answer,
  MachineProcess,
  SelectedQuestionnaire,
  QuestionnaireObject,
} from "types/types";

export enum ActionType {
  CLEAR_WHOLE_STATE = "CLEAR_WHOLE_STATE",
  CLEAR_QUESTIONNAIRE_STATE = "CLEAR_QUESTIONNAIRE_STATE",
  SET_QUESTIONNAIRES = "SET_QUESTIONNAIRES",
  SET_SERIAL_NUMBER = "SET_SERIAL_NUMBER",
  SET_MACHINE_PROCESS = "SET_MACHINE_PROCESS",
  SET_SELECTED_QUESTIONNAIRE = "SET_SELECTED_QUESTIONNAIRE",
  SET_START_TIME = "SET_START_TIME",
  SET_ACCIDENT_FORMS = "SET_ACCIDENT_FORMS",
  SET_SELECTED_ACCIDENT_FORM = "SET_SELECTED_ACCIDENT_FORM",
  SET_ACCIDENT_START_TIME = "SET_ACCIDENT_START_TIME",
  SET_ANSWERS = "SET_ANSWERS",
  SET_ACCIDENT_ANSWERS = "SET_ACCIDENT_ANSWERS",
}

export type InitialState = {
  questionnaires: QuestionnaireObject[];
  serialNumber: string;
  machineProcess: MachineProcess;
  selectedQuestionnaire: SelectedQuestionnaire;
  startTime: Date;
  accidentForms: AccidentForm[] | undefined;
  selectedAccidentForm: Af;
  accidentStartTime: Date;
  answers: Answer[];
  accidentAnswers: Answer[];
};

export type QuestionnairesContextType = {
  state: InitialState;
  setQuestionnairesAction: (questionnaires: QuestionnaireObject[]) => void;
  setSelectedQuestionnaireAction: (questionnaires: SelectedQuestionnaire) => void;
  setStartTimeAction: (startTime: Date) => void;
  setAccidentFormsAction: (accidentForms: AccidentForm[]) => void;
  setSelectedAccidentFormAction: (selectedAccidentForm: Af) => void;
  setAccidentStartTimeAction: (accidenStartTime: Date) => void;
  setAnswersAction: (answers: Answer[]) => void;
  setAccidentAnswersAction: (answers: Answer[]) => void;
  setMachineProcessAction: (machineProcess: MachineProcess) => void;
  questionnairesLoading: boolean;
  questionnairesFetchError: unknown;
  fetchQuestionnaires: (serial?: string) => void;
  accidentFormsLoading: boolean;
  accidentFormsError: boolean;
  fetchAccidentForms: () => void;
  clearWholeStateAction: () => void;
  clearQuestionnaireStateAction: () => void;
  serialNumberPath: string;
  removeAccidentsQuery: () => void;
};
