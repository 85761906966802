import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled, { css } from "styled-components/macro";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { toast, TypeOptions } from "react-toastify";

import {
  AddCommentToAssessmentPayload,
  AddCommentToAssessmentResponse,
  AssignToUserPayload,
  AssignToUserResponse,
  ChangeEntityStatusPayload,
  ChangeEntityStatusResponse,
  Entity,
  MachineProcessStatus,
  Machine,
  Process,
} from "types/types";
import {
  Payload,
  PayloadName,
  PayloadNames,
  RequestError,
  RequestName,
  RequestNames,
} from "./types/status-change.types";
import { ReturnPathKeys } from "types/tasks.types";
import {
  // addCommentToAssessment,
  // assignToUser,
  changeEntityStatus,
} from "api/questionnaires";
import { removeDuplicates } from "./utils/utils";
import useModal from "hooks/useModal";
import useTimeout from "hooks/useTimeout";
import useHandleBackToHomePage from "hooks/useHandleBackToHomePage";
import { getManagerOptionsTranslations } from "./translations/status-change.translations";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import Status from "./components/status/Status";
import AssignToUser from "./components/assign-to-user/AssignToUser";
import Comment from "./components/comment/Comment";

import Button from "components/atoms/Button";

import { Card, ContentContainer, ModalStyles } from "styles/generalStyles";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import EntityInfo from "components/molecules/EntityInfo";
import useMachineProcessInfo from "hooks/useMachineProcessInfo";

const { Actions, ButtonContainer } = ModalStyles;

const ActionsWrapper = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
  justify-content: flex-end;
  padding-bottom: 1rem;
  height: 15%;

  @media screen and (min-height: 508px) {
    height: 100%;
  }
`;

const Header = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 2rem;
  font-family: AuraAspect;

  @media screen and (min-height: 568px) {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2.2rem;
  }
`;

const BtnLabel = styled.span<{ smallFont: boolean }>`
  ${({ smallFont }) =>
    smallFont &&
    css`
      font-size: 1.3rem;
    `}
`;

const { STATUS, ASSIGNMENT, COMMENT } = PayloadNames;

const initial = { status: false, assignment: false, comment: false };

const getPayloadFromStorage = (key: PayloadName) => {
  const localData = sessionStorage.getItem(key);
  return localData ? JSON.parse(localData) : null;
};

function StatusChange() {
  const [statusPayload, setStatusPayload] = useState<ChangeEntityStatusPayload>(
    getPayloadFromStorage(STATUS),
  );
  const [assignmentPayload, setAssignmentPayload] = useState<AssignToUserPayload>(
    getPayloadFromStorage(ASSIGNMENT),
  );
  const [commentPayload, setCommentPayload] = useState<AddCommentToAssessmentPayload>(
    getPayloadFromStorage(COMMENT),
  );
  // const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const { handleBackToHomePage } = useHandleBackToHomePage();

  const { open, openModal, closeModal } = useModal();
  const setTimeOut = useTimeout();
  const {
    i18n: { language },
  } = useTranslation();
  const { header, modal, actionBtn, alert } = getManagerOptionsTranslations(language);

  const [fulfilledRequests, setFulfilledRequests] = useState<RequestName[]>([]);
  const [requestError, setRequestError] = useState(initial);

  const {
    state: { machineProcess },
    setMachineProcessAction,
  } = useContext(QuestionnairesContext);

  const { entity, entityId } = useMachineProcessInfo();

  // const entity = searchParams.get("entity") as Entity;
  // const entityId = searchParams.get("entityId") as string;

  const initialStatus = sessionStorage.getItem("initialStatus");

  const shouldSendStatusRequest =
    !!statusPayload && initialStatus !== statusPayload.status;
  const shouldSendAssignToRequest = !!assignmentPayload;
  const shouldSendCommentRequest = !!commentPayload;

  const shouldHandleRequests =
    shouldSendStatusRequest || shouldSendAssignToRequest || shouldSendCommentRequest;

  // const numberOfRequestsToBeSent = [
  //   shouldSendStatusRequest,
  //   shouldSendAssignToRequest,
  //   shouldSendCommentRequest,
  // ].filter((item) => !!item).length;

  const isAnyError = useRef(false);

  // const message = shouldHandleRequests
  //   ? modal.message.variant_1
  //   : modal.message.variant_2;

  // const buttonLabel = shouldHandleRequests
  //   ? `${modal.btnLabel.save}`
  //   : `${modal.btnLabel.finish}`;

  // This is to enable multiple toasts to be displayed on screen.
  // There can be a case when one or more reqeusts return error(s).
  // useNotification hook allows only one toast to be displayed at a time.
  const notify = (message: string, type: TypeOptions) => {
    if (type !== "default") {
      toast[type](message, {
        autoClose: 2500,
        style: { fontSize: "1.8rem" },
      });
    }
  };

  const updateStatusInContext = (status: MachineProcessStatus) => {
    if (!machineProcess) return;

    const { Machine, Process } = machineProcess;

    if (Machine) {
      const updatedMachine: Machine = { ...Machine, status };
      setMachineProcessAction({ Machine: updatedMachine, Process });
    }

    if (Process) {
      const updatedProcess: Process = { ...Process, status };
      setMachineProcessAction({ Process: updatedProcess, Machine });
    }
  };

  // --------------- NAVIGATE HANDLER ---------------

  const navigateBack = () => {
    const path = sessionStorage.getItem(ReturnPathKeys.CHANGE_STATUS) ?? "";

    navigate(path);
  };

  // --------------- SUCCESS HANDLER ---------------

  const onChangeStatusSuccess = ({ data: { status } }: ChangeEntityStatusResponse) => {
    setFulfilledRequests((prev) => removeDuplicates([...prev, RequestNames.STATUS]));
    // closeModal();

    notify(alert.success.status, "success");

    setTimeOut(() => {
      updateStatusInContext(status);
      sessionStorage.setItem("initialStatus", status);
      navigateBack();
    }, 100);
  };

  // --------------- SETTLED HANDLER ---------------

  const onSettled = (request: RequestName) => () => {
    setFulfilledRequests((prev) => removeDuplicates([...prev, request]));
  };

  // --------------- ERROR HANDLERS ---------------

  const clearError = (key: keyof RequestError | null, all = false) => {
    if (all) {
      setRequestError(initial);
    }
    if (key) {
      setRequestError((prev) => ({ ...prev, [key]: false }));
    }
  };

  const onChangeStatusError = () => {
    setRequestError((prev) => ({ ...prev, status: true }));
    closeModal();
    notify(alert.error.status, "error");
    isAnyError.current = true;
  };

  // const onAssignToUserError = () => {
  //   setRequestError((prev) => ({ ...prev, assignment: true }));
  //   closeModal();
  //   notify(alert.error.assignment, "error");
  //   isAnyError.current = true;
  // };

  // const onAddCommentError = () => {
  //   setRequestError((prev) => ({ ...prev, comment: true }));
  //   closeModal();
  //   notify(alert.error.comment, "error");
  //   isAnyError.current = true;
  // };

  // --------------- API REQUESTS ---------------

  const { mutate: changeStatus, isLoading: statusLoading } = useMutation<
    ChangeEntityStatusResponse,
    AxiosError,
    ChangeEntityStatusPayload
  >(changeEntityStatus, {
    onError: onChangeStatusError,
    onSettled: onSettled(RequestNames.STATUS),
    onSuccess: onChangeStatusSuccess,
  });

  // const { mutate: assign, isLoading: assignLoading } = useMutation<
  //   AssignToUserResponse,
  //   AxiosError,
  //   AssignToUserPayload
  // >(assignToUser, {
  //   onError: onAssignToUserError,
  //   onSettled: onSettled(RequestNames.ASSIGNMENT),
  // });

  // const { mutate: addComment, isLoading: commentLoading } = useMutation<
  //   AddCommentToAssessmentResponse,
  //   AxiosError,
  //   AddCommentToAssessmentPayload
  // >(addCommentToAssessment, {
  //   onError: onAddCommentError,
  //   onSettled: onSettled(RequestNames.COMMENT),
  // });

  // --------------- API HANDLERS ---------------

  // *** STATUS
  const handleStatusRequest = () => {
    if (shouldSendStatusRequest) {
      changeStatus(statusPayload);
    }
  };

  // *** ASSIGNMENT
  // const handleAssignmentRequest = () => {
  //   if (shouldSendAssignToRequest) {
  //     assign(assignmentPayload);
  //   }
  // };

  // *** COMMENT
  // const handleCommentRequest = () => {
  //   if (shouldSendCommentRequest) {
  //     addComment(commentPayload);
  //   }
  // };

  // *** REQUESTS HANDLER
  const handleApiRequests = () => {
    handleStatusRequest();
    // handleAssignmentRequest();
    // handleCommentRequest();
  };

  // --------------- ON CLICK HANDLERS ---------------

  const onConfirmationButtonClick = () => {
    if (isAnyError.current) {
      isAnyError.current = false;
    }

    if (!!fulfilledRequests.length) {
      setFulfilledRequests([]);
    }

    if (shouldHandleRequests) {
      handleApiRequests();
    } else {
      // handleBackToHomePage();
    }
  };

  const onSaveButtonClick = () => {
    if (shouldHandleRequests) {
      onConfirmationButtonClick();
    } else {
      navigateBack();
    }
  };

  // --------------- PAYLOAD SETTER ---------------

  const setPayload = (payloadName: PayloadName, payload: Payload) => {
    if (payloadName === STATUS) {
      setStatusPayload(payload as ChangeEntityStatusPayload);
    }
    // if (payloadName === ASSIGNMENT) {
    //   setAssignmentPayload(payload as AssignToUserPayload);
    // }
    // if (payloadName === COMMENT) {
    //   setCommentPayload(payload as AddCommentToAssessmentPayload);
    // }
  };

  // --------------- EFFECT HANDLERS ---------------

  useEffect(() => {
    sessionStorage.setItem(STATUS, JSON.stringify(statusPayload));
  }, [statusPayload]);

  // useEffect(() => {
  //   sessionStorage.setItem(ASSIGNMENT, JSON.stringify(assignmentPayload));
  // }, [assignmentPayload]);

  // useEffect(() => {
  //   sessionStorage.setItem(COMMENT, JSON.stringify(commentPayload));
  // }, [commentPayload]);

  useEffect(() => {
    const isAnyError = Object.values(requestError).some((value) => value);

    if (isAnyError) {
      setTimeOut(() => {
        clearError(null, true);
      }, 6000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestError]);

  // useEffect(() => {
  //   if (
  //     !!fulfilledRequests.length &&
  //     fulfilledRequests.length === numberOfRequestsToBeSent
  //   ) {
  //     if (!isAnyError.current) {
  //       navigate("/status-change/successfully-sent", {
  //         replace: true,
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fulfilledRequests, requestError, numberOfRequestsToBeSent]);

  return (
    <ContentContainer>
      <Header>{header.label}</Header>

      <Card>
        <EntityInfo noMarginTop />
        <Status
          entity={entity}
          entityId={entityId}
          setPayload={setPayload}
          isError={requestError.status}
          clearError={clearError}
        />
        {/* <AssignToUser
          setPayload={setPayload}
          isError={requestError.assignment}
          clearError={clearError}
        />
        <Comment
          commentPayload={commentPayload}
          setPayload={setPayload}
          isError={requestError.comment}
          clearError={clearError}
        /> */}
      </Card>

      <ActionsWrapper>
        <Actions>
          <ButtonContainer middle>
            <Button
              label={
                <BtnLabel smallFont={shouldHandleRequests}>
                  {!shouldHandleRequests
                    ? `${actionBtn.label.variant_1}`
                    : `${actionBtn.label.variant_2}`}
                </BtnLabel>
              }
              onClick={onSaveButtonClick}
            />
          </ButtonContainer>
        </Actions>
      </ActionsWrapper>

      {/* <ConfirmationModal
        message={message}
        onClick={onConfirmationButtonClick}
        onClose={closeModal}
        open={open}
        buttonLabel={buttonLabel}
        buttonMiddle
        loading={statusLoading || assignLoading || commentLoading}
      /> */}
    </ContentContainer>
  );
}

export default StatusChange;
