import { TaskFiles } from "types/tasks.types";
import { Keys } from "../types/selected-task.types";

export const clearStorage = () => {
  [
    "selectedTask",
    "taskRelated",
    "initialStatus",
    "initialUserAssigned",
    "initialTitle",
    "initialDescription",
    "initialDeadline",
    "photosToBeAttached",
    "photoDeleted",
    "tasksSearchParams",
  ].forEach((item) => {
    sessionStorage.removeItem(item);
  });
};

export const getTaskStorage = () => {
  const localData = sessionStorage.getItem("selectedTask");
  return localData ? JSON.parse(localData) : undefined;
};

export const getInitialValueFromStorage = (key: Keys) => {
  const localData = sessionStorage.getItem(key);
  return !!localData ? JSON.parse(localData) : null;
};

export const getPhotosToBeAttachedFromStorage = () => {
  const localData = sessionStorage.getItem("photosToBeAttached");
  const photosToBeAttachedArray: TaskFiles = !!localData ? JSON.parse(localData) : [];

  return photosToBeAttachedArray;
};
