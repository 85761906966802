import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { formatDistance } from "date-fns";
import pl from "date-fns/locale/pl/index";
import en from "date-fns/locale/en-GB/index";
import { getCookie } from "react-use-cookie";

import { useTranslation } from "react-i18next";

import { ContentContainer, Header, CheckListsContainer } from "styles/generalStyles";
import {
  QuestionnaireObject,
  CountryCodes,
  EntitiesEnum,
  Entity,
  MachineProcessStatuses,
  MachineProcessStatus,
} from "types/types";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import UserContext from "contexts/user-context/UserContext";
import { shuffle } from "pages/questionnaire/utils/utils";
import useModal from "hooks/useModal";
import useTimeout from "hooks/useTimeout";
import useNotification from "hooks/useNotification";
import { ACCESS_TOKEN, clearLocalStorage, isMobile } from "utils/utils";
import { getTranslations } from "./translations/check-lists.translations";
import { baseURL } from "axios-instance/axios-instance";

import Placeholders from "./components/Placeholders";
import ErrorAlert from "components/molecules/ErrorAlert";
import BackArrow from "components/atoms/BackArrow";
import NoQuestionnairesFound from "./components/NoQuestionnairesFound";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import Message from "./components/Message";
import LastFilledOut from "./components/LastFilledOut";
import SubText from "./components/SubText";
import { AxiosError } from "axios";

const CheckListItem = styled.li<{ isMobile: boolean; open: boolean }>`
  ${({ theme }) => theme.checkListItem}
  position: relative;
  user-select: none;
  transition: filter 0.2s ease-out;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};

  ${({ open }) =>
    open &&
    css`
      height: auto;
    `}

  &:hover {
    filter: brightness(0.97);
  }
`;

const Title = styled.div<{ isMobile: boolean }>`
  height: 4.5rem;
  margin-bottom: 0.6rem;
  font-family: GothamBold;
  font-size: 1.6rem;
  ${({ theme }) => theme.ellipsisMultiline}
  -webkit-line-clamp: 2;

  @media screen and (min-height: 616px) {
    font-size: 1.8rem;
  }
`;

const RedirectLoaderContainer = styled.div`
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RedirectLoader = styled.div`
  .loader {
    height: 4px;
    width: 100%;
    --c: ${({ theme }) => `no-repeat linear-gradient(${theme.primary_400} 0 0)`};
    background: ${({ theme }) => `var(--c), var(--c), ${theme.primary_100}`};
    background-size: 60% 100%;
    animation: l16 3s infinite;
  }
  @keyframes l16 {
    0% {
      background-position: -150% 0, -150% 0;
    }
    66% {
      background-position: 250% 0, -150% 0;
    }
    100% {
      background-position: 250% 0, 250% 0;
    }
  }
`;

const Label = styled.div`
  padding-bottom: 0.5rem;
`;

type Status = {
  entity: Entity;
  value: MachineProcessStatus;
};

const NOT_FOUND = "not-found";

const { MACHINE, PROCESS } = EntitiesEnum;
const { OUT_OF_ORDER } = MachineProcessStatuses;

function CheckLists() {
  const {
    state: { questionnaires, answers, selectedQuestionnaire, machineProcess },
    setAnswersAction,
    setStartTimeAction,
    setSelectedQuestionnaireAction,
    questionnairesLoading: isLoading,
    questionnairesFetchError,
    fetchQuestionnaires,
    clearQuestionnaireStateAction,
  } = useContext(QuestionnairesContext);
  const {
    user,
    PERMISSIONS: { MACHINES_PERMISSIONS, PROCESSES_PERMISSIONS },
  } = useContext(UserContext);
  const [status, setStatus] = useState<Status>();
  const [headerVisible, setHeaderVisible] = useState(false);
  const [openElementId, setOpenElementId] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation("questionnaires");
  const { labels, error } = getTranslations(language);
  const token = getCookie(ACCESS_TOKEN);
  const questionnaireIdRef = useRef("");

  const { open, openModal, closeModal } = useModal();
  const notify = useNotification();
  const setTimeOut = useTimeout();

  const noQuestionnairesFound =
    !isLoading && !questionnairesFetchError && !questionnaires.length;
  const singleQuestionnaireLaunched = localStorage.getItem("singleQuestionnaireLaunched");
  const QuestionnaireId = searchParams.get("QuestionnaireId");
  const serialNumber = searchParams.get("SerialNo");

  // *************** PERMISSIONS ***************

  const { PERMITTED_TO_READ: PERMITTED_TO_READ_MACHINES } = MACHINES_PERMISSIONS;
  const { PERMITTED_TO_READ: PERMITTED_TO_READ_PROCESSES } = PROCESSES_PERMISSIONS;

  // ----- READ -----
  const permittedToReadAll_machines = PERMITTED_TO_READ_MACHINES.ALL;
  const permittedToReadLastAssessment_machines =
    permittedToReadAll_machines || PERMITTED_TO_READ_MACHINES.LastAssessment;

  const permittedToReadAll_processes = PERMITTED_TO_READ_PROCESSES.ALL;
  const permittedToReadLastAssessment_processes =
    permittedToReadAll_processes || PERMITTED_TO_READ_PROCESSES.LastAssessment;

  let permittedToReadLastAssessment = false;

  if (machineProcess) {
    const { Machine, Process } = machineProcess;
    if (Machine) {
      permittedToReadLastAssessment = permittedToReadLastAssessment_machines;
    }

    if (Process) {
      permittedToReadLastAssessment = permittedToReadLastAssessment_processes;
    }
  }

  let content = null;

  // --------------- *** ---------------

  const starQuestionnaire = useCallback(
    (id: string) => {
      const chosenQuestionnaire = questionnaires.find(
        (item) => item.id === id,
      ) as QuestionnaireObject;

      if (!chosenQuestionnaire) {
        if (serialNumber) {
          navigate(`/check-lists?SerialNo=${serialNumber}&QuestionnaireId=${NOT_FOUND}`);
          setHeaderVisible(true);
        }

        return;
      }

      if (!selectedQuestionnaire) {
        let questionnaireToBeSetInState = chosenQuestionnaire;

        if (chosenQuestionnaire.RandomOrder) {
          const Questions = shuffle(chosenQuestionnaire.Questions);

          questionnaireToBeSetInState = {
            ...chosenQuestionnaire,
            Questions,
          };
        }

        setSelectedQuestionnaireAction(questionnaireToBeSetInState);
      }

      if (answers.length) {
        setAnswersAction([]);
      }

      if (questionnaireIdRef.current) {
        questionnaireIdRef.current = "";
      }

      localStorage.setItem("currentPath", `/questionnaire/${id}/1`);
      navigate(`/questionnaire/${id}/1`);

      setStartTimeAction(new Date());
    },
    [
      answers.length,
      navigate,
      questionnaires,
      selectedQuestionnaire,
      setAnswersAction,
      setSelectedQuestionnaireAction,
      setStartTimeAction,
      serialNumber,
    ],
  );

  const clearState = () => {
    clearQuestionnaireStateAction();
    clearLocalStorage([
      "selectedQuestionnaire",
      "serialNumberPath",
      "machineProcess",
      "serialNumber",
      "currentPath",
      "answers",
    ]);
  };

  const redirectToEnterSerialNumber = () => {
    navigate("/serial-number-method-selection", { replace: true });
  };

  // --------------- On click handlers ---------------

  const onBackArrowClick = () => {
    clearState();
    redirectToEnterSerialNumber();
  };

  const onCheckListItemClick = useCallback(
    (id: string) => () => {
      if (status?.value === OUT_OF_ORDER) {
        questionnaireIdRef.current = id;
        openModal();
      } else {
        starQuestionnaire(id);
      }
    },
    [openModal, starQuestionnaire, status?.value],
  );

  const onErrorClick = () => {
    fetchQuestionnaires();
  };

  const toggleChevron = (id: string) => {
    if (openElementId !== id) {
      setOpenElementId(id);
    } else {
      setOpenElementId("");
    }
  };

  // --------------- Content ---------------

  if (
    ((questionnaires.length === 1 && singleQuestionnaireLaunched) ||
      questionnaires.length > 1) &&
    !QuestionnaireId
  ) {
    content = (
      <CheckListsContainer>
        {questionnaires.map((item: QuestionnaireObject) => {
          const {
            id,
            QuestionnaireSubtitle,
            QuestionnaireTitle,
            last_assessment_date_created,
          } = item;
          const open = openElementId === id;

          let last = null;
          let href = "";

          if (last_assessment_date_created) {
            last = last_assessment_date_created;
          }

          if ("last_assessment" in item) {
            const {
              last_assessment: { date_created, File },
            } = item;

            last = date_created;

            if (File) {
              href = `${baseURL}assets/${File}?access_token=${token}`;
            }
          }

          let lastFilledOut = "";

          if (last) {
            if (language.includes(CountryCodes.EN)) {
              lastFilledOut = `${formatDistance(new Date(), new Date(last), {
                locale: en,
              })} ago`;
            }

            if (language.includes(CountryCodes.PL)) {
              lastFilledOut = `${formatDistance(new Date(), new Date(last), {
                locale: pl,
              })} temu`;
            }
          }

          return (
            <CheckListItem
              onClick={onCheckListItemClick(id)}
              isMobile={isMobile}
              key={id}
              open={open}
            >
              <Title isMobile={isMobile}>{QuestionnaireTitle}</Title>
              <SubText
                id={id}
                html={QuestionnaireSubtitle}
                toggleChevron={toggleChevron}
                open={open}
              />

              {permittedToReadLastAssessment && (
                <LastFilledOut href={href} lastFilledOut={lastFilledOut} />
              )}
            </CheckListItem>
          );
        })}
      </CheckListsContainer>
    );
  }

  if (isLoading) {
    if (QuestionnaireId) {
      content = (
        <RedirectLoaderContainer>
          <RedirectLoader>
            <Label>{labels.redirecting}</Label>
            <div className='loader'></div>
          </RedirectLoader>
        </RedirectLoaderContainer>
      );
    } else {
      content = <Placeholders questionnaires />;
    }
  }

  if (questionnairesFetchError) {
    content = (
      <ErrorAlert
        onClick={onErrorClick}
        message={t("error.message")}
        error={questionnairesFetchError as unknown as AxiosError}
      />
    );
  }

  if (noQuestionnairesFound) {
    content = <NoQuestionnairesFound />;
  }

  // This is to avoid being redirected to currentPath after scanning QR code
  // with phone scanner.
  // Redirect path is used to redirect user to the point in the questionnaire
  // where they left off. This behaviour is not desired after scanning qr code.
  // localStorage.removeItem("currentPath");

  // Reason of setting user id in local storage here:
  //    user logged in after scanning QR code with phone scanner
  //    (session was inactive, user id was not set in local storage).
  // In the normal flow, user id is set on the Home page.
  // When user scans QR code with phone scanner, the Home page is omitted,
  // that is why user id has to be set in local storage on this page.
  useEffect(() => {
    const userIdInLocalStorage = localStorage.getItem("userId");
    if (user) {
      if (!userIdInLocalStorage) {
        localStorage.setItem("userId", user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (machineProcess) {
      const { Machine, Process } = machineProcess;

      if (Machine) {
        setStatus({ entity: MACHINE, value: Machine.status });
      }

      if (Process) {
        setStatus({ entity: PROCESS, value: Process.status });
      }
    }
  }, [machineProcess]);

  useEffect(() => {
    const clearState = () => {
      clearQuestionnaireStateAction();
      clearLocalStorage(["selectedQuestionnaire", "currentPath", "answers"]);
    };

    if (selectedQuestionnaire) {
      clearState();
    }

    if (!!sessionStorage.getItem("scrollPosition")) {
      sessionStorage.removeItem("scrollPosition");
    }
  }, [selectedQuestionnaire, clearQuestionnaireStateAction]);

  useEffect(() => {
    // If there is ONLY ONE questionnaire on the list
    // and user has not yet logged in, launch it.
    if (questionnaires.length === 1 && !singleQuestionnaireLaunched) {
      onCheckListItemClick(questionnaires[0].id)();
      localStorage.setItem("singleQuestionnaireLaunched", "true");
    }
  }, [questionnaires, singleQuestionnaireLaunched, onCheckListItemClick]);

  useEffect(() => {
    if (QuestionnaireId === NOT_FOUND) {
      notify(error.redirect, "warning", 5000);

      setTimeOut(() => {
        if (serialNumber) {
          navigate(`/check-lists?SerialNo=${serialNumber}`);
        }
      }, 1000);

      return;
    }

    setHeaderVisible(!QuestionnaireId);

    if (questionnaires.length && QuestionnaireId) {
      onCheckListItemClick(QuestionnaireId)();
    }
  }, [
    questionnaires,
    QuestionnaireId,
    onCheckListItemClick,
    notify,
    navigate,
    serialNumber,
    setTimeOut,
    error,
  ]);

  return (
    <ContentContainer>
      {headerVisible && (
        <Header upperCase>
          <BackArrow onClick={onBackArrowClick} />
          <span>{t("header")}</span>
        </Header>
      )}
      {content}

      <ConfirmationModal
        message={<Message entity={status?.entity} />}
        onClick={() => starQuestionnaire(questionnaireIdRef.current)}
        onClose={closeModal}
        open={open}
        buttonLabel={t("button.complete")}
        buttonMiddle
        warning
      />
    </ContentContainer>
  );
}

export default CheckLists;
