import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCookie } from "react-use-cookie";
import { useQuery } from "react-query";

import { isMobile, ACCESS_TOKEN } from "utils/utils";
import { getTasks } from "api/tasks";
import { ReturnPathKeys, CreatedTask } from "types/tasks.types";
import { getTranslations } from "../../translations/tasks.translations";
import UserContext from "contexts/user-context/UserContext";
import { useAppDispatch, useAppSelector } from "redux/hooks/hooks";
import { setAllTasks, setTasksSearchParams } from "redux/slices/tasksSlice";
import useTasksURLPath from "hooks/useTasksURLPath";

import { ReactComponent as FilterIcon } from "assets/icons/filter-list.svg";

import Button from "components/atoms/Button";
import TasksList from "./components/tasks-list/TasksList";
import TasksFilter from "./components/tasks-list/components/tasks-filter/TasksFilter";

import {
  Container,
  TasksListContainer,
  TasksActions,
  ButtonContainer,
} from "../../styles/tasks.styles";
import { Wrapper } from "styles/selected-task.styles";

type TasksCreatedProps = {
  setTasksNumber: Dispatch<SetStateAction<number>>;
};

const { SELECTED_TASK, TASK_CREATOR } = ReturnPathKeys;

function TasksHandler({ setTasksNumber }: TasksCreatedProps) {
  const [filterVisible, setFilterVisible] = useState(false);
  const { allTasks } = useAppSelector(({ tasks }) => tasks);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const prevTasksURLPath = useRef("");

  const {
    i18n: { language },
  } = useTranslation();
  const { labels } = getTranslations(language);

  const {
    user,
    PERMISSIONS: { TASKS_PERMISSIONS },
  } = useContext(UserContext);
  const { tasksURLPath } = useTasksURLPath(user?.id);

  const { PERMITTED_TO_CREATE } = TASKS_PERMISSIONS;
  const hasPermissionToCreateTask = PERMITTED_TO_CREATE.ALL;

  const token = getCookie(ACCESS_TOKEN);

  // --------------- Tasks list content ---------------

  let tasksList: CreatedTask[] = allTasks;

  const openFilter = () => {
    setFilterVisible(true);
  };

  const closeFilter = () => {
    setFilterVisible(false);
  };

  const toggleFilter = () => {
    if (filterVisible) {
      closeFilter();
    } else {
      openFilter();
    }
  };

  // --------------- API handler ---------------

  const onSuccess = (data: CreatedTask[]) => {
    dispatch(setAllTasks(data));

    prevTasksURLPath.current = tasksURLPath;
  };

  const { isLoading, isError, isRefetching } = useQuery(
    "tasks",
    getTasks(token, tasksURLPath),
    {
      onSuccess,
      keepPreviousData: false,
      enabled: tasksURLPath !== prevTasksURLPath.current && !!user?.id,
    },
  );

  // --------------- Navigation handlers ---------------

  const handleReturnPath = (key: ReturnPathKeys) => {
    const retunrPath = pathname + search;
    sessionStorage.setItem(key, retunrPath);
  };

  const navigateToSeletedTaskPage = (id: string) => {
    navigate(`/task/selected?id=${id}`);
    handleReturnPath(SELECTED_TASK);
  };

  const navigateToTaskCreator = () => {
    navigate("/task-creator");
    handleReturnPath(TASK_CREATOR);
  };

  // --------------- On click handlers ---------------

  const onItemClick = (id: string) => () => {
    navigateToSeletedTaskPage(id);
  };

  const onAddTaskButtonClick = () => {
    navigateToTaskCreator();
  };

  const onFilterIconClick = () => {
    if (isLoading) return;
    toggleFilter();
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    setTasksNumber(allTasks.length);
  }, [setTasksNumber, allTasks]);

  useEffect(() => {
    dispatch(setTasksSearchParams(search));
    sessionStorage.setItem("tasksSearchParams", search);
  }, [search, dispatch]);

  return (
    <Container>
      <Wrapper isMobile={isMobile}>
        <TasksListContainer>
          <TasksList
            tasksList={tasksList}
            onItemClick={onItemClick}
            filterVisible={filterVisible}
            isLoading={isLoading}
            isRefetching={isRefetching}
            isError={isError}
          />
        </TasksListContainer>

        <TasksActions isMobile={isMobile} isLoading={isLoading}>
          <FilterIcon className='filter-icon' onClick={onFilterIconClick} />

          {hasPermissionToCreateTask && (
            <ButtonContainer>
              <Button
                label={labels.btn.add}
                customClass='add-btn'
                onClick={onAddTaskButtonClick}
                disabled={isLoading}
              />
            </ButtonContainer>
          )}
        </TasksActions>
      </Wrapper>

      {filterVisible && <TasksFilter closeFilter={closeFilter} />}
    </Container>
  );
}

export default TasksHandler;
