type AnswersData = {
  sort: number;
  OriginalText: string | null;
  Value: string | null;
  ReactionValue: string | null;
};

export const createAnswersList = (answersData: AnswersData[], questionLabel: string) =>
  answersData
    .map(
      ({ sort, OriginalText, Value, ReactionValue }) =>
        `[${questionLabel}: ${sort}] ${OriginalText}${Value ? `\n\n> ${Value}` : ""}${
          ReactionValue ? `\n\n> ${ReactionValue}` : ""
        }`,
    )
    .join("\n\n");

export const getTaskDescription = (title: string, answersList: string, label: string) => {
  const serialNumber = localStorage.getItem("serialNumber") ?? "";

  return (
    `(${serialNumber}) ` +
    title +
    `${!!answersList ? `\n\n${label}:\n\n` : ""}` +
    answersList
  );
};

export const createFileObjectForPayload = (id: string) => ({
  directus_files_id: { id },
  Tasks_id: "+",
});

export const getCurrentSlide = (searchParams: URLSearchParams) => {
  const slide = searchParams.get("slide");

  return slide ? Number(slide) : 0;
};
