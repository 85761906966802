type PromptProps = {
  onClick: () => void;
  isVisible: boolean;
};

export interface TextPromptProps extends PromptProps {
  descriptionMissing: boolean;
}

export interface PhotoPromptProps extends PromptProps {
  minPhotos: number | null | undefined;
  maxPhotos: number | null | undefined;
  minPhotosNotReached: boolean;
  isRequired: boolean;
}

export enum PromptType {
  TEXT = "text",
  PHOTO = "photo",
}
