import { useEffect, useState, ChangeEvent, useCallback, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { QUESTION_TYPE_IDS, Answers, FormTypes, QuestionObject } from "types/types";
import { isIOS } from "utils/utils";
import { getPhotoCount } from "utils/photos.utils";
import useTimeout from "hooks/useTimeout";
import useActionText from "hooks/useActionText";
import useActionPhoto from "hooks/useActionPhoto";
import useQuestionnaire from "hooks/useQuestionnaire";
import useContentOverflow from "hooks/useContentOverflow";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";

import QuestionActions from "components/molecules/question-actions/QuestionActions";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import ReactionModal from "components/molecules/reaction-modal/ReactionModal";
import NoTypeWarning from "./components/NoTypeWarning";
import WebcamCapture from "components/molecules/webcam-capture/WebcamCapture";
import PhotosInAnswer from "./components/PhotosInAnswer";
import NavHeader from "./components/NavHeader";
import QuestionTitle from "./components/QuestionTitle";
import ReactionInAnswer from "./components/ReactionInAnswer";
import ScrollButton from "./components/ScrollButton";
import TextPrompt from "components/molecules/action-prompts/TextPrompt";
import PhotoPrompt from "components/molecules/action-prompts/PhotoPrompt";
import NoQuestionsAlert from "./components/NoQuestionsAlert";

import { ContentContainer, Divider } from "styles/generalStyles";
import { MainField, PromptsContainer } from "./styles/questionnaireStyles";

const { NUMERIC, TEXT, YES_NO, FILE_PHOTO, SELECT } = QUESTION_TYPE_IDS;
const { YES, NO, NA } = Answers;
const { QUESTIONNAIRE } = FormTypes;

function Questionnaire() {
  const {
    createAnswer,
    removeAnswer,
    currentQuestion,
    currentQuestionIndex,
    questionType,
    answerInputValue,
    setAnswerInputValue,
    answerTextAreaValue,
    setAnswerTextAreaValue,
    handleAnswerTextArea,
    answerSelectValue,
    setAnswerSelectValue,
    reactionTextAreaValue,
    setReactionTextAreaValue,
    goToNextQuestion,
    goToPreviousQuestion,
    cameraVisible,
    setCameraVisible,
    clearInputFields,
    clearState,
    answerToCurrentQuestion,
    removePhoto,
    removeReactionComment,
    serialNumberPath,
    numberOfQuestions,
    isTyping,
    saved,
  } = useQuestionnaire();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [reactionModalOpen, setReactionModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [photosInAnswerVisible, setPhotosInAnswerVisible] = useState(false);
  const [minPhotosNotReached, setMinPhotosNotReached] = useState(false);
  const [descriptionMissing, setDescriptionMissing] = useState(false);
  const [selectEmpty, setSelectEmpty] = useState(false);
  const [numberInputEmpty, setNumberInputEmpty] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation("side-navbar", {
    useSuspense: true,
  });

  const { getNumberOfPhotos } = usePhotosObjectStore(QUESTIONNAIRE);

  const { isPhotoPromptVisible, minPhotos, maxPhotos } = useActionPhoto(
    currentQuestion,
    answerToCurrentQuestion,
  );

  const { isTextPromptVisible } = useActionText(
    currentQuestion,
    answerToCurrentQuestion,
    answerTextAreaValue,
    reactionTextAreaValue,
    language,
  );

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const {
    isContentOverlow,
    isScrollBtnVisible,
    isBottom,
    onScrollButtonClick,
    onScroll,
  } = useContentOverflow({
    contentContainerRef,
    currentQuestionIndex,
  });

  const { setUpPhotosObjectStore } = usePhotosObjectStore(QUESTIONNAIRE);
  const setTimeOut = useTimeout();

  const dividerVivsible =
    isContentOverlow &&
    (!!answerToCurrentQuestion?.ReactionValue || photosInAnswerVisible);

  const isRequired = !!currentQuestion?.Required;

  // --------------- Modals handlers ---------------

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const openReactionModal = () => {
    setReactionModalOpen(true);
  };

  const closeReactionModal = () => {
    setReactionModalOpen(false);
  };

  // --------------- Textarea and camera handlers ---------------

  const displayCamera = useCallback(() => {
    setCameraVisible(true);
  }, [setCameraVisible]);

  const hideCamera = useCallback(() => {
    setCameraVisible(false);
  }, [setCameraVisible]);

  const handleCloseCameraView = () => {
    setSearchParams({});
  };

  // --------------- Navigate handlers ---------------

  const goToChecklists = () => {
    navigate(serialNumberPath, { replace: true });
  };

  // --------------- On click handlers ---------------

  const onBackClick = () => {
    if (!numberOfQuestions) {
      onQuitQuestionnaireClick();
      return;
    }

    if (currentQuestionIndex === 0) {
      openConfirmationModal();
    } else {
      if (cameraVisible) {
        hideCamera();
      }
      goToPreviousQuestion();
    }

    if (currentQuestionIndex > 0) {
      clearInputFields();
    }
  };

  const onForwardClick = () => {
    goToNextQuestion();
  };

  const onYesClick = () => {
    if (!currentQuestion) return;

    const { OnYesActionText, OnYesActionPhoto, OnYesRequiredMinText } = currentQuestion;

    if (!answerToCurrentQuestion) {
      createAnswer({ yesNo: YES });
    }

    if (!!answerToCurrentQuestion) {
      const { ReactionValue, Question } = answerToCurrentQuestion;
      const hasMinPhotosTaken = getPhotoCount(Question) >= minPhotos;
      const hasDescription = !!ReactionValue;

      if (isRequired) {
        if (OnYesActionText && OnYesActionPhoto) {
          if (
            (hasMinPhotosTaken && !OnYesRequiredMinText) ||
            (hasMinPhotosTaken && hasDescription)
          ) {
            goToNextQuestion();
          } else {
            if (!hasMinPhotosTaken) setMinPhotosNotReached(true);
            if (!hasDescription) setDescriptionMissing(true);
          }
        } else if (OnYesActionText) {
          if (!OnYesRequiredMinText || hasDescription) {
            goToNextQuestion();
          } else {
            setDescriptionMissing(true);
          }
        } else if (OnYesActionPhoto) {
          if (hasMinPhotosTaken) {
            goToNextQuestion();
          } else {
            setMinPhotosNotReached(true);
          }
        } else {
          goToNextQuestion();
        }
      } else {
        goToNextQuestion();
      }
    }
  };

  const onNoClick = () => {
    if (!currentQuestion) return;

    const { OnNoActionText, OnNoActionPhoto, OnNoRequiredMinText } = currentQuestion;

    if (!answerToCurrentQuestion) {
      createAnswer({ yesNo: NO });
    }

    if (!!answerToCurrentQuestion) {
      const { ReactionValue, Question } = answerToCurrentQuestion;
      const hasMinPhotosTaken = getPhotoCount(Question) >= minPhotos;
      const hasDescription = !!ReactionValue;

      if (isRequired) {
        if (OnNoActionText && OnNoActionPhoto) {
          if (
            (hasMinPhotosTaken && !OnNoRequiredMinText) ||
            (hasMinPhotosTaken && hasDescription)
          ) {
            goToNextQuestion();
          } else {
            if (!hasMinPhotosTaken) setMinPhotosNotReached(true);
            if (OnNoRequiredMinText && !hasDescription) setDescriptionMissing(true);
          }
        } else if (OnNoActionText) {
          if (!OnNoRequiredMinText || hasDescription) {
            goToNextQuestion();
          } else {
            setDescriptionMissing(true);
          }
        } else if (OnNoActionPhoto) {
          if (hasMinPhotosTaken) {
            goToNextQuestion();
          } else {
            setMinPhotosNotReached(true);
          }
        } else {
          goToNextQuestion();
        }
      } else {
        goToNextQuestion();
      }
    }
  };

  const onNaClick = () => {
    createAnswer({ yesNo: NA });

    if (answerToCurrentQuestion) {
      goToNextQuestion();
    }
  };

  const onNextClick = async () => {
    hideCamera();

    switch (questionType) {
      case FILE_PHOTO:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (!!answerToCurrentQuestion) {
            const { Question: id, ReactionValue } = answerToCurrentQuestion;
            const hasPhotos = !!((await getNumberOfPhotos(id)) as number);

            if (!hasPhotos && !ReactionValue) {
              removeAnswer(id);
            }
          }

          goToNextQuestion();

          return;
        }

        // --- REQUIRED ---
        const questionId = answerToCurrentQuestion?.Question
          ? answerToCurrentQuestion?.Question
          : "";
        const photosNumber = (await getNumberOfPhotos(questionId)) as number;

        if (minPhotosNotReached) return;

        if (photosNumber < minPhotos) {
          setMinPhotosNotReached(true);
        } else {
          if (!!answerToCurrentQuestion) {
            goToNextQuestion();
          } else {
            createAnswer({ photoSrc: "" });
            goToNextQuestion();
          }
        }
        break;

      case SELECT:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (answerToCurrentQuestion) {
            if (!answerSelectValue.value) {
              const { Question: id } = answerToCurrentQuestion;
              removeAnswer(id);
            }
          }
          goToNextQuestion();
          return;
        }

        // --- REQUIRED ---
        if (!answerToCurrentQuestion?.Value) {
          setSelectEmpty(true);
        } else {
          createAnswer({ selectValue: answerSelectValue.value });
          goToNextQuestion();
        }
        break;

      case NUMERIC:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (!!answerInputValue) {
            createAnswer({ numericValue: answerInputValue });
          }

          if (answerToCurrentQuestion) {
            if (!answerInputValue) {
              const { Question: id } = answerToCurrentQuestion;
              removeAnswer(id);
            }
          }

          goToNextQuestion();
          return;
        }

        // --- REQUIRED ---
        if (!!answerInputValue) {
          createAnswer({ numericValue: answerInputValue });
          goToNextQuestion();
        } else {
          setNumberInputEmpty(true);
        }

        break;

      case TEXT:
        if (!!answerToCurrentQuestion) {
          if (!answerTextAreaValue) {
            const { Question: id } = answerToCurrentQuestion;
            removeAnswer(id);
          }
        }

        goToNextQuestion();
    }
  };

  const onEditIconClick = () => {
    const value = answerToCurrentQuestion?.ReactionValue ?? "";
    setReactionTextAreaValue(value);
    openReactionModal();
  };

  const onQuitQuestionnaireClick = () => {
    clearState();
    goToChecklists();
  };

  const onAddTextReactionBtnClick = () => {
    setReactionTextAreaValue("");
    setTimeOut(() => {
      openReactionModal();
    }, 10);
  };

  const onSaveReactionBtnClick = () => {
    createAnswer({ yesNo: answerToCurrentQuestion?.Value as Answers });
    setReactionTextAreaValue("");
    closeReactionModal();
  };

  const onTakePhotoBtnClick = () => {
    if (isIOS) {
      sessionStorage.setItem("returnPath", pathname);
      sessionStorage.setItem("goToPath", `${pathname}?cameraView=true`);
      navigate("/camera-detection");
    } else {
      setSearchParams({ cameraView: "true" });
    }
  };

  // --------------- Event handlers ---------------

  const onAnswerInputChange = (value: string) => {
    setAnswerInputValue(value);
  };

  const onAnswerTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    let value: string | undefined = typeof e === "string" ? e : e.target.value;

    handleAnswerTextArea(value);
  };

  const onReactionTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    if (typeof e === "string") {
      setReactionTextAreaValue(e);
    } else {
      setReactionTextAreaValue(e.target.value);
    }
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    if (questionType.includes(YES_NO) || questionType === FILE_PHOTO) {
      setReactionTextAreaValue(textTranscript);
    }

    if (questionType === TEXT) {
      onAnswerTextAreaChange(textTranscript);
    }
  };

  const onAnswerSelectChange = (value: string) => {
    createAnswer({ selectValue: value });
    setAnswerSelectValue({ label: value, value });
  };

  // --------------- Setting up photos object store ---------------

  useEffect(() => {
    setUpPhotosObjectStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------- Reaction to flipping through questions ---------------

  useEffect(() => {
    if (!currentQuestion) return;

    const answerValue = answerToCurrentQuestion?.Value as string;

    switch (questionType) {
      case TEXT:
        setAnswerTextAreaValue(answerValue);
        break;
      case NUMERIC:
        setAnswerInputValue(answerValue);
        break;
      case SELECT:
        setAnswerSelectValue({ label: answerValue, value: answerValue });
    }
  }, [
    currentQuestion,
    questionType,
    setAnswerInputValue,
    setAnswerTextAreaValue,
    answerToCurrentQuestion,
    cameraVisible,
    setAnswerSelectValue,
  ]);

  // --------------- Camera view handler ---------------

  useEffect(() => {
    const isCameraView = Boolean(searchParams.get("cameraView"));

    if (isCameraView) {
      displayCamera();
    } else {
      hideCamera();
    }
  }, [searchParams, displayCamera, hideCamera]);

  // --------------- Min photos number not reached handler ---------------

  useEffect(() => {
    if (minPhotosNotReached) {
      setTimeOut(() => {
        setMinPhotosNotReached(false);
      }, 2000);
    }
  }, [minPhotosNotReached, setTimeOut]);

  // --------------- Description missing reached handler ---------------

  useEffect(() => {
    if (descriptionMissing) {
      setTimeOut(() => {
        setDescriptionMissing(false);
      }, 2000);
    }
  }, [descriptionMissing, setTimeOut]);

  // --------------- Empty select handler ---------------

  useEffect(() => {
    if (selectEmpty) {
      setTimeOut(() => {
        setSelectEmpty(false);
      }, 2000);
    }
  }, [selectEmpty, setTimeOut]);

  // --------------- Empty number input handler ---------------

  useEffect(() => {
    if (numberInputEmpty) {
      setTimeOut(() => {
        setNumberInputEmpty(false);
      }, 2000);
    }
  }, [numberInputEmpty, setTimeOut]);

  return (
    <ContentContainer ref={contentContainerRef} onScroll={onScroll}>
      <NavHeader
        onBackClick={onBackClick}
        onForwardClick={onForwardClick}
        answered={!!answerToCurrentQuestion}
        noQuestions={!numberOfQuestions}
        isTyping={isTyping}
      />
      <QuestionTitle required={isRequired} />
      {!!questionType && (
        <MainField
          hidden={
            questionType === TEXT || questionType === NUMERIC || questionType === SELECT
          }
        >
          <PhotosInAnswer
            formType={QUESTIONNAIRE}
            minPhotos={minPhotos}
            maxPhotos={maxPhotos}
            removePhoto={removePhoto}
            questionType={questionType}
            onTakePhotoBtnClick={onTakePhotoBtnClick}
            currentQuestionId={currentQuestion?.id as string}
            answerToCurrentQuestion={answerToCurrentQuestion}
            setPhotosInAnswerVisible={setPhotosInAnswerVisible}
            minPhotosNotReached={minPhotosNotReached}
            isRequired={isRequired}
          />

          <PromptsContainer>
            <PhotoPrompt
              onClick={onTakePhotoBtnClick}
              minPhotos={minPhotos}
              maxPhotos={maxPhotos}
              minPhotosNotReached={minPhotosNotReached}
              isRequired={isRequired}
              isVisible={!!isPhotoPromptVisible}
            />
            <TextPrompt
              onClick={onAddTextReactionBtnClick}
              descriptionMissing={descriptionMissing}
              isVisible={!!isTextPromptVisible}
            />
          </PromptsContainer>

          <ReactionInAnswer
            value={answerToCurrentQuestion?.ReactionValue}
            onEditIconClick={onEditIconClick}
            removeReactionComment={removeReactionComment}
          />

          {cameraVisible && (
            <WebcamCapture
              createAnswer={createAnswer}
              currentQuestionId={currentQuestion?.id}
              maxPhotos={maxPhotos}
              handleCloseCameraView={handleCloseCameraView}
              formType={QUESTIONNAIRE}
            />
          )}

          {dividerVivsible && <Divider />}
        </MainField>
      )}

      {!numberOfQuestions && (
        <NoQuestionsAlert onQuitQuestionnaireClick={onQuitQuestionnaireClick} />
      )}

      {currentQuestion && !questionType && <NoTypeWarning />}

      {!!questionType && (
        <QuestionActions
          currentQuestion={currentQuestion as QuestionObject}
          answerToCurrentQuestion={answerToCurrentQuestion}
          onYesClick={onYesClick}
          onNoClick={onNoClick}
          onNaClick={onNaClick}
          onNextClick={onNextClick}
          onTextTranscriptChange={onTextTranscriptChange}
          onAnswerInputChange={onAnswerInputChange}
          answerInputValue={answerInputValue}
          onAnswerTextAreaChange={onAnswerTextAreaChange}
          answerTextAreaValue={answerTextAreaValue}
          onAnswerSelectChange={onAnswerSelectChange}
          answerSelectValue={answerSelectValue}
          removeAnswer={removeAnswer}
          searchParams={searchParams}
          selectEmpty={selectEmpty}
          numberInputEmpty={numberInputEmpty}
          isTyping={isTyping}
          saved={saved}
        />
      )}

      {isScrollBtnVisible && (
        <ScrollButton isBottom={isBottom} onClick={onScrollButtonClick} />
      )}

      {/* --------------- Modals --------------- */}

      <ReactionModal
        open={reactionModalOpen}
        onClose={closeReactionModal}
        questionType={questionType}
        required={isRequired}
        reactionTextAreaValue={reactionTextAreaValue}
        onReactionTextAreaChange={onReactionTextAreaChange}
        onTextTranscriptChange={onTextTranscriptChange}
        onSaveReactionBtnClick={onSaveReactionBtnClick}
      />

      <ConfirmationModal
        message={t("modal.message", { ns: "side-navbar" })}
        onClick={onQuitQuestionnaireClick}
        onClose={closeConfirmationModal}
        open={confirmationModalOpen}
        buttonLabel={t("modal.button-label", { ns: "side-navbar" })}
      />
    </ContentContainer>
  );
}

export default Questionnaire;
