import { useSearchParams } from "react-router-dom";

import { PresetObjectKeys } from "pages/user-tasks/components/tasks-handler/components/tasks-list/components/tasks-filter/types/task.filter.types";
import { basePath } from "pages/user-tasks/components/tasks-handler/utils/tasks-handler.utils";
import { TaskStatuses } from "types/tasks.types";

const { ASSIGN_TO, DEADLINE, STATUS, USER_CREATED } = PresetObjectKeys;

function useTasksURLPath(userId: string | undefined) {
  const [searchParams] = useSearchParams();

  let tasksURLPath = basePath;

  const searchParams_status = searchParams.get(STATUS);
  const searchParams_AssignTo = searchParams.get(ASSIGN_TO);
  const searchParams_user_created = searchParams.get(USER_CREATED);
  const searchParams_deadline = searchParams.get(DEADLINE);

  if (!userId) return { tasksURLPath };

  if (searchParams_status) {
    const status = searchParams_status as TaskStatuses;
    tasksURLPath = `${tasksURLPath}&filter[_and][1][status][_eq]=${status}`;
  }

  if (searchParams_AssignTo) {
    tasksURLPath = `${tasksURLPath}&filter[_and][0][_and][0][AssignTo][id][_eq]=${userId}`;
  }

  if (searchParams_user_created) {
    tasksURLPath = `${tasksURLPath}&filter[_and][0][_and][0][user_created][id][_eq]=${userId}`;
  }

  if (searchParams_deadline) {
    tasksURLPath = `${tasksURLPath}&filter[_and][0][_and][0][Deadline][_lt]=$NOW`;
  }

  return { tasksURLPath };
}

export default useTasksURLPath;
