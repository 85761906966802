import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  QUESTION_TYPE_IDS,
  Answers,
  CountryCodes,
  Answer as AnswerType,
  QuestionObject,
} from "types/types";
import { NumberOfPhotos, PhotoPreview } from "../../types/tableOfContents.types";

import Thumb from "components/atoms/Thumb";
import PhotosInfo from "./components/photos-info/PhotosInfo";

const Container = styled.div<{ isAnswered: boolean }>`
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  font-size: 1.2rem;

  ${({ isAnswered }) =>
    isAnswered &&
    css`
      border-top: 1px solid rgba(75, 78, 83, 0.2);
    `}

  .icon-yes {
    height: 2rem;

    path {
      fill: ${({ theme }) => theme.yesButton};
    }
  }

  .icon-no {
    height: 2rem;
    margin-left: 1rem;

    path {
      fill: ${({ theme }) => theme.noButton};
    }
  }
`;

const AnswerValue = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;

  .numeric,
  .select {
    font-size: 1.5rem;
    text-align: right;
  }

  .align {
    transform: translateY(0.2rem);
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const YesNoAnswerContainer = styled.div<{ flexEnd?: boolean }>`
  display: flex;
  justify-content: ${({ flexEnd }) => (flexEnd ? "flex-end" : "space-between")};
  align-items: center;
`;

const NoAnswer = styled.div`
  font-style: italic;
  opacity: 0.6;
  font-size: 1.3rem;
  text-align: right;
`;

const NaLabel = styled.span`
  font-size: 1.3rem;
  font-family: GothamBold;
  color: ${({ theme }) => theme.primary_300};
`;

const DescriptionPreview = styled.div<{ paddingTop?: boolean; short?: boolean }>`
  font-size: 1.4rem;
  font-style: italic;
  ${({ theme }) => theme.ellipsis}

  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 0.5rem;
    `}

    ${({ short }) =>
    short &&
    css`
      width: 90%;
    `}
`;

const NoDescription = styled.span`
  color: ${({ theme }) => theme.errorColor};
  opacity: 0.6;
`;

const { TEXT, FILE_PHOTO, NUMERIC, SELECT } = QUESTION_TYPE_IDS;
const { YES, NO } = Answers;

const ThumbIcon = ({ up = false, down = false }: { up?: boolean; down?: boolean }) => {
  const customClass = up ? "icon-yes" : "icon-no";

  return <Thumb up={up} down={down} filled customClass={customClass} />;
};

type AnswerProps = {
  answer: AnswerType | undefined;
  question: QuestionObject;
  minPhotos: number | null;
  photoPreview: PhotoPreview;
  numberOfPhotos: NumberOfPhotos;
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = {
    na: "Not Applicable",
    photos: "Photos",
    missing: "Missing",
  };
  let no_description = "No description...";

  if (isPolish) {
    label = {
      na: "Nie Dotyczy",
      photos: "Zdjęcia",
      missing: "Brakuje",
    };

    no_description = "Brak opisu...";
  }

  return { label, no_description };
};

function Answer({
  answer,
  question,
  minPhotos,
  photoPreview,
  numberOfPhotos,
}: AnswerProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation("table-of-contents");
  const {
    label: { na, ...labels },
    no_description,
  } = getTranslations(language);

  const {
    OnNoActionText,
    OnNoActionPhoto,
    OnYesActionText,
    OnYesActionPhoto,
    OnYesRequiredMinText,
    OnNoRequiredMinText,
  } = question;

  let answerValue = <NoAnswer>{t("no-answer")}</NoAnswer>;

  if (answer) {
    const { QuestionType, Value, ReactionValue } = answer;

    switch (QuestionType) {
      case TEXT:
        answerValue = <DescriptionPreview>{Value}</DescriptionPreview>;
        break;
      case NUMERIC:
        answerValue = <div className='numeric'>{Value}</div>;
        break;
      case SELECT:
        answerValue = <div className='select'>{Value}</div>;
        break;
      case FILE_PHOTO:
        answerValue = (
          <div>
            <PhotosInfo
              labels={labels}
              minPhotos={minPhotos as number}
              numberOfPhotos={numberOfPhotos ?? 0}
              photoPreview={photoPreview as string}
              required={question.Required}
            />

            {ReactionValue && (
              <DescriptionPreview paddingTop>{ReactionValue}</DescriptionPreview>
            )}
          </div>
        );
        break;
      default:
        answerValue = <NaLabel>{na}</NaLabel>;
        if (Value === YES)
          answerValue = (
            <YesNoAnswerContainer flexEnd>
              <ThumbIcon up />
            </YesNoAnswerContainer>
          );
        if (Value === NO)
          answerValue = (
            <YesNoAnswerContainer flexEnd>
              <ThumbIcon down />
            </YesNoAnswerContainer>
          );

        if (question.Required) {
          // ----- YES -----
          if (Value === YES) {
            if (OnYesActionText && OnYesActionPhoto) {
              answerValue = (
                <>
                  <YesNoAnswerContainer>
                    <PhotosInfo
                      labels={labels}
                      minPhotos={minPhotos as number}
                      numberOfPhotos={numberOfPhotos ?? 0}
                      photoPreview={photoPreview as string}
                      required={question.Required}
                    />
                    <ThumbIcon up />
                  </YesNoAnswerContainer>

                  <DescriptionPreview paddingTop>
                    {!!ReactionValue ? (
                      ReactionValue
                    ) : !!OnYesRequiredMinText ? (
                      <NoDescription>{no_description}</NoDescription>
                    ) : null}
                  </DescriptionPreview>
                </>
              );
            } else if (OnYesActionText) {
              answerValue = (
                <FlexContainer>
                  <DescriptionPreview short>
                    {!!ReactionValue ? (
                      ReactionValue
                    ) : !!OnYesRequiredMinText ? (
                      <NoDescription>{no_description}</NoDescription>
                    ) : (
                      <></>
                    )}
                  </DescriptionPreview>

                  <YesNoAnswerContainer flexEnd>
                    <ThumbIcon up />
                  </YesNoAnswerContainer>
                </FlexContainer>
              );
            } else if (OnYesActionPhoto) {
              answerValue = (
                <YesNoAnswerContainer>
                  <PhotosInfo
                    labels={labels}
                    minPhotos={minPhotos as number}
                    numberOfPhotos={numberOfPhotos ?? 0}
                    photoPreview={photoPreview as string}
                    required={question.Required}
                  />

                  <ThumbIcon up />
                </YesNoAnswerContainer>
              );
            }
          }

          // ----- NO -----
          if (Value === NO) {
            if (OnNoActionText && OnNoActionPhoto) {
              answerValue = (
                <>
                  <YesNoAnswerContainer>
                    <PhotosInfo
                      labels={labels}
                      minPhotos={minPhotos as number}
                      numberOfPhotos={numberOfPhotos ?? 0}
                      photoPreview={photoPreview as string}
                      required={question.Required}
                    />
                    <ThumbIcon down />
                  </YesNoAnswerContainer>

                  <DescriptionPreview paddingTop>
                    {!!ReactionValue ? (
                      ReactionValue
                    ) : !!OnNoRequiredMinText ? (
                      <NoDescription>{no_description}</NoDescription>
                    ) : null}
                  </DescriptionPreview>
                </>
              );
            } else if (OnNoActionText) {
              answerValue = (
                <FlexContainer>
                  <DescriptionPreview short>
                    {!!ReactionValue ? (
                      ReactionValue
                    ) : !!OnNoRequiredMinText ? (
                      <NoDescription>{no_description}</NoDescription>
                    ) : (
                      <></>
                    )}
                  </DescriptionPreview>

                  <YesNoAnswerContainer flexEnd>
                    <ThumbIcon down />
                  </YesNoAnswerContainer>
                </FlexContainer>
              );
            } else if (OnNoActionPhoto) {
              answerValue = (
                <YesNoAnswerContainer>
                  <PhotosInfo
                    labels={labels}
                    minPhotos={minPhotos as number}
                    numberOfPhotos={numberOfPhotos ?? 0}
                    photoPreview={photoPreview as string}
                    required={question.Required}
                  />

                  <ThumbIcon down />
                </YesNoAnswerContainer>
              );
            }
          }
        }
    }
  }

  return (
    <Container isAnswered={!!answer}>
      <AnswerValue>{answerValue}</AnswerValue>
    </Container>
  );
}

export default Answer;
