import { useEffect, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { getCookie } from "react-use-cookie";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";

import { useAppDispatch, useAppSelector } from "redux/hooks/hooks";
import { setTasksSearchParams } from "redux/slices/tasksSlice";
import {
  CreatePresetPayload,
  CreatePresetResponse,
  PresetObjectKeys,
  TasksPresetsResponse,
} from "pages/user-tasks/components/tasks-handler/components/tasks-list/components/tasks-filter/types/task.filter.types";
import {
  createTasksFiltersPreset,
  getTasksFiltersPresets,
  updateTasksFiltersPreset,
} from "api/tasks";
import { ACCESS_TOKEN } from "utils/utils";
import {
  TasksFiltersPreset,
  StatusPreset,
  AssignToPreset,
  UserCreatedPreset,
  DeadlinePreset,
  UpdatePresetPayload,
} from "pages/user-tasks/components/tasks-handler/components/tasks-list/components/tasks-filter/types/task.filter.types";

const { ASSIGN_TO, DEADLINE, STATUS, USER_CREATED } = PresetObjectKeys;

const findFieldInObject = (object: any) => (key: PresetObjectKeys) =>
  Object.keys(object).some((objKey) => objKey === key);

function useTasksFiltersPresets(user: string | undefined) {
  const { tasksSearchParams } = useAppSelector(({ tasks }) => tasks);
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const token = getCookie(ACCESS_TOKEN);
  const tasksPresetIdRef = useRef<number>();

  const onSuccess = (data: TasksPresetsResponse) => {
    let searchParams = "";

    if (data) {
      if (data.data.length) {
        if (data.data[0].id) {
          tasksPresetIdRef.current = data.data[0].id;
        }

        if (data.data.length) {
          const { filter }: TasksFiltersPreset = data.data[0];

          if (!filter) return;

          // ***** STATSUS *****
          const status_Preset = filter._and.find((item) =>
            findFieldInObject(item)(STATUS),
          );

          if (status_Preset) {
            const statusPreset = status_Preset as StatusPreset;
            searchParams = `?status=${statusPreset.status._eq}`;
          }

          // ***** ASSIGN TO *****
          const AssignTo_Preset = filter._and.find((item) =>
            findFieldInObject(item)(ASSIGN_TO),
          );

          if (AssignTo_Preset) {
            const { AssignTo } = AssignTo_Preset as AssignToPreset;
            searchParams = `${searchParams}&AssignTo=${AssignTo._eq}`;
          }

          // ***** USER CREATED *****
          const user_created_Preset = filter._and.find((item) =>
            findFieldInObject(item)(USER_CREATED),
          );

          if (user_created_Preset) {
            const { user_created } = user_created_Preset as UserCreatedPreset;
            searchParams = `${searchParams}&user_created=${user_created._eq}`;
          }

          // ***** DEADLINE *****
          const Deadline_Preset = filter._and.find((item) =>
            findFieldInObject(item)(DEADLINE),
          );

          if (Deadline_Preset) {
            const { Deadline } = Deadline_Preset as DeadlinePreset;
            searchParams = `${searchParams}&Deadline=lt:${Deadline._lt}`;
          }
        }
      }

      let params = !!searchParams ? searchParams : "?status=todo";

      dispatch(setTasksSearchParams(params));
      sessionStorage.setItem("tasksSearchParams", params);
    }
  };

  // ----- Get tasks filters presets -----
  const { isLoading: presetsLoading, refetch: refetchPresets } = useQuery(
    "tasksPresets",
    getTasksFiltersPresets(token),
    {
      onSuccess,
      keepPreviousData: false,
      enabled: !!token && !!user,
    },
  );

  // ----- Create tasks filters preset -----

  const { mutate: createPreset } = useMutation<
    CreatePresetResponse,
    AxiosError,
    CreatePresetPayload
  >(createTasksFiltersPreset, {
    onSuccess: () => {
      refetchPresets();
    },
    onError: () => {},
  });

  // ----- Update tasks filters preset -----
  const { mutate: updatePreset } = useMutation<any, AxiosError, UpdatePresetPayload>(
    updateTasksFiltersPreset,
    {
      onSuccess: () => {
        refetchPresets();
      },
      onError: () => {},
    },
  );

  const handleCreatePreset = (data: Omit<CreatePresetPayload, "token" | "user">) => {
    if (!user) return;

    createPreset({ ...data, token, user });
  };

  const handleUpdatePreset = (data: Omit<UpdatePresetPayload, "token" | "user">) => {
    if (!user) return;

    updatePreset({ ...data, token, user, icon: "smartphone" });
  };

  // --------------- Effect handler ---------------

  useEffect(() => {
    if (pathname.includes("/login")) {
      tasksPresetIdRef.current = undefined;
    }
  }, [pathname]);

  return {
    tasksSearchParams,
    presetsLoading,
    handleCreatePreset,
    handleUpdatePreset,
    tasksPresetId: tasksPresetIdRef.current,
  };
}

export default useTasksFiltersPresets;
