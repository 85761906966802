import { RefObject, useEffect, useState, useRef, UIEvent } from "react";

import usePrevious from "./usePrevious";
import useTimeout from "hooks/useTimeout";

type useContentOverflowProps = {
  contentContainerRef: RefObject<HTMLDivElement> | null;
  currentQuestionIndex: number;
};

function useContentOverflow({
  contentContainerRef,
  currentQuestionIndex,
}: useContentOverflowProps) {
  const [isContentOverlow, setIsContentOverFlow] = useState(false);
  const [isScrollBtnVisible, setIsScrollBtnVisible] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const prevQuestionIndex = usePrevious(currentQuestionIndex);

  const setTimeOut = useTimeout();

  const scrollHeightRef = useRef(0);
  const top = isBottom ? 0 : scrollHeightRef.current + 200;

  const scrollOptions: ScrollToOptions = {
    top,
    left: 0,
    behavior: "smooth",
  };

  const onScroll = ({ currentTarget }: UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = currentTarget;

    // The scrollTop property is sometimes a decimal number
    // that is why it is wrapped in Math.round().
    // It affects the general equation hence the +1 and -1
    // in the flag statement below.
    const base = Math.round(scrollTop) + clientHeight;
    const flag =
      base === scrollHeight || base + 1 === scrollHeight || base - 1 === scrollHeight;

    setIsBottom(flag);
    setIsScrollBtnVisible(true);
  };

  const onScrollButtonClick = () => {
    contentContainerRef?.current?.scrollTo(scrollOptions);
  };

  useEffect(() => {
    if (!contentContainerRef || !contentContainerRef.current) return;
    const contentContainer = contentContainerRef.current;
    const { clientHeight, scrollHeight } = contentContainer;
    scrollHeightRef.current = scrollHeight;

    setTimeOut(() => {
      setIsContentOverFlow(scrollHeight > clientHeight);
    }, 100);
  });

  useEffect(() => {
    setIsBottom(false);
    setIsScrollBtnVisible(false);

    if (!isContentOverlow) return;

    if (prevQuestionIndex !== currentQuestionIndex) {
      contentContainerRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
      setIsContentOverFlow(false);
    }
  }, [currentQuestionIndex, prevQuestionIndex, isContentOverlow, contentContainerRef]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isScrollBtnVisible) {
        setIsScrollBtnVisible(false);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [isScrollBtnVisible]);

  return {
    isContentOverlow,
    isScrollBtnVisible,
    isBottom,
    onScrollButtonClick,
    onScroll,
  };
}

export default useContentOverflow;
