import styled, { css } from "styled-components/macro";

import { isMobile } from "utils/utils";

import { ReactComponent as UncheckedIcon } from "assets/icons/checkbox-unchecked.svg";
import { ReactComponent as CheckedIcon } from "assets/icons/checkbox-checked.svg";

const Container = styled.div<{
  archived: boolean;
  small: boolean;
  dark: boolean;
  thin: boolean;
  isMobile: boolean;
}>`
  display: grid;
  place-content: center;

  ${({ archived }) =>
    archived &&
    css`
      opacity: 0.4;
    `}

  .checkbox-icon {
    width: ${({ small }) => (small ? "2rem" : "2.7rem")};
    height: ${({ small }) => (small ? "2rem" : "2.7rem")};
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

    ${({ dark }) =>
      dark &&
      css`
        path {
          stroke: ${({ theme }) => theme.primary};
        }
      `}

    ${({ thin }) =>
      thin &&
      css`
        path {
          stroke-width: 1.5;
        }
      `}
  }
`;

function CheckboxIcon({
  checked,
  archived = false,
  small = false,
  dark = false,
  thin = false,
}: {
  checked: boolean;
  archived?: boolean;
  small?: boolean;
  dark?: boolean;
  thin?: boolean;
}) {
  const icon = checked ? (
    <CheckedIcon className='checkbox-icon' />
  ) : (
    <UncheckedIcon className='checkbox-icon' />
  );

  return (
    <Container
      archived={archived}
      small={small}
      dark={dark}
      thin={thin}
      isMobile={isMobile}
    >
      {icon}
    </Container>
  );
}

export default CheckboxIcon;
