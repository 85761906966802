import { useTranslation } from "react-i18next";

import { PhotoPromptProps, PromptType } from "./types/prompt.types";
import useAnimate from "hooks/useAnimate";
import { getPromptTranslations } from "./translations/prompt.translations";

import { ReactComponent as PhotoIcon } from "assets/icons/photo-camera-2.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

import PromptContainer from "./components/prompt-container/PromptContainer";

import {
  IconContainer,
  Label,
  PhotoCountContainer,
  MinPhotosWarning,
} from "./styles/prompt.styles";

function PhotoPrompt({
  onClick,
  minPhotos,
  maxPhotos,
  minPhotosNotReached,
  isRequired,
  isVisible,
}: PhotoPromptProps) {
  const { animate, duration } = useAnimate();

  const {
    i18n: { language },
  } = useTranslation();
  const { label, warning } = getPromptTranslations(PromptType.PHOTO, language);

  return isVisible ? (
    <PromptContainer
      onClick={onClick}
      animate={animate}
      duration={duration}
      shaking={minPhotosNotReached}
      className='photo-prompt'
    >
      <PhotoCountContainer isRed={minPhotosNotReached}>
        {isRequired && <span>min:&nbsp;{minPhotos}&nbsp;|</span>}
        <span>&nbsp;max:&nbsp;{maxPhotos}</span>
      </PhotoCountContainer>

      <IconContainer isRed={minPhotosNotReached}>
        <PhotoIcon className='icon' />
      </IconContainer>

      {!minPhotosNotReached ? (
        <Label>{label}</Label>
      ) : (
        <MinPhotosWarning>
          <WarningIcon className='warning-icon' />
          <span>
            {warning.minPhotos}&nbsp;{minPhotos}
          </span>
        </MinPhotosWarning>
      )}
    </PromptContainer>
  ) : null;
}

export default PhotoPrompt;
