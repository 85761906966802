import styled, { css } from "styled-components/macro";
import { device } from "utils/utils";

export const Container = styled.div<{
  isFocused: boolean;
  isMobile: boolean;
  isIOS: boolean;
  isHeightSet: boolean;
}>`
  width: 100%;
  padding-bottom: 1rem;

  ${({ isHeightSet }) =>
    isHeightSet &&
    css`
      height: 100%;
    `}

  display: ${({ isMobile, isFocused }) =>
    !isMobile ? "grid" : isMobile && isFocused ? "block" : "grid"};

  ${({ isIOS }) =>
    isIOS &&
    css`
      @media screen and (min-width: 340px) {
        padding-bottom: 2.25rem;
      }
    `}

  @media ${device.iPad} {
    display: ${({ isFocused }) => (isFocused ? "block" : "grid")};
  }
`;

export const InputWrapper = styled.div<{ animate: boolean; duration: string }>`
  position: relative;

  .close-icon {
    left: 0.5rem;
    top: 1.3rem;
    width: 2.2rem;
    height: 2.2rem;
  }

  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `};
`;

export const Actions = styled.div<{ spaceBetween?: boolean; isNA?: boolean }>`
  display: flex;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? "space-between" : "flex-end")};
  align-items: center;
  padding-top: 0.7rem;
  width: 100%;
  place-self: end;
  position: relative;

  .enlarged {
    ${({ isNA }) =>
      isNA
        ? css`
            /* position: absolute;
            bottom: 0; */
            width: 100%;
          `
        : css`
            flex-basis: 100%;
          `}
  }

  .hidden {
    display: none;
  }
`;

export const NextBtnContainer = styled.div`
  position: relative;
  width: 15rem;

  .save-next-btn {
    font-size: 1.6rem;
  }
`;

export const BtnContainer = styled.div<{ isNA: boolean; isAnswered?: boolean }>`
  position: relative;
  flex-basis: ${({ isNA }) => (isNA ? "32%" : "48%")};

  ${({ isAnswered }) =>
    isAnswered &&
    css`
      flex-basis: 100%;
    `}
`;

export const ResetQuestion = styled.div<{
  yes?: boolean;
  no?: boolean;
  isMobile: boolean;
}>`
  position: absolute;
  top: -1.5rem;
  right: -1.1rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.cancelColor};
  z-index: ${({ theme }) => theme.level10};
  ${({ theme }) => theme.buttonShadow};
  ${({ theme }) => theme.buttonShadow};
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  transition: all 0.2s ease-in-out;

  ${({ yes }) =>
    yes &&
    css`
      background-color: ${({ theme }) => theme.yesButton};
    `}

  ${({ no }) =>
    no &&
    css`
      background-color: ${({ theme }) => theme.noButton};
    `}
  
    .x-icon {
    fill: ${({ theme }) => theme.white};
  }

  &:hover {
    filter: brightness(95%);
  }
`;

export const BarcodeTextIconsContainer = styled.div<{
  isDictaphoneAccessible: boolean;
  isMobile: boolean;
}>`
  position: absolute;
  left: ${({ isDictaphoneAccessible }) => (isDictaphoneAccessible ? "5rem" : "0")};
  top: 50%;
  transform: translateY(-37%);

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  }
`;
