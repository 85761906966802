import { configureStore } from "@reduxjs/toolkit";
import { mainSlice } from "../slices/mainSlice";
import { serviceWorkerSlice } from "../slices/serviceWorkerSlice";
import { tasksSlice } from "../slices/tasksSlice";
import { actionTextSlice } from "../slices/actionTextSlice";

export const store = configureStore({
  reducer: {
    main: mainSlice.reducer,
    sw: serviceWorkerSlice.reducer,
    tasks: tasksSlice.reducer,
    actionText: actionTextSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
