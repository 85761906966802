import { useEffect, useState } from "react";

import { AssessmentData, CreatedAnswer, Stores } from "types/types";
import useIndexedDB from "./useIndexedDB";

const store = Stores.AssessmentData;

const getLocalData = () => {
  const localData = sessionStorage.getItem("assessmentData");

  return !!localData ? JSON.parse(localData) : undefined;
};

function useAssessmentResponse() {
  const [assessmentData, setAssessmentData] = useState<{
    assessmentId: string;
    createdAnswersLength: number;
  }>(getLocalData());
  const { addItem, getItem, deleteAll } = useIndexedDB(store);

  const setAssessmentDataInIndexedDB = async (data: AssessmentData) => {
    const { assessmentId, createdAnswers } = data;
    const createdAnswersLength = createdAnswers.length;

    setAssessmentData({
      assessmentId,
      createdAnswersLength,
    });

    await addItem(data);
  };

  const getCreatedAnswersFromIndexedDB = async () => {
    if (!assessmentData) return;

    let answers: CreatedAnswer[] = [];

    const { assessmentId } = assessmentData;

    const data = (await getItem(assessmentId)) as AssessmentData;

    if (data) {
      answers = data.createdAnswers;
    }

    return answers;
  };

  const deleteAssessmentDataInIndexedDB = async () => {
    await deleteAll();
  };

  useEffect(() => {
    if (!assessmentData || !!sessionStorage.getItem("assessmentData")) return;

    sessionStorage.setItem("assessmentData", JSON.stringify(assessmentData));
  }, [assessmentData]);

  return {
    assessmentData,
    setAssessmentDataInIndexedDB,
    getCreatedAnswersFromIndexedDB,
    deleteAssessmentDataInIndexedDB,
  };
}

export default useAssessmentResponse;
