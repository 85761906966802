import { useTranslation } from "react-i18next";

import { PromptType, TextPromptProps } from "./types/prompt.types";
import { getPromptTranslations } from "./translations/prompt.translations";
import useAnimate from "hooks/useAnimate";

import { ReactComponent as PenIcon } from "assets/icons/edit_square.svg";

import PromptContainer from "./components/prompt-container/PromptContainer";

import { IconContainer, Label } from "./styles/prompt.styles";

function TextPrompt({ onClick, descriptionMissing, isVisible }: TextPromptProps) {
  const { animate, duration } = useAnimate();

  const {
    i18n: { language },
  } = useTranslation();
  const { label } = getPromptTranslations(PromptType.TEXT, language);

  return isVisible ? (
    <PromptContainer
      onClick={onClick}
      animate={animate}
      duration={duration}
      className='text-prompt'
      shaking={descriptionMissing}
    >
      <IconContainer isRed={descriptionMissing}>
        <PenIcon className='icon' />
      </IconContainer>
      <Label isRed={descriptionMissing}>{label}</Label>
    </PromptContainer>
  ) : null;
}

export default TextPrompt;
