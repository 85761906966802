import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  minText: number;
  maxText: number;
  textLength: number;
  isTextTooShort: boolean;
  isTextTooLong: boolean;
  isMinText: boolean;
  errorMessage: string;
}

const initialState: InitialState = {
  minText: 0,
  maxText: 0,
  textLength: 0,
  isTextTooShort: false,
  isTextTooLong: false,
  isMinText: false,
  errorMessage: "",
};

export const actionTextSlice = createSlice({
  name: "actionTextSlice",
  initialState,
  reducers: {
    setActionText(state, action: PayloadAction<InitialState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setActionText } = actionTextSlice.actions;
