import { TaskStatuses } from "types/tasks.types";

export enum UserLabelKeys {
  AssignTo = "AssignTo",
  user_created = "user_created",
  expired = "expired",
}

export type FilterLabels = UserLabelKeys | TaskStatuses;

export enum FilterTypeKeys {
  USER = "user",
  STATUS = "status",
  EXPIRED = "expired",
}

export enum PresetObjectKeys {
  STATUS = "status",
  DEADLINE = "Deadline",
  ASSIGN_TO = "AssignTo",
  USER_CREATED = "user_created",
}

export type TaskFilter = {
  user: UserLabelKeys[];
  status: TaskStatuses;
  expired: boolean;
};

export type StatusFilter = {
  _eq: TaskStatuses;
};

export type AssignToFilter = {
  _eq: UserLabelKeys;
};

export type UserCreatedFilter = {
  _eq: "$CURRENT_USER";
};

export type DeadlineFilter = {
  _lt: "$NOW";
};

export type FiltersArray = [
  {
    status: StatusFilter;
  },
  {
    AssignTo?: AssignToFilter;
  },
  {
    user_created?: UserCreatedFilter;
  },
  {
    Deadline?: DeadlineFilter;
  },
];

type TasksFilters = {
  _and: FiltersArray;
};

export type TasksFiltersPreset = {
  bookmark: string | null;
  filter: TasksFilters;
  id?: number;
};

export type TasksPresetsResponse = {
  data: TasksFiltersPreset[];
};

export type StatusPreset = {
  status: StatusFilter;
};

export type AssignToPreset = {
  AssignTo: AssignToFilter;
};

export type UserCreatedPreset = {
  user_created: UserCreatedFilter;
};

export type DeadlinePreset = {
  Deadline: DeadlineFilter;
};

export type PresetPayloadFilters =
  | {
      status: {
        _eq: TaskStatuses;
      };
      AssignTo?: undefined;
      user_created?: undefined;
      Deadline?: undefined;
    }
  | {
      AssignTo: {
        _eq: string | null;
      };
      status?: undefined;
      user_created?: undefined;
      Deadline?: undefined;
    }
  | {
      user_created: {
        _eq: string | null;
      };
      status?: undefined;
      AssignTo?: undefined;
      Deadline?: undefined;
    }
  | {
      Deadline: {
        _lt: string | null;
      };
      status?: undefined;
      user_created?: undefined;
      AssignTo?: undefined;
    };

export type UpdatePresetPayload = {
  presetId: number;
  token: string;
  collection: "Tasks";
  icon: string;
  filter: {
    _and: PresetPayloadFilters[];
  };
  user: string;
};

export type CreatePresetPayload = {
  token: string;
  filter: {
    _and: PresetPayloadFilters[];
  };
  bookmark: string | null;
  collection: "Tasks";
  user: string;
};

export type CreatePresetResponse = {
  data: {
    id: number;
    bookmark: string | null;
    user: string;
    collection: "Tasks";
    icon: string;
    filter: {
      _and: PresetPayloadFilters[];
    };
  };
};
