import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import useClickOutside from "hooks/useClickOutside";
import { device, isMobile } from "utils/utils";
import {
  getUserFilters,
  getStatusFilters,
  getExpiredLabel,
  getInitialFilters,
  getFiltersArray,
} from "./utils/tasks.filter.utils";
import { getTranslations } from "./translations/task.filter.translations";
import { TaskStatuses } from "types/tasks.types";
import {
  FilterLabels,
  FilterTypeKeys,
  UserLabelKeys,
  PresetObjectKeys,
  TaskFilter,
  CreatePresetPayload,
} from "./types/task.filter.types";
import UserContext from "contexts/user-context/UserContext";

import { ReactComponent as RadioUnchecked } from "assets/icons/radio-unchecked.svg";
import { ReactComponent as RadioChecked } from "assets/icons/radio-checked.svg";
import { ReactComponent as ListFilter } from "assets/icons/filter-list.svg";

import CloseIcon from "components/molecules/close-icon/CloseIcon";
import Button from "components/atoms/Button";
import CheckboxIcon from "components/atoms/checkbox-icon/CheckboxIcon";

const Container = styled.div`
  width: 100%;
  max-width: 45rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.primary};

  @media ${device.iPad} {
    max-width: 65rem;
    bottom: 2rem;
    border-radius: 0.4rem;
  }

  animation: moveUp 0.3s ease-out;

  @keyframes moveUp {
    0% {
      bottom: -33rem;
    }
    100% {
      bottom: 0;

      @media ${device.iPad} {
        bottom: 2rem;
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;

  .close-icon {
    width: 2.5rem;
    height: 2.5rem;
    top: 1rem;
    right: 1rem;

    svg > path {
      fill: ${({ theme }) => theme.textColor1};
    }
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.textColor1};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .filter-icon {
    width: 2rem;
    height: 2rem;
    path {
      stroke: ${({ theme }) => theme.textColor1};
    }
  }
`;

const PanelsContainer = styled.div``;

const Panel = styled.div`
  border-radius: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  background-color: #d5dbe7;

  &:not(:nth-child(3)) {
    margin-bottom: 0.75rem;
  }
`;

const FiltersList = styled.ul``;

const FiltersListItem = styled.li<{ isMobile: boolean }>`
  @media screen and (min-height: 518px) {
    padding: 0.5rem 0;
  }

  .radio-icon {
    width: 2.7rem;
    height: 2.7rem;
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  }
`;

const InnerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

const FilterLabel = styled.span<{ isMobile: boolean }>`
  display: inline-block;
  user-select: none;
  font-family: GothamBold;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  @media screen and (min-height: 518px) {
    padding: 0.5rem 0;
  }
`;

const ButtonContainer = styled.div`
  width: 10rem;

  .apply-btn {
    height: 3.5rem;
    font-size: 1.4rem;

    @media screen and (min-height: 518px) {
      height: 4rem;
    }
  }
`;

type TasksFilterProps = {
  closeFilter: () => void;
};

type Params = {
  status: TaskStatuses;
  AssignTo?: "CURRENT_USER";
  user_created?: "CURRENT_USER";
  Deadline?: "lt_NOW";
};

const { USER, EXPIRED } = FilterTypeKeys;
const { AssignTo, user_created } = UserLabelKeys;
const { ASSIGN_TO, DEADLINE, STATUS, USER_CREATED } = PresetObjectKeys;

function TasksFilter({ closeFilter }: TasksFilterProps) {
  const [filters, setFilters] = useState<TaskFilter>(getInitialFilters());
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, tasksPresetId, handleCreatePreset, handleUpdatePreset } =
    useContext(UserContext);

  const searchParams_status = searchParams.get(STATUS);
  const searchParams_AssignTo = searchParams.get(ASSIGN_TO);
  const searchParams_user_created = searchParams.get(USER_CREATED);
  const searchParams_deadline = searchParams.get(DEADLINE);

  const {
    i18n: { language },
  } = useTranslation();
  const ref = useRef(null);
  const userFilters = getUserFilters(language);
  const statusFilters = getStatusFilters(language);
  const expiredFilter = getExpiredLabel(language);
  const { title, btn } = getTranslations(language);

  const setFiltersInSearchParams = () => {
    const { user, expired } = filters;
    let params: Params = { status: filters.status };

    if (user.includes(AssignTo)) {
      params = { ...params, AssignTo: "CURRENT_USER" };
    }

    if (user.includes(user_created)) {
      params = { ...params, user_created: "CURRENT_USER" };
    }

    if (expired) {
      params = { ...params, Deadline: "lt_NOW" };
    }

    setSearchParams(params);
  };

  const createTasksFiltersPreset = () => {
    if (!user?.id) return;

    const payload: Omit<CreatePresetPayload, "token" | "user"> = {
      bookmark: "Preset-1",
      collection: "Tasks",
      filter: {
        _and: getFiltersArray(user.id, filters),
      },
    };

    handleCreatePreset(payload);
  };

  const updateTasksFiltersPreset = () => {
    if (!user?.id) return;

    handleUpdatePreset({
      presetId: tasksPresetId as number,
      collection: "Tasks",
      icon: "smartphone",
      filter: {
        _and: getFiltersArray(user.id, filters),
      },
    });
  };

  // --------------- On click handlers ---------------

  const handleClickOutside = () => {
    closeFilter();
  };

  useClickOutside(ref, handleClickOutside);

  const onItemClick = (filterType: keyof TaskFilter, filter: FilterLabels) => () => {
    let updatedValue: UserLabelKeys | UserLabelKeys[] | TaskStatuses | boolean = filter;

    switch (filterType) {
      case USER:
        const userFilter = filter as UserLabelKeys;

        if (filters.user.includes(userFilter)) {
          updatedValue = filters.user.filter((item) => item !== filter);
        } else {
          updatedValue = [...filters.user, userFilter];
        }
        break;

      case EXPIRED:
        updatedValue = filters[filterType] ? false : true;
        break;
    }

    setFilters((prev) => ({ ...prev, [filterType]: updatedValue }));
  };

  const onApplyBtnClick = () => {
    setFiltersInSearchParams();
    closeFilter();

    if (tasksPresetId) {
      updateTasksFiltersPreset();
    } else {
      createTasksFiltersPreset();
    }
  };

  // --------------- Effect handler ---------------

  useEffect(() => {
    const status = searchParams_status as TaskStatuses;
    let user: UserLabelKeys[] = [];
    let expired = !!searchParams_deadline;

    if (searchParams_AssignTo) {
      user = [AssignTo];
    }

    if (searchParams_user_created) {
      user = [...user, user_created];
    }

    const searchParams_filters: TaskFilter = { status, user, expired };

    setFilters(searchParams_filters);
  }, [
    searchParams_status,
    searchParams_AssignTo,
    searchParams_user_created,
    searchParams_deadline,
  ]);

  return (
    <Container ref={ref}>
      <Header>
        <Title>
          <ListFilter className='filter-icon' />
          <span>{title}</span>
        </Title>
        <CloseIcon onClick={closeFilter} customClass='close-icon' />
      </Header>
      <PanelsContainer>
        {/* STATUS FILTERS */}
        <Panel>
          <FiltersList>
            {statusFilters.map((filter) => (
              <FiltersListItem key={filter.id} isMobile={isMobile}>
                <InnerWrapper onClick={onItemClick(STATUS, filter.id)}>
                  {filters[STATUS] === filter.id ? (
                    <RadioChecked className='radio-icon' />
                  ) : (
                    <RadioUnchecked className='radio-icon' />
                  )}
                  <FilterLabel isMobile={isMobile}>{filter.label}</FilterLabel>
                </InnerWrapper>
              </FiltersListItem>
            ))}
          </FiltersList>
        </Panel>

        {/* USER FILTERS */}
        <Panel>
          <FiltersList>
            {userFilters.map((filter) => (
              <FiltersListItem key={filter.id} isMobile={isMobile}>
                <InnerWrapper onClick={onItemClick(USER, filter.id)}>
                  <CheckboxIcon checked={filters[USER].includes(filter.id)} dark thin />
                  <FilterLabel isMobile={isMobile}>{filter.label}</FilterLabel>
                </InnerWrapper>
              </FiltersListItem>
            ))}
          </FiltersList>
        </Panel>

        {/* EXPIRED FILTER */}
        <Panel>
          <FiltersList>
            {expiredFilter.map((filter) => (
              <FiltersListItem key={filter.id} isMobile={isMobile}>
                <InnerWrapper onClick={onItemClick(EXPIRED, filter.id)}>
                  <CheckboxIcon checked={filters[EXPIRED]} dark thin />
                  <FilterLabel isMobile={isMobile}>{filter.label}</FilterLabel>
                </InnerWrapper>
              </FiltersListItem>
            ))}
          </FiltersList>
        </Panel>
      </PanelsContainer>
      <Actions>
        <ButtonContainer>
          <Button
            label={btn.apply}
            question
            customClass='apply-btn'
            onClick={onApplyBtnClick}
          />
        </ButtonContainer>
      </Actions>
    </Container>
  );
}

export default TasksFilter;
