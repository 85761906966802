import styled from "styled-components/macro";
import { FilesGallery } from "types/types";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PhotosNumber = styled.span`
  opacity: 0.8;

  &::before {
    content: "(";
  }
  &::after {
    content: ")";
  }
`;

const Preview = styled.div`
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
`;

function PhotoPreview({ FilesGallery }: { FilesGallery: FilesGallery }) {
  return !!FilesGallery && FilesGallery.length ? (
    <Container>
      <Preview>
        <Image src={FilesGallery[0].src} alt='' />
      </Preview>
      <PhotosNumber>{FilesGallery.length}</PhotosNumber>
    </Container>
  ) : null;
}

export default PhotoPreview;
