import { CountryCodes } from "types/types";
import { TaskStatuses } from "types/tasks.types";
import { FilterLabels, UserLabelKeys } from "../types/task.filter.types";

const { AssignTo, user_created, expired } = UserLabelKeys;
const { ARCHIVED, DONE, IN_PROGRESS, TO_DO } = TaskStatuses;

export const getLabelTranslation = (language: string, labelKey: FilterLabels) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    [AssignTo]: "Assigned to me",
    [user_created]: "Created by me",
    [ARCHIVED]: "Archived",
    [DONE]: "Done",
    [IN_PROGRESS]: "In progress",
    [TO_DO]: "To do",
    [expired]: "Expired",
  };

  if (isPolish) {
    labels = {
      [AssignTo]: "Przypisane do mnie",
      [user_created]: "Utworzone przeze mnie",
      [ARCHIVED]: "Zarchiwizowane",
      [DONE]: "Zrobione",
      [IN_PROGRESS]: "W trakcie",
      [TO_DO]: "Do zrobienia",
      [expired]: "Przeterminowane",
    };
  }

  return labels[labelKey];
};

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let title = "Filters";
  let btn = {
    apply: "Apply",
  };

  if (isPolish) {
    title = "Filtry";
    btn = {
      apply: "Zastosuj",
    };
  }

  return { title, btn };
};
