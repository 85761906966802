import { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ActionStatus, Statuses, Stores } from "types/types";
import { getCatchErrorMessage } from "utils/utils";

const { SUCCESS, ERROR } = Statuses;

const DB = "SnapCheckDB";

const {
  SerialNumbers,
  PhotosQuestionnaire,
  PhotosAccident,
  PhotosTasks,
  AssessmentData,
} = Stores;

const stores = [
  { name: PhotosQuestionnaire, keyPath: "Question" },
  { name: PhotosAccident, keyPath: "Question" },
  { name: PhotosTasks, keyPath: "id" },
  { name: SerialNumbers, keyPath: "id" },
  { name: AssessmentData, keyPath: "assessmentId" },
];

const version = 9;

function useIndexedDB<T extends Partial<T>>(storeName: Stores) {
  const [allItems, setAllItems] = useState<T | [] | undefined>();
  const navigate = useNavigate();

  const navigateToErrorPage = useCallback(
    (message: string) => {
      navigate(`/indexed-db-error?message=${message}`, { replace: true });
    },
    [navigate],
  );

  // --------------- Init Data Base ---------------

  const initDB = useCallback((): Promise<ActionStatus> => {
    return new Promise((resolve) => {
      const openRequest = indexedDB.open(DB, version);

      openRequest.onupgradeneeded = () => {
        const db = openRequest.result;

        stores.forEach(({ name, keyPath }) => {
          if (!db.objectStoreNames.contains(name)) {
            db.createObjectStore(name, { keyPath });
          }
        });

        if (db.objectStoreNames.contains("device-uuid")) {
          db.deleteObjectStore("device-uuid");
        }
      };

      openRequest.onsuccess = () => {
        resolve({ status: SUCCESS });
      };

      openRequest.onerror = (err) => {
        const message = "[initDB] Nie można nawiązać połączenia z bazą";
        navigateToErrorPage(message);
      };
    });
  }, [navigateToErrorPage]);

  // --------------- Get all items from DB ---------------

  const getAllItems = useCallback(
    (storeName: string) => {
      if (!storeName) return;

      return new Promise((resolve) => {
        const request = indexedDB.open(DB, version);

        request.onsuccess = () => {
          try {
            const db = request.result;
            const tx = db.transaction(storeName, "readonly");
            const store = tx.objectStore(storeName);
            const res = store.getAll();

            res.onsuccess = () => {
              resolve(res.result);
              db.close();
            };

            res.onerror = () => {
              const message = "[getAllItems] Nie udało się pobrać elementów z bazy";
              navigateToErrorPage(message);
              db.close();
            };
          } catch (err) {
            const message = `[getAllItems: try/catch] ${getCatchErrorMessage(err)}`;
            navigateToErrorPage(message);
          }
        };
      });
    },
    [navigateToErrorPage],
  );

  // --------------- Get single item from DB ---------------

  const getItem = (keyPath: string) => {
    return new Promise((resolve) => {
      const request = indexedDB.open(DB, version);

      request.onsuccess = () => {
        try {
          const db = request.result;
          const tx = db.transaction(storeName, "readonly");
          const store = tx.objectStore(storeName);
          const res = store.get(keyPath);

          res.onsuccess = () => {
            resolve(res.result);
            db.close();
          };

          res.onerror = () => {
            const message = "[addItem] Nie udało się pobrać elementu z bazy";
            navigateToErrorPage(message);
            db.close();
          };
        } catch (err) {
          const message = `[getItem: try/catch] ${getCatchErrorMessage(err)}`;
          navigateToErrorPage(message);
        }
      };
    });
  };

  // --------------- Add item ---------------

  const addItem = <T>(data: T): Promise<T | string | null> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB, version);

      request.onsuccess = () => {
        try {
          const db = request.result;
          const tx = db.transaction(storeName, "readwrite");
          const store = tx.objectStore(storeName);
          const req = store.add({ ...data, created: new Date() });

          req.onsuccess = () => {
            resolve(data);
            db.close();
          };

          req.onerror = (e) => {
            const message = `[addItem] Nie udało się dodać elementu do bazy.\n\n ${req.error}`;
            // navigateToErrorPage(message);
            db.close();
          };
        } catch (err) {
          const message = `[addItem: try/catch] ${getCatchErrorMessage(err)}`;
          navigateToErrorPage(message);
        }
      };

      request.onerror = () => {
        const message = `[addItem] Nie można nawiązać połączenia z bazą\n\n ${request.error}`;
        navigateToErrorPage(message);
      };
    });
  };

  // --------------- Update data ---------------

  const updateItem = <T>(data: T): Promise<T | string | null> => {
    return new Promise((resolve) => {
      const request = indexedDB.open(DB, version);

      request.onsuccess = () => {
        const db = request.result;
        const tx = db.transaction(storeName, "readwrite");
        const store = tx.objectStore(storeName);
        const req = store.put({ ...data, created: new Date() });

        req.onsuccess = () => {
          resolve(data);
          db.close();
        };

        req.onerror = () => {
          resolve("Nie udało się zaktualizować elementu w bazie");
        };
      };

      request.onerror = () => {
        const error = request.error?.message;
        if (error) {
          resolve(error);
        } else {
          resolve("Nie można nawiązać połączenia z bazą");
        }
      };
    });
  };

  // --------------- Delete item ---------------

  const deleteItem = (id: string): Promise<ActionStatus> => {
    return new Promise((resolve) => {
      const request = indexedDB.open(DB, version);

      request.onsuccess = () => {
        const db = request.result;
        const tx = db.transaction(storeName, "readwrite");
        const store = tx.objectStore(storeName);
        const res = store.delete(id);

        res.onsuccess = async () => {
          const allItems = (await getAllItems(storeName)) as T | [];

          setAllItems(allItems);
          resolve({ status: SUCCESS });
          db.close();
        };

        res.onerror = () => {
          resolve({ status: ERROR });
          db.close();
        };
      };
    });
  };

  // --------------- Delete all ---------------

  const deleteAll = (): Promise<ActionStatus> => {
    return new Promise((resolve) => {
      const request = indexedDB.open(DB, version);

      request.onsuccess = () => {
        const db = request.result;
        const tx = db.transaction(storeName, "readwrite");
        const store = tx.objectStore(storeName);
        const res = store.clear();

        res.onsuccess = async () => {
          const allItems = (await getAllItems(storeName)) as T | [];

          setAllItems(allItems);
          resolve({ status: SUCCESS });
          db.close();
        };

        res.onerror = () => {
          resolve({ status: ERROR });
          db.close();
        };
      };
    });
  };

  // --------------- Handle item in DB ---------------

  const handleItemInDB = async (id: string) => {
    const item = await getItem(id);

    if (!item) {
      addItem({ id });
    }
  };

  // --------------- Handle init DB ---------------

  const handleInitDB = useCallback(async () => {
    const { status }: ActionStatus = await initDB();

    if (status === SUCCESS) {
      const allItems = (await getAllItems(storeName)) as T | [];
      setAllItems(allItems);
    }
  }, [storeName, initDB, getAllItems]);

  // --------------- Effect handlers ---------------

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if ("indexedDB" in window) {
        handleInitDB();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [handleInitDB]);

  return {
    allItems,
    getAllItems,
    addItem,
    getItem,
    updateItem,
    deleteItem,
    deleteAll,
    handleItemInDB,
    handleInitDB,
    // error,
  };
}

export default useIndexedDB;
