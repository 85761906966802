import { useContext } from "react";
import styled, { css } from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { QuestionnaireObject } from "types/types";
import { device, isMobile } from "utils/utils";
import useQuestionnaire from "hooks/useQuestionnaire";
import useRequiredQuestions from "hooks/useRequiredQuestions";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import Button from "components/atoms/Button";
import EntityInfo from "components/molecules/EntityInfo";

import { ContentContainer } from "styles/generalStyles";
import QuestionsList from "./components/questions-list/QuestionsList";

const Container = styled.div<{ isMobile: boolean }>`
  position: relative;
  overflow: hidden;
  ${({ theme }) => theme.fillUpRemainingSpace}

  @media ${device.iPad} {
    margin: 2rem 0;
    border-radius: 0.4rem;
    padding: 2rem 3rem;
  }

  .close-icon {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.primary};

    position: absolute;
    top: 1rem;
    right: -0.4rem;

    @media ${device.iPad} {
      right: 0.5rem;
    }

    ${({ isMobile }) =>
      !isMobile &&
      css`
        cursor: pointer;
      `}
  }

  animation: showContainer 0.25s ease-out;

  @keyframes showContainer {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  margin-bottom: 0.6rem;
  padding-top: 0.7rem;
  padding-right: 2.2rem;
  padding-bottom: 0.7rem;
  border-top: 1px solid ${({ theme }) => theme.primary_600};
`;

const Title = styled.div<{ isMobile: boolean }>`
  max-height: 6.1rem;
  font-family: GothamBold;
  font-size: 1.8rem;
  ${({ theme }) => theme.ellipsisMultiline}

  ${({ isMobile }) =>
    isMobile &&
    css`
      -webkit-line-clamp: 3;
    `}
`;

const Subtitle = styled.div`
  margin-top: 0.6rem;
  font-size: 1.6rem;
  ${({ theme }) => theme.ellipsisMultiline}
`;

const CounterContainer = styled.div<{ allAnswered: boolean }>`
  padding-left: 0.2rem;
  margin: 0.5rem 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ allAnswered }) =>
    allAnswered &&
    css`
      align-items: center;
    `}
`;

const Counter = styled.div`
  font-family: "GothamBold";
  font-size: 1.8rem;

  display: flex;
  align-items: center;

  @media screen and (min-height: 616px) {
    font-size: 2rem;
  }

  .label {
    font-size: 1.6rem;
    align-self: flex-end;
    font-family: "GothamBook";
    padding-bottom: 0.1rem;
  }
`;

const ButtonContainer = styled.div`
  width: 12rem;

  .send-btn {
    height: 4rem;
    font-size: 1.4rem;
    ${({ theme }) => theme.cardShadow};
  }
`;

function TableOfContents() {
  const {
    state: { answers, selectedQuestionnaire },
  } = useContext(QuestionnairesContext);
  const {
    allRequiredQuestionsAnswered,
    answersWithMissingPhotosNumber,
    answersWithMissingDescriptionNumber,
  } = useRequiredQuestions(answers, selectedQuestionnaire as QuestionnaireObject);
  const navigate = useNavigate();
  const { t } = useTranslation(["table-of-contents", "summary"]);
  const { numberOfQuestions } = useQuestionnaire();

  const numberOfAnswers = answers.length;
  const allAnswered =
    allRequiredQuestionsAnswered &&
    !answersWithMissingPhotosNumber &&
    !answersWithMissingDescriptionNumber;

  const title = selectedQuestionnaire?.QuestionnaireTitle;
  const subTitle = selectedQuestionnaire?.QuestionnaireSubtitle;

  const onQuestionClick = (index: number) => () => {
    const questionNumber = index + 1;
    const questionnaireId = selectedQuestionnaire?.id;
    const path = `/questionnaire/${questionnaireId}/${questionNumber}`;

    navigate(path);
  };

  const onSendButtonClick = () => {
    navigate(`/questionnaire/summary/${selectedQuestionnaire?.id}`);
  };

  const onCloseIconClick = () => {
    const path = localStorage.getItem("tableOfContentsReturnPath") ?? "";
    navigate(path, { replace: true });
    localStorage.removeItem("tableOfContentsReturnPath");
  };

  return (
    <ContentContainer background_100>
      <Container isMobile={isMobile}>
        <CloseIcon className='close-icon' onClick={onCloseIconClick} />

        <EntityInfo marginTop />

        <Header>
          <Title isMobile={isMobile}>{title}</Title>
          {!!subTitle && <Subtitle>{subTitle}</Subtitle>}
        </Header>

        <CounterContainer allAnswered={allAnswered}>
          <Counter>
            <span className='label'>{t("answers")}</span>
            <span>
              &nbsp;{numberOfAnswers}
              &#x2f;{numberOfQuestions}
            </span>
          </Counter>

          {allAnswered && (
            <ButtonContainer>
              <Button
                label={t("summary:button.send")}
                customClass='send-btn'
                onClick={onSendButtonClick}
                noShaddow
              />
            </ButtonContainer>
          )}
        </CounterContainer>

        <QuestionsList
          selectedQuestionnaire={selectedQuestionnaire}
          onQuestionClick={onQuestionClick}
          answers={answers}
        />
      </Container>
    </ContentContainer>
  );
}

export default TableOfContents;
