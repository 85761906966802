import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { isIOS } from "utils/utils";
import { getPhotoCount } from "utils/photos.utils";
import useAccidentForm from "hooks/useAccidentForm";
import { Answers, FormTypes, QUESTION_TYPE_IDS, QuestionObject } from "types/types";

import { MainField } from "../questionnaire/styles/questionnaireStyles";
import { ContentContainer } from "styles/generalStyles";
import {
  Content,
  Wrapper,
  WarningBar,
  CloseIconContainer,
} from "./styles/accidenFormStyles";
import useTimeout from "hooks/useTimeout";
import useActionText from "hooks/useActionText";
import useActionPhoto from "hooks/useActionPhoto";
import useContentOverflow from "hooks/useContentOverflow";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";

import NoTypeWarning from "pages/questionnaire/components/NoTypeWarning";
import QuestionActions from "components/molecules/question-actions/QuestionActions";
import WebcamCapture from "components/molecules/webcam-capture/WebcamCapture";
import PhotosInAnswer from "pages/questionnaire/components/PhotosInAnswer";
import ReactionModal from "components/molecules/reaction-modal/ReactionModal";
import NavHeader from "./components/NavHeader";
import QuestionTitle from "./components/QuestionTitle";
import CloseIcon from "components/molecules/close-icon/CloseIcon";
import ReactionInAnswer from "pages/questionnaire/components/ReactionInAnswer";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import ScrollButton from "pages/questionnaire/components/ScrollButton";
import PhotoPrompt from "components/molecules/action-prompts/PhotoPrompt";
import TextPrompt from "components/molecules/action-prompts/TextPrompt";

const { NUMERIC, TEXT, YES_NO, FILE_PHOTO, SELECT } = QUESTION_TYPE_IDS;
const { YES, NO, NA } = Answers;
const { ACCIDENT } = FormTypes;

function AccidentForm() {
  const {
    accidentAnswers,
    createAnswer,
    removeAnswer,
    currentQuestion,
    currentQuestionIndex,
    questionType,
    answerInputValue,
    setAnswerInputValue,
    answerTextAreaValue,
    setAnswerTextAreaValue,
    answerSelectValue,
    setAnswerSelectValue,
    reactionTextAreaValue,
    setReactionTextAreaValue,
    goToNextQuestion,
    goToPreviousQuestion,
    cameraVisible,
    setCameraVisible,
    clearInputFields,
    handleCloseForm,
    answerToCurrentQuestion,
    removePhoto,
    removeReactionComment,
    isTyping,
    saved,
  } = useAccidentForm();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [reactionModalOpen, setReactionModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [minPhotosNotReached, setMinPhotosNotReached] = useState(false);
  const [descriptionMissing, setDescriptionMissing] = useState(false);
  const [selectEmpty, setSelectEmpty] = useState(false);
  const [numberInputEmpty, setNumberInputEmpty] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation(["questionnaires", "side-navbar"], {
    useSuspense: true,
  });

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const { isContentOverlow, isBottom, onScrollButtonClick, onScroll } =
    useContentOverflow({
      contentContainerRef,
      currentQuestionIndex,
    });

  const { setUpPhotosObjectStore, getNumberOfPhotos } = usePhotosObjectStore(ACCIDENT);

  const { isPhotoPromptVisible, minPhotos, maxPhotos } = useActionPhoto(
    currentQuestion,
    answerToCurrentQuestion,
  );

  const { isTextPromptVisible } = useActionText(
    currentQuestion,
    answerToCurrentQuestion,
    answerTextAreaValue,
    reactionTextAreaValue,
    language,
  );

  const setTimeOut = useTimeout();

  const animate = !accidentAnswers.length;
  const isRequired = !!currentQuestion?.Required;

  const clearState = () => {
    if (cameraVisible) {
      hideCamera();
    }

    if (currentQuestionIndex > 0) {
      clearInputFields();
    }
  };

  // --------------- Modal handlers ---------------

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const openReactionModal = () => {
    setReactionModalOpen(true);
  };

  const closeReactionModal = () => {
    setReactionModalOpen(false);
  };

  // --------------- Textarea and camera handlers ---------------

  const displayCamera = useCallback(() => {
    setCameraVisible(true);
  }, [setCameraVisible]);

  const hideCamera = useCallback(() => {
    setCameraVisible(false);
  }, [setCameraVisible]);

  const handleCloseCameraView = () => {
    setSearchParams({});
  };

  // --------------- Navigate handlers ---------------

  const handleMoveForward = () => {
    clearInputFields();

    if (cameraVisible) {
      hideCamera();
    }

    goToNextQuestion();
  };

  // --------------- On click handlers ---------------

  const onCloseIconClick = () => {
    if (accidentAnswers.length) {
      openConfirmationModal();
    } else {
      handleCloseForm();
    }
  };

  // << BACK
  const onBackClick = () => {
    clearState();
    goToPreviousQuestion();
  };

  // FORWARD >>
  const onForwardClick = () => {
    handleMoveForward();
  };

  // BUTTON_YES
  const onYesClick = () => {
    if (!currentQuestion) return;

    const { OnYesActionText, OnYesActionPhoto, OnYesRequiredMinText } = currentQuestion;

    if (!answerToCurrentQuestion) {
      createAnswer({ yesNo: YES });
    }

    if (!!answerToCurrentQuestion) {
      const { ReactionValue, Question } = answerToCurrentQuestion;
      const hasMinPhotosTaken = getPhotoCount(Question) >= minPhotos;
      const hasDescription = !!ReactionValue;

      if (isRequired) {
        if (OnYesActionText && OnYesActionPhoto) {
          if (
            (hasMinPhotosTaken && !OnYesRequiredMinText) ||
            (hasMinPhotosTaken && hasDescription)
          ) {
            goToNextQuestion();
          } else {
            if (!hasMinPhotosTaken) setMinPhotosNotReached(true);
            if (!hasDescription) setDescriptionMissing(true);
          }
        } else if (OnYesActionText) {
          if (!OnYesRequiredMinText || hasDescription) {
            goToNextQuestion();
          } else {
            setDescriptionMissing(true);
          }
        } else if (OnYesActionPhoto) {
          if (hasMinPhotosTaken) {
            goToNextQuestion();
          } else {
            setMinPhotosNotReached(true);
          }
        } else {
          goToNextQuestion();
        }
      } else {
        goToNextQuestion();
      }
    }
  };

  // BUTTON_NO
  const onNoClick = () => {
    if (!currentQuestion) return;

    const { OnNoActionText, OnNoActionPhoto, OnNoRequiredMinText } = currentQuestion;

    if (!answerToCurrentQuestion) {
      createAnswer({ yesNo: NO });
    }

    if (!!answerToCurrentQuestion) {
      const { ReactionValue, Question } = answerToCurrentQuestion;
      const hasMinPhotosTaken = getPhotoCount(Question) >= minPhotos;
      const hasDescription = !!ReactionValue;

      if (isRequired) {
        if (OnNoActionText && OnNoActionPhoto) {
          if (
            (hasMinPhotosTaken && !OnNoRequiredMinText) ||
            (hasMinPhotosTaken && hasDescription)
          ) {
            goToNextQuestion();
          } else {
            if (!hasMinPhotosTaken) setMinPhotosNotReached(true);
            if (OnNoRequiredMinText && !hasDescription) setDescriptionMissing(true);
          }
        } else if (OnNoActionText) {
          if (!OnNoRequiredMinText || hasDescription) {
            goToNextQuestion();
          } else {
            setDescriptionMissing(true);
          }
        } else if (OnNoActionPhoto) {
          if (hasMinPhotosTaken) {
            goToNextQuestion();
          } else {
            setMinPhotosNotReached(true);
          }
        } else {
          goToNextQuestion();
        }
      } else {
        goToNextQuestion();
      }
    }
  };

  // BUTTON_NA
  const onNaClick = () => {
    createAnswer({ yesNo: NA });

    if (answerToCurrentQuestion) {
      goToNextQuestion();
    }
  };

  const onNextClick = async () => {
    hideCamera();

    switch (questionType) {
      case FILE_PHOTO:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (!!answerToCurrentQuestion) {
            const { Question: id, ReactionValue } = answerToCurrentQuestion;
            const hasPhotos = !!((await getNumberOfPhotos(id)) as number);

            if (!hasPhotos && !ReactionValue) {
              removeAnswer(id);
            }
          }

          goToNextQuestion();

          return;
        }

        // --- REQUIRED ---
        const questionId = answerToCurrentQuestion?.Question
          ? answerToCurrentQuestion?.Question
          : "";
        const photosNumber = (await getNumberOfPhotos(questionId)) as number;

        if (minPhotosNotReached) return;

        if (photosNumber < minPhotos) {
          setMinPhotosNotReached(true);
        } else {
          if (!!answerToCurrentQuestion) {
            goToNextQuestion();
          } else {
            createAnswer({ photoSrc: "" });
            goToNextQuestion();
          }
        }
        break;

      case SELECT:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (answerToCurrentQuestion) {
            if (!answerSelectValue.value) {
              const { Question: id } = answerToCurrentQuestion;
              removeAnswer(id);
            }
          }
          goToNextQuestion();
          return;
        }

        // --- REQUIRED ---
        if (!answerToCurrentQuestion?.Value) {
          setSelectEmpty(true);
        } else {
          createAnswer({ selectValue: answerSelectValue.value });
          goToNextQuestion();
        }
        break;

      case NUMERIC:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (!!answerInputValue) {
            createAnswer({ numericValue: answerInputValue });
          }

          if (answerToCurrentQuestion) {
            if (!answerInputValue) {
              const { Question: id } = answerToCurrentQuestion;
              removeAnswer(id);
            }
          }

          goToNextQuestion();
          return;
        }

        // --- REQUIRED ---
        if (!!answerInputValue) {
          createAnswer({ numericValue: answerInputValue });
          goToNextQuestion();
        } else {
          setNumberInputEmpty(true);
        }

        break;

      case TEXT:
        if (!!answerToCurrentQuestion) {
          if (!answerTextAreaValue) {
            const { Question: id } = answerToCurrentQuestion;
            removeAnswer(id);
          }
        }

        goToNextQuestion();
    }
  };

  const onEditIconClick = () => {
    const value = answerToCurrentQuestion?.ReactionValue ?? "";
    setReactionTextAreaValue(value);

    openReactionModal();
  };

  const onAddTextReactionBtnClick = () => {
    openReactionModal();
  };

  const onSaveReactionBtnClick = () => {
    createAnswer({ yesNo: answerToCurrentQuestion?.Value as Answers });
    setReactionTextAreaValue("");
    closeReactionModal();
  };

  const onTakePhotoBtnClick = () => {
    if (isIOS) {
      sessionStorage.setItem("returnPath", pathname);
      sessionStorage.setItem("goToPath", `${pathname}?cameraView=true`);
      navigate("/camera-detection");
    } else {
      setSearchParams({ cameraView: "true" });
    }
  };

  // --------------- Event handlers ---------------

  const onAnswerInputChange = (value: string) => {
    setAnswerInputValue(value);
  };

  const onAnswerTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    if (typeof e === "string") {
      setAnswerTextAreaValue(e);
    } else {
      setAnswerTextAreaValue(e.target.value);
    }
  };

  const onReactionTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    if (typeof e === "string") {
      setReactionTextAreaValue(e);
    } else {
      setReactionTextAreaValue(e.target.value);
    }
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    if (questionType.includes(YES_NO) || questionType === FILE_PHOTO) {
      setReactionTextAreaValue(textTranscript);
    }

    if (questionType === TEXT) {
      setAnswerTextAreaValue(textTranscript);
    }
  };

  const onAnswerSelectChange = (value: string) => {
    createAnswer({ selectValue: value });
    setAnswerSelectValue({ label: value, value });
  };

  // --------------- Setting up photos object store ---------------

  useEffect(() => {
    setUpPhotosObjectStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------- Reaction to flipping through questions ---------------

  useEffect(() => {
    if (!currentQuestion) return;

    const answerValue = answerToCurrentQuestion?.Value as string;

    switch (questionType) {
      case TEXT:
        setAnswerTextAreaValue(answerValue);
        break;
      case NUMERIC:
        setAnswerInputValue(answerValue);
        break;
      case SELECT:
        setAnswerSelectValue({ label: answerValue, value: answerValue });
    }
  }, [
    currentQuestion,
    questionType,
    setAnswerInputValue,
    setAnswerTextAreaValue,
    answerToCurrentQuestion,
    cameraVisible,
    setAnswerSelectValue,
  ]);

  // --------------- Camera view handler ---------------

  useEffect(() => {
    const isCameraView = Boolean(searchParams.get("cameraView"));

    if (isCameraView) {
      displayCamera();
    } else {
      hideCamera();
    }
  }, [searchParams, displayCamera, hideCamera]);

  // --------------- Min photos number not reached handler ---------------

  useEffect(() => {
    if (minPhotosNotReached) {
      setTimeOut(() => {
        setMinPhotosNotReached(false);
      }, 2000);
    }
  }, [minPhotosNotReached, setTimeOut]);

  // --------------- Description missing reached handler ---------------

  useEffect(() => {
    if (descriptionMissing) {
      setTimeOut(() => {
        setDescriptionMissing(false);
      }, 2000);
    }
  }, [descriptionMissing, setTimeOut]);

  // --------------- Empty select handler ---------------

  useEffect(() => {
    if (selectEmpty) {
      setTimeOut(() => {
        setSelectEmpty(false);
      }, 2000);
    }
  }, [selectEmpty, setTimeOut]);

  // --------------- Empty number input handler ---------------

  useEffect(() => {
    if (numberInputEmpty) {
      setTimeOut(() => {
        setNumberInputEmpty(false);
      }, 2000);
    }
  }, [numberInputEmpty, setTimeOut]);

  return (
    <ContentContainer alarm ref={contentContainerRef} onScroll={onScroll}>
      <Content animate={animate} isContentOverlow={isContentOverlow}>
        <WarningBar animate={animate} />
        <Wrapper isContentOverlow={isContentOverlow}>
          <CloseIconContainer>
            <CloseIcon onClick={onCloseIconClick} />
          </CloseIconContainer>

          <NavHeader onBackClick={onBackClick} onForwardClick={onForwardClick} />
          <QuestionTitle required={isRequired} />

          {!!questionType && (
            <MainField
              hidden={
                questionType === TEXT ||
                questionType === NUMERIC ||
                questionType === SELECT
              }
            >
              <PhotosInAnswer
                formType={ACCIDENT}
                minPhotos={minPhotos}
                maxPhotos={maxPhotos}
                removePhoto={removePhoto}
                questionType={questionType}
                onTakePhotoBtnClick={onTakePhotoBtnClick}
                currentQuestionId={currentQuestion?.id as string}
                answerToCurrentQuestion={answerToCurrentQuestion}
                minPhotosNotReached={minPhotosNotReached}
                isRequired={isRequired}
              />

              <div>
                <PhotoPrompt
                  onClick={onTakePhotoBtnClick}
                  minPhotos={minPhotos}
                  maxPhotos={maxPhotos}
                  minPhotosNotReached={minPhotosNotReached}
                  isRequired={isRequired}
                  isVisible={!!isPhotoPromptVisible}
                />
                <TextPrompt
                  onClick={onAddTextReactionBtnClick}
                  descriptionMissing={descriptionMissing}
                  isVisible={!!isTextPromptVisible}
                />
              </div>

              <ReactionInAnswer
                value={answerToCurrentQuestion?.ReactionValue}
                onEditIconClick={onEditIconClick}
                removeReactionComment={removeReactionComment}
              />

              {cameraVisible && (
                <WebcamCapture
                  createAnswer={createAnswer}
                  currentQuestionId={currentQuestion?.id}
                  maxPhotos={maxPhotos}
                  handleCloseCameraView={handleCloseCameraView}
                  formType={ACCIDENT}
                />
              )}
            </MainField>
          )}

          {currentQuestion && !questionType && <NoTypeWarning />}

          {!!questionType && (
            <QuestionActions
              currentQuestion={currentQuestion as QuestionObject}
              onYesClick={onYesClick}
              onNoClick={onNoClick}
              onNaClick={onNaClick}
              onNextClick={onNextClick}
              onTextTranscriptChange={onTextTranscriptChange}
              onAnswerInputChange={onAnswerInputChange}
              answerInputValue={answerInputValue}
              onAnswerTextAreaChange={onAnswerTextAreaChange}
              answerTextAreaValue={answerTextAreaValue}
              onAnswerSelectChange={onAnswerSelectChange}
              answerSelectValue={answerSelectValue}
              answerToCurrentQuestion={answerToCurrentQuestion}
              removeAnswer={removeAnswer}
              searchParams={searchParams}
              isTyping={isTyping}
              saved={saved}
            />
          )}

          {isContentOverlow && (
            <ScrollButton isBottom={isBottom} onClick={onScrollButtonClick} />
          )}
        </Wrapper>
      </Content>

      {/* --------------- Modals --------------- */}
      <ReactionModal
        open={reactionModalOpen}
        onClose={closeReactionModal}
        questionType={questionType}
        required={isRequired}
        reactionTextAreaValue={reactionTextAreaValue}
        onReactionTextAreaChange={onReactionTextAreaChange}
        onTextTranscriptChange={onTextTranscriptChange}
        onSaveReactionBtnClick={onSaveReactionBtnClick}
      />

      <ConfirmationModal
        message={t("modal.message", { ns: "side-navbar" })}
        onClick={handleCloseForm}
        onClose={closeConfirmationModal}
        open={confirmationModalOpen}
        buttonLabel={t("modal.button-label", { ns: "side-navbar" })}
      />
    </ContentContainer>
  );
}

export default AccidentForm;
