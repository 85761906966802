import {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  FormEvent,
  useCallback,
  useContext,
  KeyboardEvent,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { DetectedBarcode } from "barcode-detector";

import { checkIfUrl, containsSerial, getSerialNumberFromUrl } from "./utils/utils";
import { clearLocalStorage, isMobile } from "utils/utils";
import UserContext from "contexts/user-context/UserContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import useDevices from "hooks/useDevices";
import useNotification from "hooks/useNotification";
import useTimeout from "hooks/useTimeout";
import useQueryString from "hooks/useQueryString";
import useSerialNumberPath from "hooks/useSerialNumberPath";

import close from "assets/icons/close.svg";
import { ReactComponent as Keyboard } from "assets/icons/keypad-3.svg";
import { ReactComponent as QrCode } from "assets/icons/qr-code-2.svg";

import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import BackArrow from "components/atoms/BackArrow";
import QrScanner from "components/organisms/qr-scanner/QrScanner";

import { ContentContainer, Form, InputContainer } from "styles/generalStyles";

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const FormField = styled.div`
  padding-top: 1.2rem;
  margin-bottom: 1.2rem;
`;

const ClearInput = styled.div`
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: 0.5rem;
  top: 45%;
  transform: translateY(-45%);
`;

const ClearInputIcon = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Header = styled.div`
  margin-top: 1rem;
  padding-left: 0.2rem;
  font-family: GothamBold;
  text-align: center;
  position: relative;

  @media screen and (min-height: 616px) {
    margin-top: 2rem;
  }

  .label {
    display: inline-block;
    font-size: 1.8rem;
    max-width: 85%;

    @media screen and (min-width: 360px) {
      font-size: 2rem;
    }
  }

  .title {
    text-transform: lowercase;
  }

  .move-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ToggleButtonContainer = styled.div`
  padding-bottom: 0.9rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: absolute;
  bottom: -0.3rem;
  right: -1.9rem;

  transform: scale(0.8);
  z-index: ${({ theme }) => theme.level1};

  @media screen and (min-height: 616px) {
    padding-bottom: 2rem;
    bottom: 0;
    right: 0;
    transform: scale(1);
  }

  animation: fadeIn 0.15s ease forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .icon {
    width: 4rem;
    height: 4rem;
    filter: drop-shadow(0 3px 2px rgb(66, 68, 90, 0.5));

    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.primary_100};
  ${({ theme }) => theme.buttonShadow}
  transition: filter 0.2s ease-out;
  user-select: none;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  .top {
    font-size: 2.2rem;
    font-family: GothamBold;
    letter-spacing: 0.1rem;
    display: flex;
    justify-content: space-between;
  }

  .bottom {
    font-size: 2rem;
  }
`;

enum Methods {
  TYPE = "type",
  SCAN = "scan",
}

const { TYPE, SCAN } = Methods;

type Method = Methods.TYPE | Methods.SCAN;

function EnterSerialNumber() {
  const [serial, setSerial] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const query = useQueryString();
  const [method, setMethod] = useState<Method>(query.get("method") as Method);

  const { t } = useTranslation(["serial-number", "common"]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isVideoInput } = useDevices();
  const setTimeOut = useTimeout();
  const notify = useNotification();
  const handleSerialNumberPath = useSerialNumberPath();

  const {
    state: { selectedQuestionnaire },
    clearQuestionnaireStateAction,
  } = useContext(QuestionnairesContext);

  const {
    labels: { serialNumberTitle },
  } = useContext(UserContext);

  const clearInputVisible = method === TYPE && !!serial.length;

  const inputRef = useRef<HTMLInputElement>(null);
  const scannedDataRef = useRef(false);

  let headerLabel = "";

  if (scannedDataRef.current) {
    headerLabel = t("header-label.scanned");
  } else if (method === TYPE) {
    headerLabel = t("header-label.type");
  } else if (method === SCAN) {
    headerLabel = t("header-label.scan");
  }

  const onBackArrowClick = () => {
    navigate("/serial-number-method-selection", { replace: true });
  };

  const foncusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const clearInput = () => {
    scannedDataRef.current = false;

    setSerial("");
    setInputValue("");
    foncusInput();
  };

  const toggleScanner = () => {
    clearInput();
    scannedDataRef.current = false;

    if (method === TYPE) {
      navigate(pathname + "?method=scan");
      setMethod(SCAN);
    }

    if (method === SCAN) {
      navigate(pathname + "?method=type");
      setMethod(TYPE);
    }
  };

  const setTypeMethod = useCallback(() => {
    navigate(pathname + "?method=type");
    setMethod(TYPE);
  }, [navigate, pathname]);

  const displayAlert = useCallback(() => {
    notify(t("notify.number-not-found"), "warning");
  }, [notify, t]);

  const redirectToQuestionnaire = useCallback(
    (url: string) => {
      const path = url.split("#")[1];
      navigate(path, { replace: true });
    },
    [navigate],
  );

  const handleUrl = useCallback(
    (url: string) => {
      if (containsSerial(url)) {
        if (url.includes("QuestionnaireId=")) {
          redirectToQuestionnaire(url);
        } else {
          const serialNumber = getSerialNumberFromUrl(url);
          scannedDataRef.current = true;

          setInputValue(serialNumber);
          setTypeMethod();
        }
      } else {
        displayAlert();
      }
    },
    [displayAlert, redirectToQuestionnaire, setTypeMethod],
  );

  const handleInputValue = useCallback(
    (value: string) => () => {
      if (checkIfUrl(value)) {
        handleUrl(value);
      } else {
        setSerial(value);
      }
    },
    [handleUrl],
  );

  // *************** Event handlers ***************

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!!serial) {
      handleSerialNumberPath(serial.trim());
    } else {
      if (inputValue) {
        handleInputValue(inputValue);
      }
    }
  };

  const onScan = (result: DetectedBarcode[]) => {
    const { rawValue } = result[0];

    if (checkIfUrl(rawValue)) {
      handleUrl(rawValue);
    } else {
      setInputValue(rawValue);
      setTypeMethod();
    }
  };

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value);
  };

  const onFocus = () => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    if (isMobile) {
      setActionsVisible(false);
    }
  };

  const onBlur = () => {
    setTimeOut(() => {
      setActionsVisible(true);
    }, 100);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!isTyping) {
      if (e.key === "Enter") {
        e.preventDefault();
        const event = e as unknown as FormEvent<HTMLFormElement>;
        onSubmit(event);
      }
    }

    setIsTyping(true);
  };

  // *************** Effect handlers ***************

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isTyping) setIsTyping(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isTyping]);

  useEffect(() => {
    if (!inputValue) {
      setSerial("");
      return;
    }

    const timer = setTimeout(handleInputValue(inputValue), 100);

    return () => clearTimeout(timer);
  }, [inputValue, handleInputValue]);

  useEffect(() => {
    if (method === TYPE) {
      foncusInput();

      if (!isMobile) {
        setActionsVisible(true);
      }
    }

    if (method === SCAN) {
      setActionsVisible(true);
    }
  }, [method]);

  useEffect(() => {
    const clearState = () => {
      clearQuestionnaireStateAction();
      clearLocalStorage([
        "selectedQuestionnaire",
        "serialNumberPath",
        "machineProcess",
        "serialNumber",
        "serialNumberPath",
        "currentPath",
        "answers",
      ]);
    };

    if (selectedQuestionnaire || !!localStorage.getItem("serialNumber")) {
      clearState();
    }
  }, [selectedQuestionnaire, clearQuestionnaireStateAction]);

  return (
    <ContentContainer>
      <Header>
        <BackArrow onClick={onBackArrowClick} customClass='move-arrow' />
        <span className='label'>
          <span>{headerLabel}&nbsp;</span>
          <span className='title'>{serialNumberTitle}</span>
        </span>
      </Header>

      <ContentWrapper>
        {isVideoInput && method === SCAN && (
          <QrScanner
            onScan={onScan}
            isCameraReady={isCameraReady}
            setIsCameraReady={setIsCameraReady}
          />
        )}

        {method === TYPE && (
          <Form onSubmit={onSubmit}>
            <FormField>
              <InputContainer>
                <Input
                  large
                  noMargin
                  name='serialNumber'
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={onKeyDown}
                  paddingRight
                  value={inputValue}
                  ref={inputRef}
                  noLabel
                />

                {clearInputVisible && (
                  <ClearInput>
                    <ClearInputIcon src={close} alt='' onClick={clearInput} />
                  </ClearInput>
                )}
              </InputContainer>
            </FormField>

            <Button
              label={t("common:button.next")}
              large
              disabled={isTyping || !inputValue}
              loading={isTyping}
            />
          </Form>
        )}

        {isVideoInput && actionsVisible && (
          <ToggleButtonContainer>
            <ToggleButton onClick={toggleScanner}>
              <Label>
                <div className='top'>
                  <span>
                    {method === SCAN ? t("header-label.type") : t("header-label.scan")}
                  </span>
                  <span>&#x279E;</span>
                </div>
                <span className='bottom'>{serialNumberTitle}</span>
              </Label>

              {method === SCAN ? (
                <Keyboard className='icon' />
              ) : (
                <QrCode className='icon' />
              )}
            </ToggleButton>
          </ToggleButtonContainer>
        )}
      </ContentWrapper>
    </ContentContainer>
  );
}

export default EnterSerialNumber;
