import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getCookie } from "react-use-cookie";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { baseURL } from "axios-instance/axios-instance";
import { ACCESS_TOKEN, device } from "utils/utils";
import { getAnswersInProgress } from "api/questionnaires";
import { Answers, QUESTION_TYPE_IDS } from "types/types";
import { getCommonTranslations } from "translations/common.translations";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import ErrorAlert from "components/molecules/ErrorAlert";
import ThumbIcon from "components/molecules/ThumbIcon";
import PhotosPanel from "./components/PhotosPanel";
import ChevronToggle from "./components/ChevronToggle";

const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-basis: 95%;
`;

const TasksList = styled.ul`
  padding-bottom: 0.2rem;
`;

const TaskItem = styled.li`
  padding-top: 1rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  border-radius: 0.4rem;
  background-color: rgba(196, 205, 222, 0.5);

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .icon-yes {
    width: 100%;

    path {
      fill: ${({ theme }) => theme.ufoGreen};
    }
  }

  .icon-no {
    width: 100%;
    transform: translateY(-0.1rem);

    path {
      fill: ${({ theme }) => theme.errorColor};
    }
  }
`;

const TaskItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0;

  .icon-yes,
  .icon-no {
    width: 2rem;
    height: 2rem;

    @media ${device.iPad} {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

const TaskTitle = styled.p`
  font-family: GothamBold;
  flex-basis: 95%;
  font-size: 1.8rem;
`;

const TaskDetailsWrapper = styled.div<{ expanded: boolean }>`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.7s ease;

  ${({ expanded }) =>
    expanded &&
    css`
      grid-template-rows: 1fr;
    `}
`;

const TaskDetailsList = styled.ul`
  overflow: hidden;
`;

const TaskDetailsItem = styled.li`
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.6rem;
`;

const PhotoContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Photo = styled.img`
  width: 100%;
  border-radius: 0.4rem;
`;

const AnswerValue = styled.div<{
  alignRight: boolean;
  italic: boolean;
  smallFont: boolean;
}>`
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.4rem;
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 1.6rem;

  ${({ alignRight }) =>
    alignRight &&
    css`
      text-align: right;
      padding-right: 1rem;
    `}

  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
      opacity: 0.7;
    `}

    ${({ smallFont }) =>
    smallFont &&
    css`
      font-size: 1.4rem;
    `}

  @media ${device.iPad} {
    font-size: 1.8rem;
  }
`;

const Description = styled.div`
  font-style: italic;
  border-radius: 0.4rem;
  padding: 0.7rem;
  background-color: ${({ theme }) => theme.white};
`;

const NoAnswersInProgress = styled.div`
  margin-top: 4rem;
  padding-bottom: 1rem;
  font-style: italic;
  text-align: center;
  color: ${({ theme }) => theme.primary_600};
  font-size: 1.8rem;
`;

const DateCreated = styled.div`
  margin-top: 0.5rem;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.primary_600};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > span:first-child {
    &::after {
      content: ":";
    }
  }
`;

type AnswersInProgressProps = {
  setTasksLoading: Dispatch<SetStateAction<boolean>>;
  setTasksNumber: Dispatch<SetStateAction<number>>;
};

const { NA } = Answers;
const { YES_NO, TEXT, FILE_PHOTO, NUMERIC, SELECT } = QUESTION_TYPE_IDS;

function AnswersInProgress({ setTasksLoading, setTasksNumber }: AnswersInProgressProps) {
  const {
    state: {
      machineProcess: { Machine, Process },
    },
  } = useContext(QuestionnairesContext);
  const [expandedItem, setExpandedItem] = useState("");
  const token = getCookie(ACCESS_TOKEN);
  const {
    t,
    i18n: { language },
  } = useTranslation("machine-process-info");
  const {
    labels: { notApplicable, created },
  } = getCommonTranslations(language);

  const payload = {
    machineId: Machine?.id,
    processId: Process?.id,
  };

  const { data, isLoading, isError, refetch, isSuccess } = useQuery(
    "answersInProgress",
    getAnswersInProgress(token, payload),
  );

  useEffect(() => {
    setTasksLoading(isLoading);
  }, [isLoading, setTasksLoading]);

  useEffect(() => {
    if (!data) return;

    setTasksNumber(data.data.length);
  }, [data, setTasksNumber]);

  const toggleAccordion = (id: string) => () => {
    if (expandedItem === id) {
      setExpandedItem("");
    } else {
      setExpandedItem(id);
    }
  };

  return (
    <Container>
      {isSuccess && (
        <>
          {data && !!data.data.length ? (
            <TasksList>
              {data.data.map(
                ({
                  id: taskId,
                  OriginalText,
                  FilesGalleryOurs,
                  Value,
                  ReactionValue,
                  date_created,
                  QuestionType,
                }) => {
                  const containsPhotos = !!FilesGalleryOurs.length;
                  const expanded = expandedItem === taskId;
                  const photosNumber = FilesGalleryOurs.length;
                  let alignRight = QuestionType !== TEXT;
                  let italic = false;
                  let smallFont = false;
                  let answerValue = "";

                  if ([TEXT, NUMERIC, SELECT].some((type) => type === QuestionType)) {
                    answerValue = Value;
                  }

                  if (QuestionType.includes(YES_NO)) {
                    if (Value === NA) {
                      answerValue = notApplicable;
                      italic = true;
                      smallFont = true;
                    } else if (!!ReactionValue) {
                      answerValue = ReactionValue;
                      alignRight = false;
                    }
                  }

                  if (QuestionType === FILE_PHOTO) {
                    if (!!ReactionValue) {
                      answerValue = ReactionValue;
                      alignRight = false;
                    }
                  }

                  return (
                    <TaskItem key={taskId}>
                      <TaskItemHeader>
                        <TaskTitle>{OriginalText}</TaskTitle>
                        <ThumbIcon value={Value} flexBasis={5} />
                      </TaskItemHeader>

                      <DateCreated>
                        <span>{created}</span>
                        <span>{format(new Date(date_created), "dd-MM-yyyy HH:mm")}</span>
                      </DateCreated>

                      {!!answerValue && (
                        <AnswerValue
                          alignRight={alignRight}
                          italic={italic}
                          smallFont={smallFont}
                        >
                          {answerValue}
                        </AnswerValue>
                      )}

                      {containsPhotos && (
                        <>
                          <PhotosPanel
                            id={taskId}
                            expanded={expanded}
                            photosNumber={photosNumber}
                            toggleAccordion={toggleAccordion}
                          />

                          <TaskDetailsWrapper expanded={expanded}>
                            <TaskDetailsList>
                              {FilesGalleryOurs.map(({ id, directus_files_id }) => {
                                let photoId = "";
                                let description = "";

                                if (directus_files_id) {
                                  photoId = directus_files_id.id;
                                  description = directus_files_id.description;
                                }

                                const src = `${baseURL}assets/${photoId}?access_token=${token}`;

                                return (
                                  <TaskDetailsItem key={id}>
                                    <PhotoContainer>
                                      <Photo src={src} alt='' />
                                    </PhotoContainer>
                                    {!!description && (
                                      <Description>{description}</Description>
                                    )}
                                  </TaskDetailsItem>
                                );
                              })}

                              <ChevronToggle
                                id={taskId}
                                expanded={expanded}
                                toggleAccordion={toggleAccordion}
                                bottom
                              />
                            </TaskDetailsList>
                          </TaskDetailsWrapper>
                        </>
                      )}
                    </TaskItem>
                  );
                },
              )}
            </TasksList>
          ) : (
            <NoAnswersInProgress>{t("no-tasks-in-progress")}</NoAnswersInProgress>
          )}
        </>
      )}

      {isError && (
        <ErrorAlert onClick={refetch} message={t("error.unable-to-load-tasks")} />
      )}
    </Container>
  );
}

export default AnswersInProgress;
