import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { getCommonTranslations } from "translations/common.translations";

import { ReactComponent as ErrorIcon } from "assets/icons/error-2.svg";

import Button from "components/atoms/Button";

const Container = styled.div<{ smallMargin?: boolean }>`
  margin-top: ${({ smallMargin }) => (smallMargin ? "1rem" : "4rem")};
`;

const ErrorMessageContainer = styled.div<{ paddingBottom: boolean }>`
  color: ${({ theme }) => theme.textColor1};
  background-color: ${({ theme }) => theme.errorColor};
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;

  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 1rem;
    `}

  .error-icon {
    fill: ${({ theme }) => theme.textColor1};
    width: 3rem;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const BottomContainer = styled.div`
  font-family: monospace;
  font-size: 1.2rem;
  padding: 0.5rem;
  background-color: #7b0016;
  border-radius: 0.4rem;
  color: #e5e5e5;
`;

const ErrorStatus = styled.div`
  margin-bottom: 0.25rem;
`;

const ErrorMessage = styled.div``;

type ErrorAlertProps = {
  onClick?: () => void;
  message: string;
  smallMargin?: boolean;
  error?: AxiosError;
};

function ErrorAlert({ onClick, message, smallMargin, error }: ErrorAlertProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const { button } = getCommonTranslations(language);

  let errorStatus = "";
  let errorCode = "";
  let errorMessage = "";

  if (error) {
    errorStatus = error.response?.status as unknown as string;
    errorCode = (error.response?.data as { errors?: any }).errors[0].extensions.code;
    errorMessage = (error.response?.data as { errors?: any }).errors[0].message;
  }

  return (
    <Container smallMargin={smallMargin}>
      <ErrorMessageContainer paddingBottom={!!error}>
        <TopContainer>
          <ErrorIcon className='error-icon' />
          <span>{message}</span>
        </TopContainer>

        {!!error && (
          <BottomContainer>
            <ErrorStatus>
              &#x5b;{errorStatus}&#x5d;&nbsp;{errorCode}
            </ErrorStatus>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </BottomContainer>
        )}
      </ErrorMessageContainer>

      {onClick && <Button label={button.label.tryAgain} onClick={onClick} />}
    </Container>
  );
}

export default ErrorAlert;
