import { useEffect, useState } from "react";

const getLocalData = () => {
  const localData = localStorage.getItem("selectedAnswers");

  return !!localData ? JSON.parse(localData) : [];
};

function useSelectedAnswers() {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(getLocalData());

  useEffect(() => {
    localStorage.setItem("selectedAnswers", JSON.stringify(selectedAnswers));
  }, [selectedAnswers]);

  return { selectedAnswers, setSelectedAnswers };
}

export default useSelectedAnswers;
