export const basePath = `/items/Tasks?limit=1000
&fields[]=id
&fields[]=AssignTo
&fields[]=Company
&fields[]=Deadline
&fields[]=Description
&fields[]=Files.id
&fields[]=Files.directus_files_id.id
&fields[]=Files.directus_files_id.type
&fields[]=Files.directus_files_id.title
&fields[]=Files.directus_files_id.filename_download
&fields[]=Title
&fields[]=date_created
&fields[]=date_updated
&fields[]=sort
&fields[]=status
&fields[]=user_created
&fields[]=user_updated
&fields[]=Related.id
&fields[]=Related.collection
&sort[]=-date_created
&filter[_and][1][status][_neq]=archived`;
