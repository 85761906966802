import { useEffect, useState } from "react";

import { useAppDispatch } from "redux/hooks/hooks";
import { setActionText } from "redux/slices/actionTextSlice";

import { Answer, Answers, QuestionObject, QUESTION_TYPE_IDS } from "types/types";
import { getTranslations } from "translations/textarea.translations";

const { YES, NO } = Answers;
const { YES_NO, TEXT, FILE_PHOTO } = QUESTION_TYPE_IDS;

const defaultMaxText = 4096;

function useActionTextNew(
  currentQuestion: QuestionObject | undefined,
  answerToCurrentQuestion: Answer | undefined,
  answerTextAreaValue: string | undefined,
  reactionTextAreaValue: string,
  language: string,
) {
  const [isTextPromptVisible, setIsTextPromptVisible] = useState(false);
  const dispatch = useAppDispatch();

  const { error } = getTranslations(language);

  useEffect(() => {
    if (!currentQuestion) return;

    const {
      QuestionType,
      OnYesRequiredMinText,
      OnYesMaxText,
      RequiredMinText,
      MaxText,
      Required,
      OnYesActionText,
      OnNoActionText,
      OnNoRequiredMinText,
      OnNoMaxText,
    } = currentQuestion;

    const YES_NO_QUESTION = QuestionType.includes(YES_NO);
    const TEXT_QUESTION = QuestionType === TEXT;
    const PHOTO_QUESTION = QuestionType === FILE_PHOTO;

    const defaultMinText = Required ? 1 : 0;

    let isPromptVisible = false;

    let minText = RequiredMinText ?? defaultMinText;
    let maxText = MaxText ?? defaultMaxText;
    let textLength = 0;

    if (YES_NO_QUESTION) {
      textLength = reactionTextAreaValue.length;

      if (answerToCurrentQuestion) {
        const { Value, ReactionValue } = answerToCurrentQuestion;

        switch (Value) {
          case YES:
            minText = OnYesRequiredMinText ?? defaultMinText;
            maxText = OnYesMaxText ?? defaultMaxText;

            isPromptVisible = !!OnYesActionText && !ReactionValue?.length;

            break;
          case NO:
            minText = OnNoRequiredMinText ?? defaultMinText;
            maxText = OnNoMaxText ?? defaultMaxText;

            isPromptVisible = !!OnNoActionText && !ReactionValue?.length;
        }
      }
    }

    if (TEXT_QUESTION) {
      textLength = answerTextAreaValue?.length ?? 0;
    }

    if (PHOTO_QUESTION) {
      textLength = reactionTextAreaValue.length;
      isPromptVisible = true;

      if (answerToCurrentQuestion) {
        const { ReactionValue } = answerToCurrentQuestion;
        isPromptVisible = !ReactionValue?.length;
      }
    }

    const isTextTooShort = textLength < minText;
    const isTextTooLong = textLength > maxText;
    const isMinText = textLength >= minText;

    const errorMessage = isTextTooLong
      ? `${error.textTooLong} [${textLength}/${maxText}]`
      : "";

    setIsTextPromptVisible(isPromptVisible);

    dispatch(
      setActionText({
        minText,
        maxText,
        textLength,
        isTextTooShort,
        isTextTooLong,
        isMinText,
        errorMessage,
      }),
    );
  }, [
    currentQuestion,
    answerTextAreaValue?.length,
    answerToCurrentQuestion,
    reactionTextAreaValue.length,
    error.textTooLong,
    dispatch,
  ]);

  return {
    isTextPromptVisible,
  };
}

export default useActionTextNew;
