import { QuestionObject } from "types/types";

function usePhotoCounter() {
  const setUpPhotoQuestionsInStorage = (photoTypeQuestions: QuestionObject[]) => {
    if (!photoTypeQuestions.length) return;

    localStorage.setItem(
      "photoTypeQuestions",
      JSON.stringify(photoTypeQuestions.map(({ id }) => id)),
    );

    photoTypeQuestions.forEach((question) => {
      if (!!localStorage.getItem(question.id)) return;
      localStorage.setItem(question.id, "0");
    });
  };

  const setPhotoCountInStorage = (id: string, count: number) => {
    localStorage.setItem(id, `${count}`);
  };

  return {
    setUpPhotoQuestionsInStorage,
    setPhotoCountInStorage,
  };
}

export default usePhotoCounter;
