import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreatedTask } from "types/tasks.types";

type SelectedTask = CreatedTask | undefined;

type InitialState = {
  allTasks: CreatedTask[];
  selectedTask: SelectedTask;
  tasksSearchParams: string;
};

const getTasksSearchParamsFromStorage = () => {
  const localData = sessionStorage.getItem("tasksSearchParams");

  return !!localData ? localData : "?status=todo";
};

const initialState: InitialState = {
  allTasks: [],
  selectedTask: undefined,
  tasksSearchParams: getTasksSearchParamsFromStorage(),
};

export const tasksSlice = createSlice({
  name: "tasksSlice",
  initialState,
  reducers: {
    setAllTasks(state, action: PayloadAction<CreatedTask[]>) {
      return { ...state, allTasks: action.payload };
    },
    setTasksSearchParams(state, action: PayloadAction<string>) {
      return { ...state, tasksSearchParams: action.payload };
    },
  },
});

export const { setAllTasks, setTasksSearchParams } = tasksSlice.actions;
