import { ChangeEvent, RefObject } from "react";
import styled, { css } from "styled-components/macro";
import { DetectedBarcode } from "barcode-detector";

import BarcodeScanner from "../barcode-scanner/BarcodeScanner";

import { TextArea } from "styles/generalStyles";
import AutoSaveLabel from "./auto-save-label/AutoSaveLabel";

const Container = styled.div<{ marginTop: boolean; isModal: boolean }>`
  position: relative;
  height: 100%;

  ${({ isModal }) =>
    isModal &&
    css`
      height: 25rem;
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 1rem;
    `}
`;

const MinTextLength = styled.span`
  position: absolute;
  right: 0.2rem;
  top: -2rem;
  font-size: 1.4rem;
  opacity: 0.75;
`;

const BottomMessage = styled.span`
  position: absolute;
  bottom: -1.8rem;
  left: 0;
  font-size: 1.4rem;
`;

const ErrorMessage = styled(BottomMessage)`
  color: ${({ theme }) => theme.errorColor};
`;

const CharacterCounter = styled(BottomMessage)`
  color: ${({ theme }) => theme.primary_600};
`;

type TextareaBarcodeScannerProps = {
  labels: {
    minTextLength: string;
    remaining: string;
  };
  onScan: (result: DetectedBarcode[]) => void;
  isModal?: boolean;
  readOnly?: boolean;
  textareaRef: RefObject<HTMLTextAreaElement>;
  errorMessage: string;
  minText: number;
  maxText: number;
  textAreaValue: string | undefined;
  onTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  isBarcodeScannerVisible: boolean;
  required?: boolean;
  saved?: boolean;
  isTyping?: boolean;
  task?: boolean;
};

function TextareaBarcodeScanner({
  labels,
  onScan,
  isModal = false,
  readOnly = false,
  textareaRef,
  errorMessage,
  minText,
  maxText,
  textAreaValue,
  onTextAreaChange,
  isBarcodeScannerVisible,
  required = false,
  saved = false,
  isTyping = false,
  task = false,
}: TextareaBarcodeScannerProps) {
  const isLabelVisible = required && minText > 1 && !isBarcodeScannerVisible;
  const textLength = textAreaValue ? textAreaValue.length : 0;
  const numbersOfCharachtersLeft = minText - textLength;

  let autoSaveLabelVisible = false;

  if (textAreaValue) {
    autoSaveLabelVisible = !isModal && textAreaValue.length <= maxText;

    if (required) {
      autoSaveLabelVisible =
        !isModal && textAreaValue.length >= minText && textAreaValue.length <= maxText;
    }
  }

  return (
    <Container marginTop={isLabelVisible} isModal={isModal}>
      {autoSaveLabelVisible && <AutoSaveLabel saved={saved} isTyping={isTyping} />}

      {isLabelVisible && (
        <MinTextLength>
          {!textLength ? (
            <span>
              &#91;{labels.minTextLength}&nbsp;{minText}&#93;
            </span>
          ) : (
            numbersOfCharachtersLeft > 0 && (
              <span>
                &#91;{labels.remaining} {numbersOfCharachtersLeft}&#93;
              </span>
            )
          )}
        </MinTextLength>
      )}
      {!isBarcodeScannerVisible ? (
        <TextArea
          onChange={onTextAreaChange}
          value={textAreaValue || ""}
          ref={textareaRef}
          smallFont
          disabled={readOnly}
          marginBottom={readOnly}
          isError={!!errorMessage}
        />
      ) : (
        <BarcodeScanner onScan={onScan} />
      )}

      <ErrorMessage>{errorMessage}</ErrorMessage>

      {!errorMessage && !isBarcodeScannerVisible && !task && (
        <CharacterCounter>
          &#91;{textLength} / {maxText}&#93;
        </CharacterCounter>
      )}
    </Container>
  );
}

export default TextareaBarcodeScanner;
