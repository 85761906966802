import { Answer, Answers, QUESTION_TYPE_IDS, QuestionObject } from "types/types";
import useDevices from "./useDevices";
import { defaultMaxPhotos, getDefaultMinPhotos } from "utils/photos.utils";

const { YES, NO } = Answers;
const { YES_NO, FILE_PHOTO } = QUESTION_TYPE_IDS;

const checkIfHasPhotos = (id: string) => {
  const localData = localStorage.getItem(id);

  return localData ? !!parseInt(localData) : false;
};

function useActionPhoto(
  currentQuestion: QuestionObject | undefined,
  answerToCurrentQuestion: Answer | undefined,
) {
  const { isVideoInput } = useDevices();
  let minPhotos = 0;
  let maxPhotos = 0;

  if (!currentQuestion) return { minPhotos, maxPhotos };

  const {
    QuestionType,
    OnYesRequiredMinPhotos,
    OnYesMaxPhotos,
    RequiredMinPhotos,
    MaxPhotos,
    Required,
    OnYesActionPhoto,
    OnNoActionPhoto,
    OnNoRequiredMinPhotos,
    OnNoMaxPhotos,
  } = currentQuestion;
  const YES_NO_QUESTION = QuestionType.includes(YES_NO);
  const PHOTO_QUESTION = QuestionType === FILE_PHOTO;

  const defaultMinPhotos = getDefaultMinPhotos(Required);

  let isPhotoPromptVisible = false;

  if (YES_NO_QUESTION) {
    if (answerToCurrentQuestion) {
      const { Value, Question } = answerToCurrentQuestion;
      let hasPhotos = checkIfHasPhotos(Question);

      switch (Value) {
        case YES:
          minPhotos = OnYesRequiredMinPhotos ?? defaultMinPhotos;
          maxPhotos = OnYesMaxPhotos ?? defaultMaxPhotos;

          isPhotoPromptVisible = isVideoInput && !!OnYesActionPhoto && !hasPhotos;

          break;

        case NO:
          minPhotos = OnNoRequiredMinPhotos ?? defaultMinPhotos;
          maxPhotos = OnNoMaxPhotos ?? defaultMaxPhotos;

          isPhotoPromptVisible = isVideoInput && !!OnNoActionPhoto && !hasPhotos;
      }
    }
  }

  if (PHOTO_QUESTION) {
    minPhotos = RequiredMinPhotos ?? defaultMinPhotos;
    maxPhotos = MaxPhotos ?? defaultMaxPhotos;

    isPhotoPromptVisible = isVideoInput;

    if (answerToCurrentQuestion) {
      isPhotoPromptVisible =
        isVideoInput && !checkIfHasPhotos(answerToCurrentQuestion.Question);
    }
  }

  return { isPhotoPromptVisible, minPhotos, maxPhotos };
}

export default useActionPhoto;
