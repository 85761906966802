import {
  Answer,
  Answers,
  QuestionnaireObject,
  QUESTION_TYPE_IDS,
  QuestionObject,
} from "types/types";
import {
  checkIfIsAnswerWithPhotos,
  getMinMaxPhotos,
  getPhotoCount,
} from "utils/photos.utils";

const { YES_NO, TEXT, FILE_PHOTO } = QUESTION_TYPE_IDS;
const { NO, YES } = Answers;

const getRequiredQuestions = (questionnaire: QuestionnaireObject) =>
  questionnaire.Questions.filter(({ Required }) => !!Required);

// ----- Answers with missing photos handlers -----

const getAnswersWithPhotos = (answer: Answer) =>
  checkIfIsAnswerWithPhotos(answer.Question) ? answer : undefined;

const getAnswersWithMissingPhotos = (
  requiredQuestions: QuestionObject[],
  answers: Answer[],
) =>
  answers
    .filter(getAnswersWithPhotos)
    .reduce((acc: Answer[], currAnswer) => {
      const currentQuestion = requiredQuestions.find(
        ({ id }) => id === currAnswer.Question,
      );

      if (currentQuestion) {
        const { QuestionType, OnYesActionPhoto, OnNoActionPhoto } = currentQuestion;

        const YES_NO_QUESTION = QuestionType.includes(YES_NO);
        const PHOTO_QUESTION = QuestionType === FILE_PHOTO;
        const { Value, Question } = currAnswer;
        const { minPhotos } = getMinMaxPhotos(currentQuestion, currAnswer);
        const photosTaken = getPhotoCount(Question);

        if (YES_NO_QUESTION) {
          if ((Value === YES && OnYesActionPhoto) || (Value === NO && OnNoActionPhoto)) {
            if (minPhotos > photosTaken) {
              acc = [...acc, currAnswer];
            }
          }
        }

        if (PHOTO_QUESTION) {
          if (minPhotos > photosTaken) {
            acc = [...acc, currAnswer];
          }
        }
      }

      return acc;
    }, [])
    .sort((a, b) => a.sort - b.sort);

// ----- Answers with missing description handlers -----

const getAnswersWithMissingDescription = (
  answers: Answer[],
  requiredQuestions: QuestionObject[],
) =>
  answers.filter(({ QuestionType, ReactionValue, Value, Question }) => {
    const currentQuestion = requiredQuestions.find(({ id }) => id === Question);
    let flag = false;

    if (!currentQuestion) return flag;

    const { OnNoActionText, OnYesActionText, OnNoRequiredMinText, OnYesRequiredMinText } =
      currentQuestion;

    if (QuestionType.includes(YES_NO)) {
      if (Value === NO) {
        if (OnNoActionText) {
          if (OnNoRequiredMinText) {
            flag = !ReactionValue;
          } else {
            flag = false;
          }
        }
      }

      if (Value === YES) {
        if (OnYesActionText) {
          if (OnYesRequiredMinText) {
            flag = !ReactionValue;
          } else {
            flag = false;
          }
        }
      }
    }

    if (QuestionType === TEXT) flag = !Value;

    return flag;
  });

// --------------- ***** ---------------

function useRequiredQuestions(answers: Answer[], questionnaire: QuestionnaireObject) {
  const requiredQuestions = getRequiredQuestions(questionnaire);

  // ***** Required questions *****

  let allRequiredQuestionsAnswered = true;
  let requiredQuestionsUnanswered: string[] = [];

  let answersWithMissingPhotosNumber = 0;
  let answersWithMissingDescriptionNumber = 0;

  let firstUnansweredQuestionIndex = -1;
  let firstQuestionWithMissingPhotosIndex = -1;
  let firstQuestionWithMissingDescriptionIndex = -1;

  if (requiredQuestions.length) {
    const requiredQuestionsAnswered = requiredQuestions.reduce((acc: string[], curr) => {
      if (answers.some(({ Question }) => Question === curr.id)) {
        acc = [...acc, curr.id];
      }

      return acc;
    }, []);

    requiredQuestionsUnanswered = requiredQuestions.reduce((acc: string[], curr) => {
      if (!requiredQuestionsAnswered.some((id) => id === curr.id)) {
        acc = [...acc, curr.id];
      }

      return acc;
    }, []);

    firstUnansweredQuestionIndex = questionnaire.Questions.findIndex(
      ({ id }) => id === requiredQuestionsUnanswered[0],
    );

    allRequiredQuestionsAnswered = !requiredQuestionsUnanswered.length;

    // ***** Answers with missing photos *****

    const answersWithMissingPhotos = getAnswersWithMissingPhotos(
      requiredQuestions,
      answers,
    );

    answersWithMissingPhotosNumber = answersWithMissingPhotos.length;

    if (answersWithMissingPhotosNumber) {
      firstQuestionWithMissingPhotosIndex = questionnaire.Questions.findIndex(
        ({ id }) => id === answersWithMissingPhotos[0].Question,
      );
    }

    // ***** Answers with missing description *****

    const answersWithMissingDescription = getAnswersWithMissingDescription(
      answers,
      requiredQuestions,
    );
    answersWithMissingDescriptionNumber = answersWithMissingDescription.length;

    if (answersWithMissingDescriptionNumber) {
      firstQuestionWithMissingDescriptionIndex = questionnaire.Questions.findIndex(
        ({ id }) => id === answersWithMissingDescription[0].Question,
      );
    }
  }

  const indexes = [
    firstUnansweredQuestionIndex,
    firstQuestionWithMissingPhotosIndex,
    firstQuestionWithMissingDescriptionIndex,
  ].filter((index) => index >= 0);

  // This is to prevent goToIndex from getting value of Infinity
  const goToIndex = indexes.length ? Math.min(...indexes) : 0;

  return {
    allRequiredQuestionsAnswered,
    answersWithMissingPhotosNumber,
    answersWithMissingDescriptionNumber,
    goToIndex,
  };
}

export default useRequiredQuestions;
