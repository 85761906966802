import { TaskStatuses } from "types/tasks.types";
import { getLabelTranslation } from "../translations/task.filter.translations";
import {
  UserLabelKeys,
  TaskFilter,
  PresetPayloadFilters,
  PresetObjectKeys,
} from "../types/task.filter.types";

const { AssignTo, user_created, expired } = UserLabelKeys;
const { DONE, IN_PROGRESS, TO_DO } = TaskStatuses;
const { ASSIGN_TO, DEADLINE, STATUS, USER_CREATED } = PresetObjectKeys;

export const getUserFilters = (language: string) => [
  { id: AssignTo, label: getLabelTranslation(language, AssignTo) },
  { id: user_created, label: getLabelTranslation(language, user_created) },
];

export const getStatusFilters = (language: string) => [
  { id: TO_DO, label: getLabelTranslation(language, TO_DO) },
  { id: IN_PROGRESS, label: getLabelTranslation(language, IN_PROGRESS) },
  { id: DONE, label: getLabelTranslation(language, DONE) },
  // { id: ARCHIVED, label: getLabelTranslation(language, ARCHIVED) },
];

export const getExpiredLabel = (language: string) => [
  { id: expired, label: getLabelTranslation(language, expired) },
];

export const getInitialFilters = (): TaskFilter => ({
  user: [],
  status: TO_DO,
  expired: false,
});

export const getFiltersArray = (userId: string, filters: TaskFilter) => {
  const { status, user, expired } = filters;

  return [
    { [STATUS]: { _eq: status } },
    { [ASSIGN_TO]: { _eq: user.includes(AssignTo) ? userId : null } },
    { [USER_CREATED]: { _eq: user.includes(user_created) ? userId : null } },
    { [DEADLINE]: { _lt: expired ? "$NOW" : null } },
  ].reduce((acc, curr) => {
    if (curr[STATUS]?._eq) {
      acc = [...acc, curr];
    }

    if (curr[DEADLINE]?._lt) {
      acc = [...acc, curr];
    }

    if (curr[ASSIGN_TO]?._eq) {
      acc = [...acc, curr];
    }

    if (curr[USER_CREATED]?._eq) {
      acc = [...acc, curr];
    }

    return acc;
  }, [] as PresetPayloadFilters[]);
};
