import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { DetectedBarcode } from "barcode-detector";
import { useAppSelector } from "redux/hooks/hooks";

import { QuestionType } from "types/types";
import useDictaphoneAccess from "hooks/useDictaphoneAccess";
import useBarcodeScannerAccess from "hooks/useBarcodeScannerAccess";
import { getTranslations } from "./translations/reaction-modal.translations";
import { getTranslations as getTextAreaTranslations } from "translations/textarea.translations";
import { getTextValueAfterScan } from "utils/barcode-scanner.utils";
import { focusElement, isMobile, placeCursorAtTheEnd, scrollToBottom } from "utils/utils";

import { ReactComponent as BarCodeIcon } from "assets/icons/barcode.svg";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";

import Modal from "components/templates/Modal";
import Button from "components/atoms/Button";
import Dictaphone from "components/molecules/Dictaphone";
import TextareaBarcodeScanner from "../textarea-barcode-scanner/TextareaBarcodeScanner";

import { ModalStyles } from "styles/generalStyles";

const { Content, Actions, ButtonContainer } = ModalStyles;

const BtnWrapper = styled.div`
  .save-btn {
    height: 5rem;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
`;

const BarcodeTextIconsContainer = styled.div<{
  isDictaphoneAccessible: boolean;
  isMobile: boolean;
}>`
  position: absolute;
  left: ${({ isDictaphoneAccessible }) => (isDictaphoneAccessible ? "5rem" : "0")};
  top: 50%;
  transform: translateY(-50%);

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  }
`;

type EditReactionModalProps = {
  open: boolean;
  onClose: () => void;
  questionType?: QuestionType | "";
  reactionTextAreaValue: string;
  onReactionTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  onTextTranscriptChange: (val: string) => void;
  onSaveReactionBtnClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  header?: string;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  task?: boolean;
};

function ReactionModal({
  open,
  onClose,
  questionType,
  reactionTextAreaValue,
  onReactionTextAreaChange,
  onTextTranscriptChange,
  onSaveReactionBtnClick,
  loading = false,
  disabled = false,
  header = "",
  optional = false,
  readOnly = false,
  required = false,
  task = false,
}: EditReactionModalProps) {
  const [isBarcodeScannerVisible, setIsBarcodeScannerVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isFirstRender = useRef(true);
  const {
    t,
    i18n: { language },
  } = useTranslation(["questionnaires", "common"]);

  const { isDictaphoneAccessible } = useDictaphoneAccess();
  const { hasAccessToBarcodeScanner } = useBarcodeScannerAccess();
  const { minText, maxText, textLength, isMinText, errorMessage } = useAppSelector(
    ({ actionText }) => actionText,
  );

  const { headerLabel } = getTranslations(language);
  const { labels } = getTextAreaTranslations(language);
  const modalHeader = !!header ? header : headerLabel;
  const isListening = !!searchParams.get("listening");
  const isEmpty = !reactionTextAreaValue;

  let btnDisabled = disabled || isListening || !!errorMessage || isEmpty || !isMinText;

  if (optional) {
    btnDisabled = disabled || isListening || !!errorMessage;
  }

  if (task) {
    btnDisabled = disabled || isListening || !!errorMessage || isEmpty;
  }

  const openBarcodeScanner = () => {
    setIsBarcodeScannerVisible(true);
  };

  const closeBarcodeScanner = () => {
    setIsBarcodeScannerVisible(false);
  };

  // *************** On click handlers ***************

  const onSaveButtonClick = () => {
    if (isBarcodeScannerVisible) {
      closeBarcodeScanner();
    }
    onSaveReactionBtnClick();
  };

  const onBarcodeIconClick = () => {
    openBarcodeScanner();
  };

  const onTextIconClick = () => {
    closeBarcodeScanner();
  };

  const onCloseIconClick = () => {
    if (isBarcodeScannerVisible) {
      closeBarcodeScanner();
    }
    onClose();
    isFirstRender.current = true;
  };

  // *************** On scan handler ***************

  const onScan = (result: DetectedBarcode[]) => {
    const { rawValue } = result[0];
    const value = getTextValueAfterScan(reactionTextAreaValue, rawValue);

    onReactionTextAreaChange(value);
    closeBarcodeScanner();
  };

  // *************** Effect handler ***************

  useEffect(() => {
    const { current: textArea } = textareaRef;

    if (!open || !textArea) return;

    if (isFirstRender.current) {
      focusElement(textArea);
      placeCursorAtTheEnd(textArea, textLength);
      scrollToBottom(textArea);
      isFirstRender.current = false;
    }
  }, [open, textLength]);

  return (
    <Modal
      header={modalHeader}
      onClose={onCloseIconClick}
      open={open}
      closeDisabled={isListening}
    >
      <Content>
        <TextareaBarcodeScanner
          isModal
          required={required}
          labels={labels}
          onScan={onScan}
          readOnly={readOnly}
          textareaRef={textareaRef}
          errorMessage={errorMessage}
          minText={minText}
          maxText={maxText}
          textAreaValue={reactionTextAreaValue}
          onTextAreaChange={onReactionTextAreaChange}
          isBarcodeScannerVisible={isBarcodeScannerVisible}
          task={task}
        />
        {!readOnly && (
          <Actions spaceBetween={isDictaphoneAccessible || hasAccessToBarcodeScanner}>
            <IconsContainer>
              {isDictaphoneAccessible && (
                <Dictaphone
                  onTextTranscriptChange={onTextTranscriptChange}
                  questionType={questionType}
                  answerTextAreaValue={reactionTextAreaValue}
                  isBarcodeScannerVisible={isBarcodeScannerVisible}
                />
              )}
              {hasAccessToBarcodeScanner && !isListening && (
                <BarcodeTextIconsContainer
                  isMobile={isMobile}
                  isDictaphoneAccessible={isDictaphoneAccessible}
                >
                  {!isBarcodeScannerVisible ? (
                    <BarCodeIcon className='icon' onClick={onBarcodeIconClick} />
                  ) : (
                    <TextIcon className='icon' onClick={onTextIconClick} />
                  )}
                </BarcodeTextIconsContainer>
              )}
            </IconsContainer>

            <ButtonContainer>
              <BtnWrapper>
                <Button
                  onClick={onSaveButtonClick}
                  label={t("common:button.save")}
                  loading={loading}
                  disabled={btnDisabled}
                  customClass='save-btn'
                />
              </BtnWrapper>
            </ButtonContainer>
          </Actions>
        )}
      </Content>
    </Modal>
  );
}

export default ReactionModal;
