import { useContext } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "react-use-cookie";

import { logout } from "api/auth";
import { ACCESS_TOKEN, REFRESH_TOKEN, clearLocalStorage } from "utils/utils";
import AuthContext from "contexts/auth-context/AuthContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import UserContext from "contexts/user-context/UserContext";
import useTimeout from "./useTimeout";
import useClearObjectStoreQuestionnaire from "./useClearObjectStoreQuestionnaire";
import useClearObjectStoreAccident from "./useClearObjectStoreAccident";
import useClearObjectStoreTask from "./useClearObjectStoreTask";
import usePermissions from "./permissions-hooks/usePermissions";
import { setTasksSearchParams } from "redux/slices/tasksSlice";
import { useAppDispatch } from "redux/hooks/hooks";
import useAssessmentResponse from "./useAssessmentResponse";

function useLogout() {
  const { clearWholeStateAction, removeAccidentsQuery } =
    useContext(QuestionnairesContext);
  const { clearAuthState } = useContext(AuthContext);
  const { setUser, removeUserInfoQuery } = useContext(UserContext);
  const refresh_token = getCookie(REFRESH_TOKEN);
  const navigate = useNavigate();
  const setTimeOut = useTimeout();
  const { removePermissionsQuery } = usePermissions();

  const { deleteQuestionnairePhotos } = useClearObjectStoreQuestionnaire();
  const { deleteAccidentPhotos } = useClearObjectStoreAccident();
  const { deleteTaskPhotosInIndexedDB } = useClearObjectStoreTask();
  const { deleteAssessmentDataInIndexedDB } = useAssessmentResponse();
  const dispatch = useAppDispatch();

  const clearAllStates = () => {
    setCookie(ACCESS_TOKEN, "");
    setCookie(REFRESH_TOKEN, "");
    clearAuthState();

    setTimeOut(() => {
      clearWholeStateAction();
      setUser(undefined);
      removeUserInfoQuery();
      removePermissionsQuery();
      clearLocalStorage();
      sessionStorage.clear();
      removeAccidentsQuery();
      deleteQuestionnairePhotos();
      deleteAccidentPhotos();
      deleteTaskPhotosInIndexedDB();
      deleteAssessmentDataInIndexedDB();
      dispatch(setTasksSearchParams(""));
    }, 1000);

    return Promise.resolve();
  };

  const onSettled = () => {
    clearAllStates().then(() => {
      navigate("/login", { replace: true });
    });
  };

  const { mutate } = useMutation(logout, {
    onSettled,
    onError: onSettled,
  });

  const handleLogOut = () => {
    mutate({ refresh_token });
  };

  return { handleLogOut, clearAllStates };
}

export default useLogout;
