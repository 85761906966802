import { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import {
  Answer,
  Answers,
  QuestionObject,
  QuestionType,
  QUESTION_TYPE_IDS,
  FormTypes,
  CreateAnswerValue,
} from "types/types";
import { clearLocalStorage, findAnswer, findAnswerIndex, getSize } from "utils/utils";
import usePhotosObjectStore from "./usePhotosObjectStore";

type Qn = QuestionObject | undefined;
type QT = QuestionType | "";

const { NUMERIC, TEXT, YES_NO, FILE_PHOTO, SELECT } = QUESTION_TYPE_IDS;
const { QUESTIONNAIRE } = FormTypes;

function useQuestionnaire() {
  const {
    state: { answers, machineProcess, selectedQuestionnaire },
    setAnswersAction,
    setSelectedQuestionnaireAction,
    serialNumberPath,
  } = useContext(QuestionnairesContext);

  const [currentQuestion, setCurrentQuestion] = useState<Qn>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);

  const [answerInputValue, setAnswerInputValue] = useState("");
  const [answerTextAreaValue, setAnswerTextAreaValue] = useState<string>("");

  const [answerSelectValue, setAnswerSelectValue] = useState({ label: "", value: "" });
  const [reactionTextAreaValue, setReactionTextAreaValue] = useState("");

  const [cameraVisible, setCameraVisible] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);

  const numberOfQuestions = selectedQuestionnaire?.Questions.length;
  const questionType: QT = currentQuestion?.QuestionType ?? "";

  const answerToCurrentQuestion = findAnswer(answers, currentQuestion?.id);
  const answerToCurrentQuestionIndex = findAnswerIndex(answers, currentQuestion?.id);
  const params = useParams();
  const navigate = useNavigate();

  const {
    getPhotosFromIndexedDB,
    updatePhotosInIndexedDB,
    deleteAllPhotosInIndexedDB,
    getNumberOfPhotos,
  } = usePhotosObjectStore(QUESTIONNAIRE);

  const [saved, setSaved] = useState(false);

  const isTyping = !!sessionStorage.getItem("typing");

  // --------------- Clear state handlers ---------------

  const clearState = () => {
    setAnswersAction([]);
    setSelectedQuestionnaireAction(undefined);
    clearLocalStorage(["selectedQuestionnaire", "currentPath", "answers"]);
    deleteAllPhotosInIndexedDB();
    sessionStorage.clear();
  };

  const clearInputFields = () => {
    if (answerInputValue) {
      setAnswerInputValue("");
    }
    if (answerTextAreaValue) {
      setAnswerTextAreaValue("");
    }
    if (reactionTextAreaValue) {
      setReactionTextAreaValue("");
    }
  };

  // --------------- Textarea handler ---------------

  const handleAnswerTextArea = (value: string) => {
    setAnswerTextAreaValue(value);
    sessionStorage.setItem("typing", JSON.stringify(true));
  };

  // --------------- Navigate handlers ---------------

  const goToNextQuestion = () => {
    if (!currentQuestion || !numberOfQuestions) return;

    if (currentQuestionIndex + 1 < numberOfQuestions) {
      setCurrentQuestionIndex((prev) => prev + 1);

      if (params.question) {
        const nextQuestionNumber = +params.question + 1;
        navigate(`/questionnaire/${selectedQuestionnaire?.id}/${nextQuestionNumber}`);
      }
    } else {
      navigate(`/questionnaire/summary/${selectedQuestionnaire?.id}`);
    }

    clearInputFields();

    if (cameraVisible) {
      setCameraVisible(false);
    }
  };

  const goToPreviousQuestion = () => {
    if (params.question) {
      const prevQuestionNumber = +params.question - 1;
      navigate(`/questionnaire/${selectedQuestionnaire?.id}/${prevQuestionNumber}`);
    }
    setCurrentQuestionIndex((prev) => prev - 1);
  };

  // --------------- Answer creator ---------------

  const createAnswer = useCallback(
    (answerValue: CreateAnswerValue) => {
      if (!questionType) return;
      if (!currentQuestion) return;

      const { id, sort, QuestionText, QuestionSubtext, QuestionType } = currentQuestion;
      let Value = null;
      let reaction = null;
      let FilesGallery = null;
      let Gravity = 0;

      if ("Gravity" in currentQuestion) {
        Gravity = currentQuestion.Gravity as unknown as number;
      }

      switch (questionType) {
        case TEXT:
          Value = answerValue.textValue as string;
          break;

        case NUMERIC:
          Value = answerValue.numericValue as string;
          break;

        case FILE_PHOTO:
          if (!answerToCurrentQuestion) {
            // Answer not created yet, this is the first photo to be added to FilesGallery
            FilesGallery = answerValue.photoSrc
              ? [{ src: answerValue.photoSrc, comment: "" }]
              : null;

            updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
          } else {
            // Answer already exists, update FilesGallery (add another photo)
            // If null, filesGallery is set to an empty array
            // to avoid "filesGallery is not iterable" error.
            if (answerValue.photoSrc) {
              const src = answerValue.photoSrc as string;

              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                const filesGallery = photosFromDB ?? [];
                FilesGallery = [...filesGallery, { src, comment: "" }];

                updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
              });
            } else {
              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                updatePhotosInIndexedDB({
                  Question: currentQuestion.id,
                  FilesGallery: photosFromDB,
                });
              });
            }
          }

          reaction = reactionTextAreaValue
            ? reactionTextAreaValue
            : (answerToCurrentQuestion?.ReactionValue as string);
          break;

        case SELECT:
          Value = answerValue.selectValue as string;
          break;

        // ----------------------------------------------------

        default:
          if (!answerToCurrentQuestion) {
            Value = answerValue.yesNo as Answers;
          } else {
            Value = answerToCurrentQuestion.Value;

            if (answerValue.photoSrc) {
              const src = answerValue.photoSrc as string;

              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                const filesGallery = photosFromDB ?? [];
                FilesGallery = [...filesGallery, { src, comment: "" }];

                updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
              });
            } else {
              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                updatePhotosInIndexedDB({
                  Question: currentQuestion.id,
                  FilesGallery: photosFromDB,
                });
              });
            }

            reaction = reactionTextAreaValue
              ? reactionTextAreaValue
              : (answerToCurrentQuestion?.ReactionValue as string);
          }
      }

      const ReactionValue = reactionTextAreaValue ? reactionTextAreaValue : reaction;

      const ValueSize = Value
        ? getSize(Value) + getSize(FilesGallery?.map(({ src }) => src) as string[])
        : 0;
      const ReactionSize =
        getSize(ReactionValue) + getSize(FilesGallery?.map(({ src }) => src) as string[]);

      const Machine = machineProcess?.Machine ? machineProcess?.Machine.id : null;
      const Process = machineProcess?.Process ? machineProcess?.Process.id : null;

      const answer = Object.assign(
        {
          sort,
          Gravity,
          AnswerCreated: new Date(),
          OriginalText: QuestionText,
          OriginalSubText: QuestionSubtext,
          Value,
          ValueSize,
          ReactionValue,
          ReactionSize,
          Question: id,
          QuestionType,
          FilesGallery: null,
        },
        Machine && { Machine },
        Process && { Process },
      ) as Answer;

      let modifiedAnswers = [];

      // Modify answer that is already in state
      if (answerToCurrentQuestionIndex > -1) {
        modifiedAnswers = answers;
        modifiedAnswers[answerToCurrentQuestionIndex] = answer;
      } else {
        // Add a new answer to answers array
        modifiedAnswers = [...answers, answer];
      }

      setAnswersAction(modifiedAnswers);
    },
    [
      answerToCurrentQuestion,
      answerToCurrentQuestionIndex,
      answers,
      currentQuestion,
      getPhotosFromIndexedDB,
      machineProcess?.Machine,
      machineProcess?.Process,
      questionType,
      reactionTextAreaValue,
      setAnswersAction,
      updatePhotosInIndexedDB,
    ],
  );

  // --------------- Answers updater ---------------

  const updateAnswersInState = (updatedAnswer: Answer) => {
    const modifiedAnswers = answers;
    modifiedAnswers[answerToCurrentQuestionIndex] = updatedAnswer;

    setAnswersAction(modifiedAnswers);
  };

  // --------------- Remove handlers ---------------

  // ----- Photo -----
  const removePhoto = (photoIndex: number) => {
    if (!answerToCurrentQuestion || !currentQuestion) {
      return;
    }

    const updatedFilesGallery = answerToCurrentQuestion.FilesGallery;
    updatedFilesGallery?.splice(photoIndex, 1);

    const updatedAnswer = {
      ...answerToCurrentQuestion,
      FilesGallery: updatedFilesGallery,
    };

    updateAnswersInState(updatedAnswer);
  };

  // ----- Reaction -----
  const removeReactionComment = () => {
    if (!answerToCurrentQuestion || !currentQuestion) {
      return;
    }

    const updatedAnswer = {
      ...answerToCurrentQuestion,
      ReactionValue: null,
    };

    updateAnswersInState(updatedAnswer);

    if (reactionTextAreaValue) {
      setReactionTextAreaValue("");
    }
  };

  // ----- Answer -----
  const removeAnswer = async (Question: string) => {
    const updatedAnswers = answers.filter((answer) => answer.Question !== Question);

    setAnswersAction(updatedAnswers);

    const containsPhotos = await !!getNumberOfPhotos(Question);

    if (containsPhotos) {
      updatePhotosInIndexedDB({ Question, FilesGallery: null });
    }

    if (questionType.includes(YES_NO)) {
      if (reactionTextAreaValue) {
        setReactionTextAreaValue("");
      }
    }
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (params.id && params.question) {
      const index = +params.question - 1;

      setCurrentQuestionIndex(index);
    }
  }, [params, setCurrentQuestionIndex]);

  useEffect(() => {
    if (selectedQuestionnaire) {
      setCurrentQuestion(selectedQuestionnaire.Questions[currentQuestionIndex]);
    }
  }, [currentQuestionIndex, selectedQuestionnaire]);

  useEffect(() => {
    const isPreviewMode = answers.some(
      ({ Question }) => Question === currentQuestion?.id,
    );

    setPreviewMode(isPreviewMode);
  }, [answers, currentQuestion]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isTyping && answerTextAreaValue) {
        createAnswer({ textValue: answerTextAreaValue });
        setSaved(true);
        sessionStorage.removeItem("typing");
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [answerTextAreaValue, createAnswer, isTyping]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (saved) {
        setSaved(false);
      }
    }, 1200);

    return () => clearTimeout(timer);
  }, [saved]);

  return {
    answers,
    createAnswer,
    removeAnswer,
    currentQuestion,
    currentQuestionIndex,
    questionType,
    numberOfQuestions,
    answerInputValue,
    setAnswerInputValue,
    answerTextAreaValue,
    setAnswerTextAreaValue,
    handleAnswerTextArea,
    answerSelectValue,
    setAnswerSelectValue,
    reactionTextAreaValue,
    setReactionTextAreaValue,
    cameraVisible,
    setCameraVisible,
    previewMode,
    goToNextQuestion,
    goToPreviousQuestion,
    clearInputFields,
    clearState,
    answerToCurrentQuestion,
    removePhoto,
    removeReactionComment,
    serialNumberPath,
    isTyping,
    saved,
  };
}

export default useQuestionnaire;
