import { useTranslation } from "react-i18next";
import { CreatedTask } from "types/tasks.types";

import { isMobile } from "utils/utils";
import {
  NoTasks,
  StatusLabelConatiner,
  TaskItem,
  TaskItemFooter,
  TaskItemHeader,
  TasksList as Tasks,
} from "../../../../styles/tasks.styles";
import { getStatusOptions, getTaskStatusLabel } from "utils/tasks-utils";
import { getTranslations } from "../../../../translations/tasks.translations";

import Placeholders from "../../../Placeholders";
import ErrorAlert from "components/molecules/ErrorAlert";
import TaskDates from "./components/task-dates/TaskDates";

type TasksListProps = {
  tasksList: CreatedTask[];
  onItemClick: (id: string) => () => void;
  filterVisible: boolean;
  isLoading: boolean;
  isRefetching: boolean;
  isError: boolean;
};

function TasksList({
  tasksList,
  onItemClick,
  filterVisible,
  isLoading,
  isRefetching,
  isError,
}: TasksListProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const statusOptions = getStatusOptions(language, { iconOnly: true });
  const { alert } = getTranslations(language);
  const placeholdersVisible = isLoading || isRefetching;

  let content = null;

  if (!!tasksList.length) {
    content = (
      <Tasks>
        {tasksList.map((task) => {
          const { id, Title, date_created, Deadline, Related } = task;
          const statusLabel = getTaskStatusLabel(task, statusOptions);
          // const attachments = Related.filter(({ item }) => typeof item !== "string");

          const isMine =
            task.user_created === "7cf3df18-5984-4ea2-aff4-57f2013246af" &&
            task.AssignTo === "a77b9c6a-eda9-4f2a-8715-152e57282923";

          return (
            <TaskItem
              key={id}
              isMobile={isMobile}
              onClick={onItemClick(id)}
              isMine={isMine}
              filterVisible={filterVisible}
            >
              <TaskItemHeader>{Title}</TaskItemHeader>
              <TaskItemFooter>
                <TaskDates date_created={date_created} Deadline={Deadline} />
                {/* <div>{!!attachments.length && <span>{attachments.length}</span>}</div> */}
                <StatusLabelConatiner>{statusLabel}</StatusLabelConatiner>
              </TaskItemFooter>
            </TaskItem>
          );
        })}
      </Tasks>
    );
  }

  if (!isLoading && !tasksList.length) {
    content = <NoTasks>{alert.info.noTasks}</NoTasks>;
  }

  if (placeholdersVisible) {
    content = <Placeholders />;
  }

  if (isError) {
    content = <ErrorAlert message={alert.error.unableToLoad} />;
  }

  return content;
}

export default TasksList;
