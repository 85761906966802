import { CountryCodes } from "types/types";

export const getCommonTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    yes: "Yes",
    no: "No",
    notApplicable: "Not applicable",
    created: "Created",
  };

  let info = {
    noPhotos: "No photos",
  };

  let modalMessage = {
    signout: "Are you sure you want to sign out?",
  };

  let button = {
    label: {
      signout: "Sign out",
      tryAgain: "Try again",
    },
  };

  if (isPolish) {
    labels = {
      yes: "Tak",
      no: "Nie",
      notApplicable: "Nie dotyczy",
      created: "Utworzono",
    };

    modalMessage = {
      signout: "Czy na pewno chcesz się wylogować?",
    };

    button = {
      label: {
        signout: "Wyloguj",
        tryAgain: "Spróbuj ponownie",
      },
    };

    info = {
      noPhotos: "Brak zdjęć",
    };
  }

  return { labels, modalMessage, button, info };
};
