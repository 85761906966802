import styled from "styled-components/macro";

const Container = styled.span`
  margin-left: 0.5rem;
  border-radius: 0.4rem;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.errorColor};
  opacity: 0.6;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  text-transform: lowercase;

  .warning-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .missing-photos-number {
    font-family: GothamBold;
  }
`;

function MissingAlert({
  label,
  missingPhotosNumber,
}: {
  label: string;
  missingPhotosNumber?: number;
}) {
  return (
    <Container>
      <span>
        {label}
        {missingPhotosNumber && (
          <span className='missing-photos-number'>:&nbsp;{missingPhotosNumber}</span>
        )}
      </span>
    </Container>
  );
}

export default MissingAlert;
