import { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  clearLocalStorage,
  findAnswer,
  findAnswerIndex,
  getReturnPath,
  getSize,
} from "utils/utils";
import {
  Answer,
  Answers,
  QUESTION_TYPE_IDS,
  QuestionType,
  FormTypes,
  QuestionObject,
  CreateAnswerValue,
} from "types/types";
import usePhotosObjectStore from "./usePhotosObjectStore";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

type QT = QuestionType | "";

const { FILE_PHOTO, NUMERIC, TEXT, YES_NO, SELECT } = QUESTION_TYPE_IDS;
const { ACCIDENT } = FormTypes;

function useAccidentForm() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    state: { accidentAnswers, selectedAccidentForm },
    setAccidentAnswersAction,
    setSelectedAccidentFormAction,
  } = useContext(QuestionnairesContext);

  const [currentQuestion, setCurrentQuestion] = useState<QuestionObject | undefined>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [answerInputValue, setAnswerInputValue] = useState("");
  const [answerTextAreaValue, setAnswerTextAreaValue] = useState("");
  const [answerSelectValue, setAnswerSelectValue] = useState({ label: "", value: "" });
  const [reactionTextAreaValue, setReactionTextAreaValue] = useState("");
  const [cameraVisible, setCameraVisible] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [saved, setSaved] = useState(false);

  const {
    getPhotosFromIndexedDB,
    updatePhotosInIndexedDB,
    deleteAllPhotosInIndexedDB,
    getNumberOfPhotos,
  } = usePhotosObjectStore(ACCIDENT);

  const numberOfQuestions = selectedAccidentForm?.Questions.length;
  const questionType: QT = currentQuestion?.QuestionType ?? "";

  const accidentFormId = selectedAccidentForm?.id;

  const answerToCurrentQuestion = findAnswer(accidentAnswers, currentQuestion?.id);
  const answerToCurrentQuestionIndex = findAnswerIndex(
    accidentAnswers,
    currentQuestion?.id,
  );

  const isTyping = !!sessionStorage.getItem("typing");

  const clearState = () => {
    setAccidentAnswersAction([]);
    setSelectedAccidentFormAction(undefined);
    clearLocalStorage(["accidentAnswers", "selectedAccidentForm"]);
    deleteAllPhotosInIndexedDB();
  };

  const navigateBack = () => {
    const returnPath = getReturnPath();
    const path = returnPath === "/home" ? "/home" : "/accident-forms-list";

    navigate(path, { replace: true });
  };

  const handleCloseForm = () => {
    clearState();
    navigateBack();
  };

  const clearInputFields = () => {
    if (answerInputValue) {
      setAnswerInputValue("");
    }
    if (answerTextAreaValue) {
      setAnswerTextAreaValue("");
    }
    if (reactionTextAreaValue) {
      setReactionTextAreaValue("");
    }
  };

  const goToNextQuestion = () => {
    if (numberOfQuestions) {
      if (currentQuestionIndex + 1 < numberOfQuestions) {
        setCurrentQuestionIndex((prev) => prev + 1);

        if (params.question) {
          const nextQuestionNumber = +params.question + 1;
          navigate(`/accident-form/${nextQuestionNumber}`);
        }
      } else {
        navigate(`/accident-form/summary/${accidentFormId}`);
      }
      clearInputFields();

      if (cameraVisible) {
        setCameraVisible(false);
      }
    }
  };

  const goToPreviousQuestion = () => {
    if (params.question) {
      const prevQuestionNumber = +params.question - 1;
      navigate(`/accident-form/${prevQuestionNumber}`);
    }
    setCurrentQuestionIndex((prev) => prev - 1);
  };

  // --------------- Answer creator ---------------

  const createAnswer = useCallback(
    (answerValue: CreateAnswerValue) => {
      if (!questionType) return;
      if (!currentQuestion) return;

      const { id, sort, QuestionText, QuestionSubtext, QuestionType } = currentQuestion;
      let Value = null;
      let reaction = null;
      let FilesGallery = null;
      let Gravity = 0;

      if ("Gravity" in currentQuestion) {
        Gravity = currentQuestion.Gravity as unknown as number;
      }

      switch (questionType) {
        case TEXT:
          Value = answerValue.textValue as string;
          break;

        case NUMERIC:
          Value = answerValue.numericValue as string;
          break;

        case FILE_PHOTO:
          if (!answerToCurrentQuestion) {
            // Answer not created yet, this is the first photo to be added to FilesGallery
            FilesGallery = answerValue.photoSrc
              ? [{ src: answerValue.photoSrc, comment: "" }]
              : null;

            updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
          } else {
            // Answer already exists, update FilesGallery (add another photo)
            // If null, filesGallery is set to an empty array
            // to avoid "filesGallery is not iterable" error.
            if (answerValue.photoSrc) {
              const src = answerValue.photoSrc as string;

              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                const filesGallery = photosFromDB ?? [];
                FilesGallery = [...filesGallery, { src, comment: "" }];

                updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
              });
            } else {
              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                updatePhotosInIndexedDB({
                  Question: currentQuestion.id,
                  FilesGallery: photosFromDB,
                });
              });
            }
          }

          reaction = reactionTextAreaValue
            ? reactionTextAreaValue
            : (answerToCurrentQuestion?.ReactionValue as string);
          break;

        case SELECT:
          Value = answerValue.selectValue as string;
          break;

        // ----------------------------------------------------

        default:
          if (!answerToCurrentQuestion) {
            Value = answerValue.yesNo as Answers;
          } else {
            Value = answerToCurrentQuestion.Value;

            if (answerValue.photoSrc) {
              const src = answerValue.photoSrc as string;

              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                const filesGallery = photosFromDB ?? [];
                FilesGallery = [...filesGallery, { src, comment: "" }];

                updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
              });
            } else {
              getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
                updatePhotosInIndexedDB({
                  Question: currentQuestion.id,
                  FilesGallery: photosFromDB,
                });
              });
            }

            reaction = reactionTextAreaValue
              ? reactionTextAreaValue
              : (answerToCurrentQuestion?.ReactionValue as string);
          }
      }

      const ReactionValue = reactionTextAreaValue ? reactionTextAreaValue : reaction;

      const ValueSize = Value
        ? getSize(Value) + getSize(FilesGallery?.map(({ src }) => src) as string[])
        : 0;
      const ReactionSize =
        getSize(ReactionValue) + getSize(FilesGallery?.map(({ src }) => src) as string[]);

      const answer = Object.assign({
        sort,
        Gravity,
        AnswerCreated: new Date(),
        OriginalText: QuestionText,
        OriginalSubText: QuestionSubtext,
        Value,
        ValueSize,
        ReactionValue,
        ReactionSize,
        Question: id,
        QuestionType,
        FilesGallery: null,
      }) as Answer;

      let modifiedAnswers = [];

      // Modify answer that is already in state
      if (answerToCurrentQuestionIndex > -1) {
        modifiedAnswers = accidentAnswers;
        modifiedAnswers[answerToCurrentQuestionIndex] = answer;
      } else {
        // Add a new answer to answers array
        modifiedAnswers = [...accidentAnswers, answer];
      }

      setAccidentAnswersAction(modifiedAnswers);
    },
    [
      accidentAnswers,
      answerToCurrentQuestion,
      answerToCurrentQuestionIndex,
      currentQuestion,
      getPhotosFromIndexedDB,
      questionType,
      reactionTextAreaValue,
      setAccidentAnswersAction,
      updatePhotosInIndexedDB,
    ],
  );

  // --------------- Answers updater ---------------

  const updateAnswersInState = (updatedAnswer: Answer) => {
    const modifiedAnswers = accidentAnswers;
    modifiedAnswers[answerToCurrentQuestionIndex] = updatedAnswer;

    setAccidentAnswersAction(modifiedAnswers);
  };

  // --------------- Remove handlers ---------------

  // Photo
  const removePhoto = (photoIndex: number) => {
    if (!answerToCurrentQuestion || !currentQuestion) {
      return;
    }

    const updatedFilesGallery = answerToCurrentQuestion.FilesGallery;
    updatedFilesGallery?.splice(photoIndex, 1);

    const updatedAnswer = {
      ...answerToCurrentQuestion,
      FilesGallery: updatedFilesGallery,
    };

    updateAnswersInState(updatedAnswer);
  };

  // Reaction
  const removeReactionComment = () => {
    if (!answerToCurrentQuestion || !currentQuestion) {
      return;
    }

    const updatedAnswer = {
      ...answerToCurrentQuestion,
      ReactionValue: null,
    };

    updateAnswersInState(updatedAnswer);

    if (reactionTextAreaValue) {
      setReactionTextAreaValue("");
    }
  };

  // ----- Answer
  const removeAnswer = async (Question: string) => {
    const updatedAnswers = accidentAnswers.filter(
      (answer) => answer.Question !== Question,
    );

    setAccidentAnswersAction(updatedAnswers);

    const containsPhotos = await !!getNumberOfPhotos(Question);

    if (containsPhotos) {
      updatePhotosInIndexedDB({ Question, FilesGallery: null });
    }

    if (questionType.includes(YES_NO)) {
      if (reactionTextAreaValue) {
        setReactionTextAreaValue("");
      }
    }
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (params.question) {
      const index = +params.question - 1;

      setCurrentQuestionIndex(index);
    }
  }, [params]);

  useEffect(() => {
    if (selectedAccidentForm) {
      setCurrentQuestion(selectedAccidentForm.Questions[currentQuestionIndex]);
    }
  }, [currentQuestionIndex, selectedAccidentForm, accidentAnswers, currentQuestion?.id]);

  useEffect(() => {
    const isPreviewMode = accidentAnswers.some(
      (answer) => answer.Question === currentQuestion?.id,
    );

    setPreviewMode(isPreviewMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isTyping && answerTextAreaValue) {
        createAnswer({ textValue: answerTextAreaValue });
        setSaved(true);
        sessionStorage.removeItem("typing");
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [answerTextAreaValue, createAnswer, isTyping]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (saved) {
        setSaved(false);
      }
    }, 1200);

    return () => clearTimeout(timer);
  }, [saved]);

  return {
    accidentAnswers,
    createAnswer,
    removeAnswer,
    currentQuestion,
    currentQuestionIndex,
    questionType,
    numberOfQuestions,
    answerInputValue,
    setAnswerInputValue,
    answerTextAreaValue,
    setAnswerTextAreaValue,
    answerSelectValue,
    setAnswerSelectValue,
    reactionTextAreaValue,
    setReactionTextAreaValue,
    cameraVisible,
    setCameraVisible,
    goToNextQuestion,
    goToPreviousQuestion,
    previewMode,
    clearInputFields,
    handleCloseForm,
    answerToCurrentQuestion,
    removePhoto,
    removeReactionComment,
    clearState,
    isTyping,
    saved,
  };
}

export default useAccidentForm;
