import styled, { css } from "styled-components/macro";

import { isMobile } from "utils/utils";

import { ReactComponent as ChevronDoubleIcon } from "assets/icons/chevron-double-down.svg";

const BottomSpace = styled.div`
  height: 5rem;
`;

const Container = styled.div`
  position: absolute;
  bottom: 7rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.level5};

  animation: fadeIn ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const IconContainer = styled.div<{ isBottom: boolean; isMobile: boolean }>`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: relative;
  background-color: rgba(33, 47, 91, 0.8);
  transition: background-color 0.75s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isBottom }) =>
    isBottom &&
    css`
      background-color: ${({ theme }) => theme.primary_50};
    `}

  .chevron-icon {
    width: 4rem;
    height: 4rem;

    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

    ${({ isBottom }) =>
      isBottom &&
      css`
        transform: rotate(180deg);
        top: -0.6rem;
      `}

    path {
      fill: ${({ theme }) => theme.mainBackground};

      transition: fill 0.7s ease;

      ${({ isBottom }) =>
        isBottom &&
        css`
          fill: ${({ theme }) => theme.primary};
        `}
    }
  }
`;

type ScrollButtonProps = {
  isBottom: boolean;
  onClick: () => void;
};

function ScrollButton({ isBottom, onClick }: ScrollButtonProps) {
  return (
    <>
      <BottomSpace />
      <Container>
        <IconContainer isBottom={isBottom} isMobile={isMobile} onClick={onClick}>
          <ChevronDoubleIcon className='chevron-icon' />
        </IconContainer>
      </Container>
    </>
  );
}

export default ScrollButton;
