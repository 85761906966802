import { Answer, Answers, QUESTION_TYPE_IDS, QuestionObject } from "types/types";

const { YES_NO, FILE_PHOTO } = QUESTION_TYPE_IDS;
const { YES, NO } = Answers;

export const checkIfPhotoType = ({
  QuestionType,
  OnNoActionPhoto,
  OnYesActionPhoto,
}: QuestionObject) =>
  (QuestionType.includes(YES_NO) && (!!OnNoActionPhoto || !!OnYesActionPhoto)) ||
  QuestionType === FILE_PHOTO;

export const checkIfIsAnswerWithPhotos = (id: string) => !!localStorage.getItem(id);

export const getDefaultMinPhotos = (required: boolean) => (required ? 1 : 0);
export const defaultMaxPhotos = 5;

export const getMinMaxPhotos = (
  currentQuestion: QuestionObject | undefined,
  currentAnswer: Answer | undefined,
) => {
  let minPhotos = 0;
  let maxPhotos = 0;

  if (!currentQuestion || !currentAnswer)
    return {
      minPhotos,
      maxPhotos,
    };

  const {
    QuestionType,
    OnYesRequiredMinPhotos,
    OnYesMaxPhotos,
    RequiredMinPhotos,
    MaxPhotos,
    Required,
    OnNoRequiredMinPhotos,
    OnNoMaxPhotos,
  } = currentQuestion;

  const YES_NO_QUESTION = QuestionType.includes(YES_NO);
  const PHOTO_QUESTION = QuestionType === FILE_PHOTO;
  const defaultMinPhotos = getDefaultMinPhotos(Required);

  if (YES_NO_QUESTION) {
    const { Value } = currentAnswer;

    switch (Value) {
      case YES:
        minPhotos = OnYesRequiredMinPhotos ?? defaultMinPhotos;
        maxPhotos = OnYesMaxPhotos ?? defaultMaxPhotos;

        break;

      case NO:
        minPhotos = OnNoRequiredMinPhotos ?? defaultMinPhotos;
        maxPhotos = OnNoMaxPhotos ?? defaultMaxPhotos;
    }
  }

  if (PHOTO_QUESTION) {
    minPhotos = RequiredMinPhotos ?? defaultMinPhotos;
    maxPhotos = MaxPhotos ?? defaultMaxPhotos;
  }

  return {
    minPhotos,
    maxPhotos,
  };
};

export const getPhotoCount = (id: string) => {
  return parseInt(localStorage.getItem(id) ?? "0");
};
