import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "react-use-cookie";
import { AxiosError, AxiosProgressEvent } from "axios";
import styled, { css } from "styled-components/macro";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { differenceInMilliseconds } from "date-fns";

import { useMutation } from "react-query";
import { sendAccidentReport } from "api/questionnaires";
import { Assessment, AccidentFormPayload, FormTypes, Answer } from "types/types";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import AuthContext from "contexts/auth-context/AuthContext";
import {
  ACCESS_TOKEN,
  DEVICE_INFO_STRING,
  clearLocalStorage,
  device,
  getReturnPath,
} from "utils/utils";
import { checkIfIsAnswerWithPhotos } from "utils/photos.utils";
import useModal from "hooks/useModal";
import useNotification from "hooks/useNotification";
import useAccidentForm from "hooks/useAccidentForm";
import usePhotosForAnswers from "hooks/usePhotosForAnswers";
// import { useAppSelector } from "redux/hooks/hooks";
import packageInfo from "../../../package.json";

import SuccessAlert from "components/molecules/SuccessAlert";
import Button from "components/atoms/Button";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import UploadProgressBar from "components/atoms/upload-progress-bar/UploadProgressBar";

import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { ReactComponent as ChevronIcon } from "assets/icons/navigation-chevron.svg";

import {
  Header,
  Arrow,
  HeaderLabel,
} from "pages/questionnaire/styles/questionnaireStyles";
import ReportIncompleteInfo from "pages/summary/components/ReportIncompleteInfo";

import { ContentContainer, ModalStyles } from "styles/generalStyles";
import { Content, WarningBar, Wrapper } from "../accident-form/styles/accidenFormStyles";

const { Actions, ButtonContainer } = ModalStyles;

const StyledToast = styled(ToastContainer)`
  font-size: 1.5rem;
`;

const FormTitle = styled.div`
  padding-top: 1rem;

  @media screen and (min-height: 616px) {
    padding-top: 2rem;
  }
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-family: GothamBold;
  text-align: center;
  text-transform: uppercase;
  margin-top: 2rem;
  user-select: none;
  ${({ theme }) => theme.ellipsisMultiline}

  @media screen and (min-height: 616px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  ${({ theme }) => theme.ellipsis}

  @media screen and (min-height: 616px) {
    font-size: 1.5rem;
  }
`;

const Info = styled.div<{ successfullySent: boolean }>`
  margin-top: 1.5rem;
  text-align: center;
  ${({ theme }) => theme.fillUpRemainingSpace}

  ${({ successfullySent }) =>
    successfullySent &&
    css`
      justify-content: space-between;
    `}

  .ready {
    font-family: GothamBold;
    text-transform: uppercase;

    @media ${device.iPad} {
      font-size: 2rem;
    }
  }
`;

const DoneIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.4rem;

  @media screen and (min-height: 616px) {
    margin-top: 2rem;
  }

  .done-icon {
    width: 8rem;
    height: 8rem;

    @media screen and (min-height: 616px) {
      width: 10rem;
      height: 10rem;
    }
  }
`;

const { ACCIDENT } = FormTypes;

function AccidentSummary() {
  const {
    state: { accidentAnswers, accidentStartTime, selectedAccidentForm, serialNumber },
  } = useContext(QuestionnairesContext);
  const { setShouldReAuth } = useContext(AuthContext);
  const { clearState } = useAccidentForm();

  const [assessment, setAssessment] = useState<Assessment | undefined>(undefined);
  const [successfullySent, setSuccessfullySent] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { photosForAnswers } = usePhotosForAnswers(ACCIDENT);

  const { t } = useTranslation(["summary", "side-navbar"]);

  const { open, openModal, closeModal } = useModal();

  const navigate = useNavigate();
  const params = useParams();
  const deviceInfoString = localStorage.getItem(DEVICE_INFO_STRING);

  const notify = useNotification();

  const endTimeRef = useRef<Date>();

  const formTitle = selectedAccidentForm?.Title;
  const formSubtitle = selectedAccidentForm?.Subtitle;

  const numberOfRequiredQuestions = selectedAccidentForm?.Questions.filter(
    ({ Required }) => Required,
  ).length;

  const token = getCookie(ACCESS_TOKEN);
  const returnPath = getReturnPath();

  // const allQuestionsAnswered = numberOfRequiredQuestions === accidentAnswers.length;

  const closeConfirmationModal = () => {
    closeModal();
  };

  const displayAlert = useCallback(
    (error: AxiosError) => {
      notify(`${t("error.unable-to-send-report")} ${error.response?.status})`, "error");
    },
    [notify, t],
  );

  const onError = useCallback(
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        setShouldReAuth(true);
      } else {
        displayAlert(error);
      }
    },
    [displayAlert, setShouldReAuth],
  );

  const onSuccess = () => {
    setSuccessfullySent(true);
  };

  const { mutate, isLoading } = useMutation<
    { id: string },
    AxiosError,
    AccidentFormPayload
  >(sendAccidentReport, {
    onSuccess,
    onError,
  });

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const value = Math.ceil((progressEvent.progress as number) * 100);

    setUploadProgress(value);
  };

  const handleSendReport = () => {
    if (!endTimeRef.current) return;

    const AppVersion = packageInfo.version;
    const UserAgent = deviceInfoString;
    const Duration = Math.round(
      differenceInMilliseconds(endTimeRef.current, accidentStartTime) / 1000,
    );
    const payload = { ...assessment, AppVersion, Duration, UserAgent };

    const accidentPayload = {
      payload,
      token,
      onUploadProgress,
    } as AccidentFormPayload;

    mutate(accidentPayload);
  };

  const handleQuitAccident = () => {
    const path = !!returnPath ? returnPath : "/accident-forms-list";

    clearState();
    clearLocalStorage(["returnPath"]);

    navigate(path, { replace: true });
  };

  const findFirstUnansweredQuestion = () => {
    if (!selectedAccidentForm) {
      return;
    }

    const unansweredQuestions = selectedAccidentForm.Questions.map((question) =>
      accidentAnswers.some((answer) => answer.Question === question.id)
        ? undefined
        : question,
    ).filter((item) => !!item);

    const firstUnansweredQuestion = !!unansweredQuestions
      ? unansweredQuestions[0]
      : undefined;

    return firstUnansweredQuestion;
  };

  const goToFirstUnansweredQuestion = (index: number) => {
    const path = `/accident-form/${index + 1}`;
    navigate(path);
  };

  const handleUnansweredQuestions = () => {
    if (!selectedAccidentForm) {
      return;
    }

    const firstUnansweredQuestion = findFirstUnansweredQuestion();

    let firstUnansweredQuestionIndex = 0;

    if (firstUnansweredQuestion) {
      firstUnansweredQuestionIndex = selectedAccidentForm.Questions.findIndex(
        ({ id }) => id === firstUnansweredQuestion.id,
      );
    }

    goToFirstUnansweredQuestion(firstUnansweredQuestionIndex);
  };

  const onCloseIconClick = () => {
    handleQuitAccident();
  };

  const onTriangleBackClick = () => {
    if (!isLoading) navigate(`/accident-form/${accidentAnswers.length}`);
  };

  const onCompleteBtnClick = () => {
    handleUnansweredQuestions();
  };

  useEffect(() => {
    if (!photosForAnswers) {
      return;
    }

    const numberOfNegativeAnswers = accidentAnswers.filter(
      ({ Value }) => Value === "No",
    ).length;

    const numberOfCriticalAnswers = accidentAnswers.filter(
      ({ Gravity, Value }) => Value === "No" && Gravity === 1,
    ).length;

    // Complete answers with photos (if any)
    const answersWithPhotos = accidentAnswers.reduce((acc: Answer[], curr: Answer) => {
      if (checkIfIsAnswerWithPhotos(curr.Question)) {
        acc = [...acc, { ...curr, FilesGallery: photosForAnswers[curr.Question] }];
      } else {
        acc = [...acc, curr];
      }

      return acc;
    }, []);

    // Sort answers
    const sortedAnswers = answersWithPhotos.sort((a, b) => a.sort - b.sort);

    const summary = {
      AccidentForm: params.accidentFormId,
      Payload: sortedAnswers,
      Questionnaire: null,
      SerialNo: serialNumber,
      TotalAnswers: accidentAnswers.length,
      TotalGravity1Negatives: numberOfCriticalAnswers,
      TotalNegatives: numberOfNegativeAnswers,
      createdAt: accidentStartTime,
    } as Assessment;

    setAssessment(summary);
  }, [
    accidentAnswers,
    accidentStartTime,
    params.accidentFormId,
    serialNumber,
    photosForAnswers,
  ]);

  useEffect(() => {
    endTimeRef.current = new Date();
  }, []);

  return (
    <ContentContainer alarm>
      <Content>
        <WarningBar />
        <Wrapper>
          <Header marginTop paddingBottom>
            {!successfullySent && (
              <Arrow
                onClick={onTriangleBackClick}
                isLoading={isLoading}
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <ChevronIcon className='chevron-icon' />
              </Arrow>
            )}
            <HeaderLabel fullWidth={successfullySent}>
              {t("header.report-complete")}
              {/* {allQuestionsAnswered
                ? t("header.report-complete")
                : t("header.report-incomplete")} */}
            </HeaderLabel>
          </Header>

          {/* {allQuestionsAnswered && (
            <FormTitle>
              <Title>{formTitle}</Title>
              <Subtitle>{formSubtitle !== "null" ? formSubtitle : ""}</Subtitle>
            </FormTitle>
          )} */}

          <FormTitle>
            <Title>{formTitle}</Title>
            <Subtitle>{formSubtitle !== "null" ? formSubtitle : ""}</Subtitle>
          </FormTitle>

          {/* {allQuestionsAnswered && (
            <DoneIconContainer>
              <DoneIcon className='done-icon' width='10rem' height='10rem' />
            </DoneIconContainer>
          )} */}

          <DoneIconContainer>
            <DoneIcon className='done-icon' width='10rem' height='10rem' />
          </DoneIconContainer>

          {/* {allQuestionsAnswered && (
            <Info successfullySent={successfullySent}>
              {successfullySent ? (
                <>
                  <SuccessAlert message={t("alert.report-sent")} />
                  <Actions>
                    <ButtonContainer middle>
                      <Button
                        label={t("button.close", { ns: "common" })}
                        onClick={onCloseIconClick}
                      />
                    </ButtonContainer>
                  </Actions>
                </>
              ) : (
                <span className='ready'>{t("header.report-ready-to-be-sent")}</span>
              )}

              {!successfullySent && isLoading && (
                <UploadProgressBar progress={uploadProgress} />
              )}
            </Info>
          )} */}

          <Info successfullySent={successfullySent}>
            {successfullySent ? (
              <>
                <SuccessAlert message={t("alert.report-sent")} />
                <Actions>
                  <ButtonContainer middle>
                    <Button
                      label={t("button.close", { ns: "common" })}
                      onClick={onCloseIconClick}
                    />
                  </ButtonContainer>
                </Actions>
              </>
            ) : (
              <span className='ready'>{t("header.report-ready-to-be-sent")}</span>
            )}

            {!successfullySent && isLoading && (
              <UploadProgressBar progress={uploadProgress} />
            )}
          </Info>

          {!successfullySent && (
            <Actions spaceBetween>
              <ButtonContainer double>
                <Button
                  label={t("button.discard")}
                  question
                  onClick={openModal}
                  disabled={isLoading}
                />
              </ButtonContainer>

              <ButtonContainer middle>
                <Button
                  onClick={handleSendReport}
                  label={t("button.send")}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </ButtonContainer>
            </Actions>
          )}

          <ReportIncompleteInfo
            onClick={onCompleteBtnClick}
            openModal={openModal}
            allRequiredQuestionsAnswered={true}
            answersWithMissingPhotosNumber={0}
            answersWithMissingDescriptionNumber={0}
          />
        </Wrapper>
      </Content>

      <ConfirmationModal
        message={t("modal.message", { ns: "side-navbar" })}
        onClick={onCloseIconClick}
        onClose={closeConfirmationModal}
        open={open}
        buttonLabel={t("modal.button-label", { ns: "side-navbar" })}
      />

      <StyledToast position='top-left' theme='colored' autoClose={3000} limit={1} />
    </ContentContainer>
  );
}

export default AccidentSummary;
